import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  companyname: "",
};

export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    updateDomain: (state, action) => {
      state.name = action.payload;
    },
    updateCompanyName: (state, action) => {
      state.companyname = action.payload;
    },
  },
});

export const { updateDomain, updateCompanyName } = domainSlice.actions;

export const domainReducer = domainSlice.reducer;
