import "./styles.css";
import { Button, DatePicker } from "antd";
import moment from "moment";
import { useState } from "react";

const DateRangePicker = ({
  setStartDate,
  setEndDate,
  refreshBtn,
  desc,
  startDate,
  endDate,
  size,
}) => {
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false); // State to control picker open/close
  const [selectedDates, setSelectedDates] = useState([
    startDate
      ? moment(startDate, "YYYY-MM-DD HH:mm:ss")
      : moment().subtract(7, "days"),
    endDate ? moment(endDate, "YYYY-MM-DD HH:mm:ss") : moment(),
  ]);

  const handleButtonClick = (range) => {
    let newStartDate, newEndDate;

    // Set date ranges based on button clicked
    switch (range) {
      case "today":
        newStartDate = moment().startOf("day");
        newEndDate = moment(); // Current date and time
        break;
      case "week":
        newStartDate = moment().startOf("week");
        newEndDate = moment(); // Current date and time
        break;
      case "month":
        newStartDate = moment().startOf("month");
        newEndDate = moment(); // Current date and time
        break;
      default:
        break;
    }

    // Update state and close picker
    setStartDate(newStartDate.format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(newEndDate.format("YYYY-MM-DD HH:mm:ss"));
    setSelectedDates([newStartDate, newEndDate]);
    refreshBtn((prev) => !prev);
    setOpen(false);
  };

  return (
    <div className="w-100 p-1 rounded-1">
      {desc && (
        <div
          className="pb-2"
          style={{
            fontSize: 14,
            color: "#878787",
            fontWeight: 300,
          }}
        >
          Select a date range
        </div>
      )}
      <RangePicker
        renderExtraFooter={() => (
          <div className="my-2">
            {[
              { label: "Today", value: "today" },
              { label: "This Week", value: "week" },
              { label: "This Month", value: "month" },
            ].map((i) => (
              <Button
                key={i.value}
                className="mr-2"
                onClick={() => {
                  handleButtonClick(i.value);
                }}
              >
                {i.label}
              </Button>
            ))}
          </div>
        )}
        // onOk={handleButtonClick}
        value={selectedDates} // Use state to set the value dynamically
        size={size ?? "default"}
        showTime={{ format: "HH:mm:ss" }}
        format="YYYY-MM-DD HH:mm:ss"
        onChange={(e) => {
          setStartDate(moment(e[0]._d).format("YYYY-MM-DD HH:mm:ss"));
          setEndDate(moment(e[1]._d).format("YYYY-MM-DD HH:mm:ss"));
          setSelectedDates([e[0], e[1]]); // Update selected dates
          refreshBtn((prev) => !prev);
        }}
        open={open}
        onOpenChange={(openStatus) => setOpen(openStatus)} // Update the picker state
      />
    </div>
  );
};

export default DateRangePicker;
