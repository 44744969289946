import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const commandAnalyticsAPI = createApi({
  reducerPath: "commandAnalyticsAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    // Count issued commands by device ID
    countIssuedCommandsByDeviceId: build.mutation({
      query: (data) => ({
        url: "/commandanalytics/countgetissuedcommandsbydeviceid",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),

    // Count issued commands by username
    countIssuedCommandsByUsername: build.mutation({
      query: (data) => ({
        url: "/commandanalytics/countgetissuedcommandsbyusername",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),

    // Count issued commands by username and device ID
    countIssuedCommandsByUsernameAndDeviceId: build.mutation({
      query: (data) => ({
        url: "/commandanalytics/countgetissuedcommandsbyusernameanddeviceid",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),

    // Get issued commands by device ID
    getIssuedCommandsByDeviceId: build.mutation({
      query: (data) => ({
        url: "/commandanalytics/getissuedcommandsbydeviceid",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),

    // Get issued commands by username
    getIssuedCommandsByUsername: build.mutation({
      query: (data) => ({
        url: "/commandanalytics/getissuedcommandsbyusername",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),

    // Get issued commands by username and device ID
    getIssuedCommandsByUsernameAndDeviceId: build.mutation({
      query: (data) => ({
        url: "/commandanalytics/getissuedcommandsbyusernameanddeviceid",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
  }),
});

export const {
  useCountIssuedCommandsByDeviceIdMutation,
  useCountIssuedCommandsByUsernameMutation,
  useCountIssuedCommandsByUsernameAndDeviceIdMutation,
  useGetIssuedCommandsByDeviceIdMutation,
  useGetIssuedCommandsByUsernameMutation,
  useGetIssuedCommandsByUsernameAndDeviceIdMutation,
} = commandAnalyticsAPI;
