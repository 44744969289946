import axios from "../../../utils/axios";
import { useEffect, useState } from "react";
// import Layout from "../../../components/Layout";
// import PageNav from "../../../components/PageNav";
import UserRolesTable from "../../../components/Roles/Tables/UserRolesTable";
import { toast } from "react-hot-toast";
// import { commandController } from "../../../controllers/CommandController";
import { channelController } from "../../../controllers/channelController";
import { getToken } from "../../../utils/token";
import TableSkimmer from "../../../components/TableSkimmer";
// import { Button } from "../../../components/Button";
// import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { postLogin, postLogout } from "../../../redux/actions/auth.action";
import { errorBlock } from "../../../controllers/errorBlock";
import { convertToNigerianTime } from "../../../utils/timezones";
// import { setNavtitle } from "../../../redux/slice/layoutSlice";

const UserRoles = () => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [loadingLogout, setloadingLogout] = useState(false);
  const dispatch = useDispatch();
  convertToNigerianTime();

  const { navtitle } = useSelector((state) => state.layout);

  const getTimezone = () => {};

  useEffect(() => {
    (async () => {
      try {
        let username = await getToken("spiral_username");
        let res = await axios.post("/roles/getuserroles", {
          targetusername: username,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }

        // console.log(result);
        setData(result?.message?.body);
        setloading(false);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.response);
        }
        toast.error(err.message);
        setloading(false);
      }
    })();
  }, []);

  return (
    <div className="h-screen bg-gray-50 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 items-center place-items-center place-content-center gap-10">
      <div
        className="h-[400px] bg-gradient-to-r from-gray-900 via-gray-800 to-blue-900  absolute top-0 left-0 w-screen"
        // style={{
        //   background: `url(${BGImg})`,
        //   backgroundPosition: "center",
        //   backgroundRepeat: "center",
        //   backgroundSize: "cover",
        //   opacity: 0.5,
        //   backgroundAttachment: "fixed",
        // }}
      ></div>
      <div className=" 0 w-[90%] !z-50 bg-white p-3 h-[90vh] drop-shadow-sm rounded-sm">
        {/* <div className="w-[100px]">
          <Button
            text={"Logout"}
            bg={"#6B49FF"}
            loading={loadingLogout}
            onClick={() => {
              try {
                setloadingLogout(true);
                dispatch(postLogout());
              } catch (error) {
                setloadingLogout(false);
                errorBlock(error);
              }
            }}
          />
        </div> */}
        <div className="flex items-center justify-between">
          <div className="">
            <div className="font-semibold  text-[17px]">
              All roles assigned to your account
            </div>
            <div className="text-gray-500 font-light">List of roles</div>
          </div>
          {/* <Button></Button> */}
          <Button
            className="!border-none !outline-none !text-white !bg-black"
            loading={loadingLogout}
            onClick={() => {
              try {
                setloadingLogout(true);
                dispatch(postLogout());
              } catch (error) {
                setloadingLogout(false);
                errorBlock(error);
              }
            }}
          >
            Logout
          </Button>
        </div>
        <hr className="border-[0.2px] border-gray-100 my-2" />
        <div className="">
          {loading ? (
            <TableSkimmer entries={10} col={7} />
          ) : (
            <UserRolesTable data={!data.length ? [] : data} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRoles;
