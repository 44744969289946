import React, { useEffect, useState } from "react";
import MeterCommandsTable from "../../../../components/Meter/Table/MeterCommandsTable";
import {
  useGetIssuedCommandsByUsernameMutation,
  useGetIssuedCommandsByDeviceIdMutation,
} from "../../../../services/command.analytics.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import toast from "react-hot-toast";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { convertToNigerianTime } from "../../../../utils/timezones";
export const CustomerMeterCommands = () => {
  const { username } = useSelector((state) => state.userDetails);
  const { timezone } = useSelector((state) => state.projectSite);
  const [getIssuedCommandsByDeviceId, { isLoading, data, error }] =
    useGetIssuedCommandsByDeviceIdMutation();
  const { customer, meterid } = useParams();
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "month").format("YYYY-MM-DD")
  );

  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  //
  useEffect(() => {
    try {
      getIssuedCommandsByDeviceId({
        deviceid: meterid,
        startissueddate: timezone
          ? convertToNigerianTime(startdate, timezone)
          : startdate,
        endissueddate: timezone
          ? convertToNigerianTime(enddate, timezone)
          : enddate,
        limit: 20,
        offset: 0,
      })
        .then((res) => {
          if (res.error) {
            errorBlock(res.error);
            return;
          }
          // console.log(res.data, "response");
          // if (res.data.response !== "success") {
          //   console.log("Got in here");
          //   toast.error(res.data.error);
          //   return;
          // }
          // toast.success(res.data.response);
        })
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, []);

  console.log(data, "Data");

  return (
    <div className="">
      <p>Recent commands issued on meter</p>
      <MeterCommandsTable data={data} />
    </div>
  );
};
