import {
  AiOutlineEye,
  AiOutlineKey,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { errorBlock } from "../../../controllers/errorBlock";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "../../../redux/actions/auth.action";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { headers } from "../../../utils/headers";
import {
  updateClientId,
  updateRole,
  updateRoleCount,
  updateUsername,
} from "../../../redux/slice/userDetailSlice";
import {
  setCountry,
  setProjectSiteId,
  setProjectSiteName,
} from "../../../redux/slice/projectsiteSlice";
import { apiURL } from "../../../utils/axios";
import { isWWWhostname } from "../../../utils/helpers";
import { useGetCompanyNameByAliasMutation } from "../../../services/external.services";
// import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import {
  updateCompanyName,
  updateDomain,
} from "../../../redux/slice/domainslice";

const SignIn = () => {
  const controller = new AbortController();
  const dispatch = useDispatch();
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingcompany, setloadingcompany] = useState(true);
  const [companydata, setcompanydata] = useState(null);

  const [eye, seteye] = useState(false);
  let navigate = useNavigate();

  const [installPrompt, setInstallPrompt] = useState(null);

  const [prompt, setPrompt] = useState(false);
  const [domain, setdomain] = useState(null);

  const { role, domain: companyDomain } = useSelector(
    (state) => state.userDetails
  );
  const { isLoggedIn } = useSelector((state) => state.auth);
  const token = localStorage.getItem("spiral_token");

  const hanldeSwitch = async (role) => {
    try {
      switch (role) {
        case "System Manager":
          navigate(`/dashboard/systemadmin`);
          return;
        case "Project Manager":
          navigate(`/dashboard/projectmanager`);
          return;
        case "Customer Representative":
          navigate(`/dashboard/customerrepresentative`);
          return;
        case "Customer Manager":
          navigate(`/dashboard/customermanager`);
          return;
        case "Sales Agent":
          navigate(`/dashboard/salesagent`);
          return;
        default:
          break;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (token && isLoggedIn) {
      hanldeSwitch(role);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setInstallPrompt((prev) => e);
      // console.log(e);
      setPrompt((prev) => true);
      // console.log(`'beforeinstallprompt' event was fired.`);
    });
  }, []);

  const handleInstall = async () => {
    try {
      installPrompt.prompt();
      const { outcome } = await installPrompt.userChoice;
      if (outcome.includes("accepted")) {
        toast.success("App installed succesfully");
        return;
      } else if (outcome.includes("dismissed")) {
        return null;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleLogin = async () => {
    try {
      if (!username || !password) {
        toast.error("All values are required.");
        return;
      }

      let clientid = String(Date.now());

      setloading(true);
      let res = await axios.post(
        apiURL.mmsUrlDev + "auth/login",
        {
          username: username,
          password,
          subdomain: domain,
          clientid,
        },
        headers
      );

      if (res.data.response == "2fa_required") {
        setloading(false);

        // /auth/verify2fa
        // Param: username, token (from email), clientid, subdomain.
        navigate(
          `/mfa-verification?username=${username}&clientid=${clientid}&subdomain=${domain}`
        );
        return toast.success(res.data.message);
      }

      if (res.data.response == "invalid identity") {
        setloading(false);
        return toast.error("Invalid details. Please try again.");
      }

      if (res.data.response == "disabled") {
        setloading(false);
        return toast.error("Account disabled. Contact your admin");
      }

      if (res.data.response == "reset password") {
        toast.error("Please reset your default password.");
        setloading(false);
        localStorage.setItem("spiral_registration", 0);
        localStorage.setItem("spiral_token", res.data?.sessionid);
        localStorage.setItem("spiral_username", username);
        navigate("/resetpassword");
        return;
      }

      if (res.data.response === "success") {
        if (!res.data?.registration) {
          toast.success("Please complete your registration");
          localStorage.setItem("spiral_registration", 0);
          localStorage.setItem("spiral_token", res.data?.sessionid);
          localStorage.setItem("spiral_username", username);
          setloading(false);
          navigate("/completeregistration");
          return;
        } else {
          setloading(false);
          localStorage.setItem("spiral_registration", 1);
          localStorage.setItem("spiral_token", res.data?.sessionid);
          localStorage.setItem("spiral_username", username);
          // navigate("/dashboard/systemadmin");

          // Make request to get User Role
          let rolesReq = await axios.post(
            apiURL.mmsUrlDev + "/roles/getuserroles",
            {
              callerid: username,
              targetusername: username,
              sessionid: res.data?.sessionid,
            },
            headers
          );
          let userRoles = rolesReq.data.body;
          if (!userRoles.length) {
            toast.error("A role has not been assigned to your account.");
            setloading(false);
            return;
          }

          dispatch(postLogin());

          dispatch(updateRoleCount(rolesReq.data.meta.count));

          dispatch(updateClientId(clientid));

          // check the length of the role
          if (userRoles.length == 1) {
            const {
              creator,
              role,
              roleid,
              country,
              siteid,
              sitename,
              username,
            } = userRoles[0];

            dispatch(updateDomain(domain));

            if (role == "System Manager") {
              dispatch(setProjectSiteName(""));
              dispatch(setProjectSiteId(""));
              dispatch(updateUsername(username));
              dispatch(setCountry(""));
              dispatch(updateRole(""));
            }
            // update the siteid, sitename, and username
            dispatch(setProjectSiteName(sitename));
            dispatch(setProjectSiteId(siteid));
            dispatch(setCountry(country));
            dispatch(updateUsername(username));
            dispatch(updateRole(role));

            if (role == "Sales Agent") {
              window.location.pathname = "/dashboard/salesagent";
            } else if (role == "Project Manager") {
              window.location.pathname = "/dashboard/projectmanager";
            } else if (role == "Customer Manager") {
              window.location.pathname = "/dashboard/customermanager";
            } else if (role == "Customer Representative") {
              window.location.pathname = "/dashboard/customerrepresentative";
            }
            setloading(false);
            return;
          }
          navigate("/userroles");
          setloading(false);
        }
      }
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };
  const [
    getCompanyNameByAlias,
    { isLoading: companyLoading, error: companyError, data: companyData },
  ] = useGetCompanyNameByAliasMutation();

  const isWwwHostname = useCallback(() => {
    return /^www\./.test(window.location.hostname);
  }, []);

  const initFunc = async () => {
    try {
      let hostname = window.location.hostname;
      let finalDomain;
      if (isWwwHostname()) {
        finalDomain = hostname.split(".")[1];
        // dispatch(updateDomain(hostname.split(".")[1]));
        // setdomain(hostname.split(".")[1]);
      } else if (hostname.startsWith("localhost")) {
        finalDomain = "ikj";
        // setdomain("ikj");
        // dispatch(updateDomain("ikj"));
      } else {
        finalDomain = hostname.split(".")[0];
      }

      // console.log(finalDomain, "final domain");
      let res = await axios.get(
        `https://api.headend.chanels.io/external/saas/getcompanynamebycompanyalias?secretkey=fff6647+09H@_3ggdeb3h66464&companyalias=${finalDomain}`,
        {
          headers: {
            Version: "0.1",
            "X-Secret-ID": "fff6647+09H@_3ggdeb3h66464",
          },
        }
      );

      setcompanydata(res.data.companyname);
      dispatch(updateCompanyName(res.data.companyname));
      setloadingcompany(false);

      setdomain(finalDomain);
      dispatch(updateDomain(finalDomain));

      if (res.data.response == "invalid identity") {
        setloading(false);
        return toast.error("Invalid details. Please try again.");
      }

      // getCompanyNameByAlias({
      //   companyalias: finalDomain,
      // })
      //   .then((res) => {
      //     if (res.error) {
      //       errorBlock(res.error);
      //       return;
      //     }

      //     if (res.data.response !== "success") {
      //       toast.error(res.data.error);
      //       return;
      //     }

      //     console.log(res.data, "company response");
      //     toast.success(res.data.response);
      //   })
      //   .catch((err) => {
      //     errorBlock(err);
      //   });
    } catch (error) {
      errorBlock(error);
    }
  };

  useEffect(() => {
    initFunc();
  }, []);

  return (
    // bg-[#f4f4f4]
    <div className="h-screen grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="w-[90%] z-50 md:w-[500px] mx-auto bg-white rounded-md shadow-md">
        {prompt && (
          <div className="block md:flex p-2 bg-[#e8effa] items-center justify-between border-b-[1px] border-blue-300">
            <div className="md:text-start text-center">
              Install this software on your device ?
            </div>
            <div className="w-ful md:flex flex mt-2 md:mt-0 items-center justify-center">
              <button
                onClick={() => handleInstall()}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-green-600 mx-3 "
              >
                Install
              </button>
              <button
                onClick={() => setPrompt(!prompt)}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-red-600 mx-3 "
              >
                Not now
              </button>
            </div>
          </div>
        )}
        {loadingcompany ? (
          <div className="flex flex-col p-4 items-center justify-center">
            <Spin tip={""} size="large" />
            <h1 className="mt-2">
              Please hold on, we are fetching your company's records...
            </h1>
          </div>
        ) : (
          <div className="">
            <div className="pt-6">
              <div className="text-center text-primary font-semibold text-2xl">
                Welcome to{" "}
                <span className="text-blue-500">
                  {companydata[0].toUpperCase() + companydata.substring(1)}
                </span>{" "}
                <br />
                Management System for Utilities
              </div>
            </div>
            <div className="w-[full] h-auto rounded-md px-6 pt-4 pb-6">
              <div className="grid grid-cols-3 place-content-center gap-0">
                <div className="flex items-center justify-center ">
                  <div className="border-t-[0.7px] border-gray-400 w-full"></div>
                </div>
                <div className="font-light text-center text-gray-600">
                  Sign in below
                </div>
                <div className="flex items-center justify-center ">
                  <div className="border-t-[0.7px] border-gray-400 w-full"></div>
                </div>
              </div>

              <div className="">
                {/* Lastname */}
                <div className="mt-4">
                  <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded border-gray-300 mt-2 h-[45px]">
                    <div className="d-inline flex items-center justify-center px-2">
                      <AiOutlineUser
                        size={20}
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2 w-full">
                      <input
                        required
                        value={username}
                        onChange={(e) => setusername(e.target.value)}
                        type={"text"}
                        placeholder={"Enter your username"}
                        className="block bg-transparent placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                      />
                    </div>
                  </div>
                </div>

                {/* Password */}
                <div className="mt-4">
                  <div className="flex items-center justify-end">
                    <label
                      onClick={() => navigate("/forgotpassword")}
                      className="flex text-primary underline cursor-pointer font-normal text-sm"
                    >
                      Forgot Password?
                    </label>
                  </div>

                  <div className="flex items-center justify-between w-100 border-[1px] rounded bg-gray-100 border-gray-300 mt-2 h-[45px]">
                    <div className="flex w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineKey
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-2 w-full">
                        <input
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          type={eye ? "text" : "password"}
                          placeholder={"Enter your password"}
                          className=" block bg-transparent placeholder:text-sm placeholder:font-thin outline-none border-[0px] w-full"
                        />
                      </div>
                    </div>

                    <div
                      className="px-3 cursor-pointer"
                      onClick={() => seteye(!eye)}
                    >
                      {eye ? (
                        <AiOutlineEye
                          color="text-primary"
                          className="text-primary"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          color="text-primary"
                          className="text-primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Button */}
                <div className=" w-100 mt-7">
                  <Button
                    text={"Login"}
                    disable={(domain || companyLoading) ?? false}
                    loading={loading}
                    onClick={handleLogin}
                  />
                </div>

                <div className="w-100 mt-4">
                  <div className="text-primary text-center font-normal py-3">
                    Need help with your account?{" "}
                    <a
                      className="text-secondary underline cursor-pointer"
                      href="mailto:hello@chanels.io"
                    >
                      Click here
                    </a>
                  </div>
                  {/* <Link
                  to="/createaccount"
                  className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
                >
                  <span className="font-semibold text-1xl pl-2">
                    Don't have an account? Create one
                  </span>
                </Link> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
