import moment from "moment-timezone";

/**
 * Converts a given time in a specific timezone to Nigerian time.
 * @param {string} time - The time to convert (format: "YYYY-MM-DD HH:mm:ss").
 * @param {string} timeZone - The source timezone (e.g., "Africa/Kigali").
 * @returns {string} - The time converted to Nigerian time (format: "YYYY-MM-DD HH:mm:ss").
 */
export const convertToNigerianTime = (time, timeZone, format) => {
  // Define Nigerian timezone
  const nigerianTimeZone = "Africa/Lagos";

  // Parse the time in the given timezone
  const timeInSourceTimeZone = moment.tz(time, timeZone);

  if (nigerianTimeZone == timeZone) {
  }
  // Convert to Nigerian time
  const timeInNigerianTime = timeInSourceTimeZone.tz(nigerianTimeZone);

  return timeInNigerianTime.format(format ?? "YYYY-MM-DD HH:mm:ss");
};

/**
 * Gets the current time in the user's local timezone.
 * @returns {string} - The current time in the user's local timezone (format: "YYYY-MM-DD HH:mm:ss").
 */
export const getCurrentLocalTime = () => {
  return moment().format("YYYY-MM-DD HH:mm:ss");
};
