import { HiHome } from "react-icons/hi";
import PageNav from "../../PageNav";
import moment from "moment";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { FcCalendar } from "react-icons/fc";
import { setToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import DataTable, { TableColumn } from "react-data-table-component";
import { useDispatch } from "react-redux";
import {
  setCountry,
  setProjectSiteId,
  setProjectSiteName,
  settimezone,
} from "../../../redux/slice/projectsiteSlice";
import {
  updateRole,
  updateUsername,
} from "../../../redux/slice/userDetailSlice";
import { setNavtitle } from "../../../redux/slice/layoutSlice";
import { tableCustomStyles } from "../../../helperFuncs";
const UserRolesTable = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      name: "Created by",
      selector: (row) => row.amount,
      center: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">
              {item?.creator ? item?.creator : "Chanels"}
            </div>
          </div>
        );
      },
    },
    {
      name: "Username",
      selector: (row) => row.username,
      center: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">
              {item?.username ? item?.username : "-"}
            </div>
          </div>
        );
      },
    },
    {
      name: "Site Managed",
      center: true,
      selector: (row) => row.description,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">
              {item?.sitename ? item?.sitename : "-Nil-"}
            </div>
          </div>
        );
      },
    },

    {
      name: "Role",
      center: true,
      selector: (row) => row.role,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px] text-secondary">{item?.role}</div>
          </div>
        );
      },
    },
    {
      name: "Date assigned",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[12px]">
              {" "}
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <div className="flex rounded-md items-center justify-between">
            <div
              onClick={async () => {
                try {
                  // await setToken("spiral_site", item?.projectzone);
                  dispatch(updateUsername(item?.username));
                  dispatch(setProjectSiteId(item?.siteid) ?? "");

                  dispatch(setProjectSiteName(item?.sitename ?? ""));
                  dispatch(setCountry(item?.country ?? ""));

                  dispatch(settimezone(item?.timezone));

                  switch (item?.role) {
                    case "System Manager":
                      dispatch(updateRole("System Manager"));
                      await setToken("spiral_role", "System Manager");
                      navigate(`/dashboard/systemadmin`);
                      dispatch(setNavtitle("System Manager Dashboard"));
                      return;
                    case "Project Manager":
                      dispatch(updateRole("Project Manager"));
                      dispatch(setNavtitle("Project Manager Dashboard"));

                      navigate(`/dashboard/projectmanager`);
                      return;
                    case "Customer Representative":
                      dispatch(updateRole("Customer Representative"));
                      dispatch(setNavtitle("Customer Rep Dashboard"));
                      navigate(`/dashboard/customerrepresentative`);
                      return;
                    case "Customer Manager":
                      dispatch(updateRole("Customer Manager"));
                      dispatch(setNavtitle("Customer Manager Dashboard"));
                      navigate(`/dashboard/customermanager`);
                      return;
                    // case "Customer Agent":
                    //   dispatch(updateRole("Customer Agent"));
                    //   dispatch(setNavtitle("Customer Agent Dashboard"));
                    //   return;
                    case "Sales Agent":
                      dispatch(updateRole("Sales Agent"));
                      dispatch(setNavtitle("Sales Agent Dashboard"));
                      navigate(`/dashboard/salesagent`);
                      return;

                    default:
                      break;
                  }
                } catch (err) {
                  toast.error(err.message);
                }
              }}
              className="p-1 border-[1px] border-gray-400 rounded-sm mr-3 cursor-pointer"
            >
              View dashboard
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      style={{
        height: 400,
      }}
      columns={columns}
      data={data}
      pagination
      paginationPerPage={10}
      customStyles={tableCustomStyles}
    />
  );
};

export default UserRolesTable;
