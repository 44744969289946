import React, { useState, useEffect, useCallback, useMemo } from "react";
import Layout from "../../../../components/Layout";
import { useAsync } from "../../../../utils/Hooks/useAsync";
import { client } from "../../../../utils/api";
import { Link, useParams } from "react-router-dom";
import ProfileImg from "../../../../assets/svg/projectimg.svg";
import moment from "moment";
import SiteDetailsCard from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCard";
import SiteDetailsCardRight from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCardRight";
import BackBtn from "../../../../components/BackBtn";
import { FaUserShield } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
/** Styles */
import "./styles.css";
import Rule from "../../../../components/Rule/Index";
import { useGetSiteDetailsMutation } from "../../../../services/projectsite.services";
import { countryList } from "../../../../helperFuncs/countryList";
import { errorBlock } from "../../../../controllers/errorBlock";
import { formatName } from "../../../../utils/helpers";
import { useGetDetailedTransactionAnalyticsMutation } from "../../../../services/salesanalytics.services";
import {
  useGetAllMeterCustomersMutation,
  useGetCustomerInZoneMutation,
} from "../../../../services/customer.services";
import { useGetDCUsMutation } from "../../../../services/dcu.services";
import { useGetMetersInSiteMutation } from "../../../../services/meter.services";
const SysAdminDashboard = () => {
  const [currency, setcurrency] = useState("");
  const [tab, settab] = useState("customer");

  const { projectsite, username } = useParams();
  const [
    getDetailedTransactionAnalytics,
    { isLoading: revenueloading, data: revenueData, error: revenueError },
  ] = useGetDetailedTransactionAnalyticsMutation();

  const [
    getCustomerInZone,
    { isLoading: customerloading, data: customerData, error: customerError },
  ] = useGetCustomerInZoneMutation();

  const [
    getMetersInSite,
    { isLoading: meterloading, data: meterData, error: meterError },
  ] = useGetMetersInSiteMutation();

  const { data: userDetails, run: getUser } = useAsync({
    data: [],
    status: "pending",
  });

  const { data: userRevenue, run: getsaleshistoryperzone } = useAsync({
    data: [],
    status: "pending",
  });

  const { data: meters, run: getallmetersinzone } = useAsync({
    data: {},
    status: "pending",
  });

  // get user
  useEffect(() => {
    getUser(
      client(`/auth/useraccount/getuser`, {
        data: { username },
        method: "POST",
      })
    );
  }, [getUser, username]);

  // get sales history per zone based on vending
  const [startdate, setstartdate] = useState(
    moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  );
  const [endate, setendate] = useState(
    moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  );

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  const [getDCUs, { isLoading: dculoading, error: dcuError, data: dcuData }] =
    useGetDCUsMutation();

  // get Site details
  useEffect(() => {
    getSiteDetails({
      siteid: projectsite,
    }).unwrap();
  }, []);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  // revenue from registration date
  useEffect(() => {
    if (userDetails?.dateregistered) {
      try {
        getDetailedTransactionAnalytics({
          siteid: projectsite,
          startdate: userDetails?.dateregistered,
          enddate: endate,
          initiatedby: "",
          paymentgateway: "",
        })
          .unwrap()
          .catch((err) => {
            errorBlock(err);
          });
      } catch (error) {
        errorBlock(error);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (revenueError) {
      errorBlock(revenueError);
    }
  }, []);

  // dcu count
  useEffect(() => {
    try {
      getDCUs({
        projectsite,
      })
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, [userDetails]);

  useEffect(() => {
    if (dcuError) {
      errorBlock(dcuError);
    }
  }, []);

  // customers in zone
  useEffect(() => {
    try {
      getCustomerInZone({
        projectsite,
      })
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, []);

  useEffect(() => {
    if (customerError) {
      errorBlock(customerError);
    }
  }, []);

  // meters in zone
  useEffect(() => {
    try {
      getMetersInSite(projectsite)
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, []);

  useEffect(() => {
    if (customerError) {
      errorBlock(customerError);
    }
  }, []);

  return (
    <Layout>
      <div className="mb-40">
        {<BackBtn text={"Go back"} />}
        <div className="">
          <div className=" p-2 rounded shadow mt-3 bg-white items-center">
            <div className="flex items-cente justify-between">
              <div className="flex items-center ">
                <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                <div className="ml-2">
                  <h1 className="m-0">
                    Performance Records of:{" "}
                    <span className="font-bold">
                      {formatName(userDetails?.firstname ?? "nill") +
                        " " +
                        formatName(userDetails?.lastname ?? "nill")}{" "}
                      ({formatName(userDetails?.username ?? "nill")})
                    </span>
                  </h1>
                  <div className="hidden">
                    Details of project site managed by{" "}
                    <span className="text-secondary font-bold">
                      {/* {userDetails?.username
                        ? `${userDetails?.username[0].toUpperCase()}${userDetails?.username.substring(
                            1
                          )} `
                        : ""} */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-[170px] justify-end">
                Site managed:
                {siteData?.sitename && (
                  <Link
                    to={`/dashboard/projectsite/${siteData?.siteid}`}
                    className=" font-semibold underline pl-2"
                  >
                    {siteData?.sitename}
                  </Link>
                )}
              </div>
            </div>
            {/* user details */}
            <div className=" mt-4 grid grid-cols-5 gap-4 w-full py-4 border rounded">
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaUserShield size={24} />
                </div>
                <div className="text-gray-400">Project Site Name</div>
                {siteData?.sitename && (
                  <Link
                    to={`/dashboard/projectsite/${siteData?.siteid}`}
                    className=" font-semibold underline pl-2"
                  >
                    {siteData?.sitename}
                  </Link>
                )}
              </div>
              {/* full name */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaUserShield size={24} />
                </div>
                <div className="text-gray-400">Full Name</div>
                <div className="font-semibold">
                  {!userDetails?.firstname
                    ? "Registration not complete"
                    : userDetails?.firstname + " " + userDetails?.lastname}
                </div>
              </div>
              {/* Email */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdMarkEmailRead size={24} />
                </div>
                <div className="text-gray-400">Email</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.email}
                </div>
              </div>
              {/* Phone */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaPhone size={24} />
                </div>
                <div className="text-gray-400">Phone</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.phone}
                </div>
              </div>
              {/* Date registered */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdDateRange size={24} />
                </div>
                <div className="text-gray-400">Date registered</div>
                <div className="font-semibold">
                  {moment(userDetails?.dateregistered).format("LLL")}
                </div>
              </div>
            </div>

            <div className="w-full hidden project-datails__title">
              Project manager details for{" "}
              <span className="zone-name">{projectsite}</span>
            </div>
          </div>
          <div className="hidden flex-row p-3 bg-white">
            <div className="hidden md:block lg:block xl:block w-2/12">
              <div className="project-img flex items-center justify-center">
                <img
                  src={ProfileImg}
                  alt="img"
                  style={{ width: "70px", height: "70px" }}
                />
              </div>
            </div>
            <div className="hidden flex-grow-0 flex-shrink-0 w-1/3">
              <div className="flex-row items-center justify-between">
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry flex items-center justify-between  ">
                      <div className="project-title text-sm">Full Name</div>
                      <div className="project-value">
                        {!userDetails?.firstname
                          ? "Registration not complete"
                          : userDetails?.firstname +
                            " " +
                            userDetails?.lastname}
                      </div>
                    </div>
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title">Phone Number</div>
                      <div className="project-value">{userDetails?.phone}</div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title ">Email</div>
                      <div className="project-value">
                        {!userDetails?.email
                          ? "Registration not complete."
                          : userDetails?.email}
                      </div>
                    </div>
                    <div className="project-entry flex mt-2 items-center justify-between">
                      <div className="project-title">Date Registered</div>
                      <div className="project-value">
                        {moment(userDetails?.dateregistered).format("LLL")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Revenue generate in the site  */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="flex bg-blue-50 p-2 cursor-pointer items-center justify-between">
              <div className="font-semibold">Revenue and Customer Records</div>
              <div className=" cursor-pointer">
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            <div className="grid grid-cols-2 gap-2 mt-2">
              {/* Revenue */}
              <div className="border flex flex-col justify-between rounded-md p-2">
                <div className="">
                  <div className="flex items-start justify-between">
                    <h1 className="font-normal">
                      Revenue generated since{" "}
                      {userDetails ? userDetails?.username : " "} was made a
                      <br /> project manager.
                    </h1>
                    <Link
                      to={`/dashboard/records/revenue/${projectsite}/${username}`}
                      className="underline"
                    >
                      Explore more
                    </Link>
                  </div>

                  <p className="font-bold m-0 pt-4 text-end !text-[30px]">
                    {Number(
                      revenueData?.cumulativeStats?.netRevenue ?? 0
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    <sup>{currency}</sup>
                  </p>
                </div>
              </div>
              {/* Customer */}
              <div className="border flex flex-col justify-between rounded-md p-2">
                <div className="">
                  <div className="flex items-start justify-between">
                    <h1 className="font-normal">
                      Number of customers onboarded since{" "}
                      {userDetails ? userDetails?.username : " "} <br />
                      was made a project manager.
                    </h1>
                    <Link
                      to={`/dashboard/records/revenue/${projectsite}/${username}`}
                      className="hidden underline"
                    >
                      Explore more
                    </Link>
                  </div>

                  <p className="font-bold m-0 pt-4 text-end !text-[30px]">
                    {customerData?.meta?.count ?? 0}
                    {/* <sup>{currency}</sup> */}
                  </p>
                </div>
              </div>

              <div className="bg-white rounded-1 shadow-sm"></div>
            </div>
          </div>
          {/* DCU and Meter Records  */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="flex bg-blue-50 p-2 cursor-pointer items-center justify-between">
              <div className="font-semibold">DCU and Meter Records</div>
              <div className=" cursor-pointer">
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            <div className="grid grid-cols-2 gap-2 mt-2">
              {/* DCU */}
              <div className="border flex flex-col justify-between rounded-md p-2">
                <div className="">
                  <div className="flex items-start justify-between">
                    <h1 className="font-normal">
                      Device concentrator units (DCU) added since{" "}
                      {userDetails ? userDetails?.username : " "} was made a
                      <br /> project manager.
                    </h1>
                    <Link
                      to={`/dashboard/records/revenue/${projectsite}/${username}`}
                      className="hidden underline"
                    >
                      Explore more
                    </Link>
                  </div>

                  <p className="font-bold m-0 pt-4 text-end !text-[30px]">
                    {dcuData?.meta.count ?? 0}
                  </p>
                </div>
              </div>
              {/* Meter */}
              <div className="border flex flex-col justify-between rounded-md p-2">
                <div className="">
                  <div className="flex items-start justify-between">
                    <h1 className="font-normal">
                      Number of registered (active) meters in site since{" "}
                      {userDetails ? userDetails?.username : " "} <br />
                      was made a project manager.
                    </h1>
                    <Link
                      to={`/dashboard/records/revenue/${projectsite}/${username}`}
                      className="hidden underline"
                    >
                      Explore more
                    </Link>
                  </div>

                  <p className="font-bold m-0 pt-4 text-end !text-[30px]">
                    {meterData?.meta?.count ?? 0}
                    {/* <sup>{currency}</sup> */}
                  </p>
                </div>
              </div>

              <div className="bg-white rounded-1 shadow-sm"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default SysAdminDashboard;
