import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const analyticAPI = createApi({
  reducerPath: "analyticPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers) => {
      // Add additional header preparations here if necessary
    },
  }),
  endpoints: (build) => ({
    // POST
    getProjectDailyEnergyAnalyticsSummary: build.mutation({
      query: (data) => ({
        url: "energyanalytics/projectmanager/getdailysiteenergyanalyticssummary",
        method: "POST",
        body: {
          ...credentials,
          siteid: data?.siteid, // JSON array of meter data
          startdate: data?.startdate, // e.g., "2024-12-01 00:00:00"
          enddate: data?.enddate, // e.g., "2024-12-31 23:59:59"
        },
      }),
    }),
    // Energy analytics
    getEnergyAnalyticsSummary: build.mutation({
      query: (data) => ({
        url: "energyanalytics/consumer/getenergyanalyticssummaryformeters",
        method: "POST",
        body: {
          ...credentials,
          meterjsonarray: data?.meterjsonarray, // JSON array of meter data
          startdate: data?.startdate, // e.g., "2024-12-01 00:00:00"
          enddate: data?.enddate, // e.g., "2024-12-31 23:59:59"
        },
      }),
    }),
    // Daily energy analytics
    getDailyEnergyAnalyticsSummary: build.mutation({
      query: (data) => ({
        url: "energyanalytics/consumer/getdailyenergyanalyticssummaryformeters",
        method: "POST",
        body: {
          ...credentials,
          meterjsonarray: data?.meterjsonarray, // JSON array of meter data
          startdate: data?.startdate, // e.g., "2024-12-01 00:00:00"
          enddate: data?.enddate, // e.g., "2024-12-31 23:59:59"
        },
      }),
    }),
    // POST
    getDailySiteWideEnergyAnalyticsSummary: build.mutation({
      query: (data) => ({
        url: "energyanalytics/systemmanager/getcompanywidedailyenergyanalyticssummary",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    // POST
    getBasicPowerQauality: build.mutation({
      query: (data) => ({
        url: "energyanalytics/meter/getbasicpowerquality",
        method: "POST",
        body: {
          ...credentials,
          meterjsonarray: data?.meterjsonarray,
          startdate: data?.startdate, // e.g., "2024-12-01 00:00:00"
          enddate: data?.enddate, // e.g., "2024-12-31 23:59:59"
        },
      }),
    }),
    // POST
    getDetailedPowerQauality: build.mutation({
      query: (data) => ({
        url: "energyanalytics/meter/getdetailedpowerquality",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
  }),
});

export const {
  useGetBasicPowerQaualityMutation,
  useGetDetailedPowerQaualityMutation,
  useGetDailyEnergyAnalyticsSummaryMutation,
  useGetEnergyAnalyticsSummaryMutation,
  useGetProjectDailyEnergyAnalyticsSummaryMutation,
  useGetDailySiteWideEnergyAnalyticsSummaryMutation,
} = analyticAPI;
