import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Rule from "../../components/Rule/Index";
import TableSkimmer from "../../components/TableSkimmer";
import WalletCard from "../../components/Wallet/Cards/WalletCards";
import WalletHistoryTable from "../../components/Wallet/Table/WalletHistoryTable";
import { channelController } from "../../controllers/channelController";
import { errorBlock } from "../../controllers/errorBlock";
import { getToken } from "../../utils/token";
import DateRangePicker from "../../components/DateRangePicker";
import moment from "moment";
import { toast } from "react-hot-toast";
import { billingController } from "../../controllers/BillingController";
import { currencyOptions } from "./walletData";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getWalletBalance,
//   getWallethistory,
// } from "../../redux/actions/billing.action";
import { useGetSiteDetailsMutation } from "../../services/projectsite.services";
import { countryList } from "../../helperFuncs/countryList";
import { convertToNigerianTime } from "../../utils/timezones";

const Wallet = () => {
  const dispatch = useDispatch();

  const wallet = useSelector((state) => state.billing);
  const { username } = useSelector((state) => state.userDetails);
  const { siteid, country, timezone } = useSelector(
    (state) => state.projectSite
  );

  const [balance, setbalance] = useState(0);
  const [details, setdetails] = useState(null);
  const [tranx, settranx] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract(1, "month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [limit, setlimit] = useState(12);
  const [loading, setloading] = useState(true);
  const [analytics, setanalytics] = useState({});
  const [offset, setoffset] = useState(0);
  const [currency, setcurrency] = useState(0);

  const [modal, setmodal] = useState(false);
  const [openmodal, setopenmodal] = useState(false);

  const [
    getSiteDetails,
    { isLoading: siteLoading, data: siteData, error: siteError },
  ] = useGetSiteDetailsMutation();

  useEffect(() => {
    getSiteDetails({ siteid });
  }, []);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  // console.log(siteData, "site data");
  // transaction analytics
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/wallet/salesagent/walletanalyticsforsalesagent",
          {
            username,
            currencycode: currencyOptions[currency].tag,
            startdate: timezone
              ? convertToNigerianTime(startdate, timezone)
              : startdate,
            enddate: timezone
              ? convertToNigerianTime(enddate, timezone)
              : enddate,
          }
        );

        const result = billingController(res);
        if (result.type != "success") {
          return toast.error(result.message);
        }

        setanalytics(result.message);
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, [refresh, limit, offset, currency]);

  // wallet balance
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/wallet/getwalletbalance", {
          username,
          currencycode: currencyOptions[currency].tag,
        });

        const result = billingController(res);
        if (result.type != "success") {
          return toast.error(result.message);
        }

        setbalance(result.message.response);
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, [refresh, limit, offset, currency]);

  // get wallet balance
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/wallet/salesagent/getwalletrecords", {
          username,
          currencycode: currencyOptions[currency].tag,
          startdate: timezone
            ? convertToNigerianTime(startdate, timezone)
            : startdate,
          enddate: timezone
            ? convertToNigerianTime(enddate, timezone)
            : enddate,
        });

        const result = billingController(res);
        if (result.type != "success") {
          return toast.error(result.message);
        }

        setloading(false);
        settranx(result.message.body);
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, [refresh, limit, offset, currency]);

  const handleRevenuefilter = (e) => {
    switch (e) {
      case "today":
        setstartdate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "week":
        setstartdate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "month":
        setstartdate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "year":
        setstartdate(
          moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }

    setenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
    setrefresh((prev) => !prev);
  };

  return (
    <Layout>
      <div className="bg-white rounded-md drop-shadow-md p-3 mt-2 mb-10">
        <div className="flex mb-3 justify-between items-center">
          <div className="">
            <PageTitle
              title={"Transactions on your wallet"}
              caption={
                "List of vending and topups transaction on your account."
              }
            />
          </div>
          <DateRangePicker
            setEndDate={setenddate}
            setStartDate={setstartdate}
            setRefresh={setrefresh}
            startDate={startdate}
            endDate={enddate}
          />
          {/* <FilterDateRange
            open={openmodal}
            handlefilter={handleRevenuefilter}
          /> */}
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          <WalletCard
            value={balance}
            title={"Wallet Balance"}
            type={"balance"}
            currencyOptions={currencyOptions}
            currency={countryList[country]["Currency Code"] ?? ""}
            setcurrency={setcurrency}
          />
          <WalletCard
            value={analytics?.totalTopUpAmount ?? 0}
            title={"Total Credits"}
            currencyOptions={currencyOptions}
            currency={countryList[country]["Currency Code"] ?? ""}
            type={"credit"}
            setcurrency={setcurrency}
          />
          <WalletCard
            value={0}
            title={"Total Debits"}
            type={"spent"}
            currencyOptions={currencyOptions}
            currency={countryList[country]["Currency Code"] ?? ""}
            setcurrency={setcurrency}
          />
        </div>
        <div className="mt-3">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <PageTitle
                title={"Wallet history"}
                caption={"View wallet transaction history"}
              />
              <div className="hidden">
                <DateRangePicker
                  refreshBtn={setrefresh}
                  setEndDate={setenddate}
                  setStartDate={setstartdate}
                  desc
                />
              </div>
            </div>
          </div>

          <Rule />
          <div className="">
            {wallet?.loading ? (
              <TableSkimmer entries={12} col={7} />
            ) : (
              <WalletHistoryTable
                loading={loading}
                letHistoryTable
                setdetails={setdetails}
                setmodal={setmodal}
                data={tranx}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default Wallet;
