import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const salesanalyticsAPI = createApi({
  reducerPath: "salesPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      // You can prepare headers here if needed
    },
  }),
  endpoints: (build) => ({
    getSalesBySysAdmin: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getsystemmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getSalesByProjectManager: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getprojectmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          projectmanagerusername: data?.username,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getCustomerMeterVendHistory: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getcustomermeterpurchases",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          meterid: data?.meterid,
        },
      }),
    }),
    getTopPerformingAgentSiteWide: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettopperformingsalesagentsorganizationwide",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          limit: data?.limit,
        },
      }),
    }),

    getCumulativeSalesAgentAnalytics: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getcumulativesalesagentanalytics",
        method: "POST",
        body: {
          ...credentials,
          username: data?.username,
          siteid: data?.siteid,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getCustomerMeterPurchases: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getcustomermeterpurchases",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getCustomersWithDecliningOrders: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getcustomerswithdecliningorders",
        method: "POST",
        body: {
          ...credentials,
          siteid: data?.siteid,
          thresholdpercentage: data?.thresholdpercentage,
          monthstocompare: data?.monthstocompare,
        },
      }),
    }),
    getDailySalesAgentAnalytics: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getdailysalesagentanalytics",
        method: "POST",
        body: {
          ...credentials,
          username: data?.username,
          siteid: data?.siteid,
          startdate: data?.startdate,
          enddate: data?.enddate,
          limit: data?.limit,
          offset: data?.offset,
        },
      }),
    }),
    getDetailedTransactionAnalytics: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getdetailedtransactionanalytics",
        method: "POST",
        body: {
          ...credentials,
          siteid: data?.siteid,
          startdate: data?.startdate,
          enddate: data?.enddate,
          initiatedby: data?.initiatedby || "",
          paymentgateway: data?.paymentgateway || "",
        },
      }),
    }),
    getOrderDetailsByChannelIdAndCreator: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getorderdetailsbychannelidandcreator",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          creator: data?.creator,
          limit: data?.limit,
          offset: data?.offset,
        },
      }),
    }),
    getProjectManagerAnalytics: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getprojectmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          projectmanagerusername: data?.projectmanagerusername,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getSystemManagerAnalytics: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getsystemmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getTopPerformingSalesAgentsOrganizationWide: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettopperformingsalesagentsorganizationwide",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          limit: data?.limit,
        },
      }),
    }),
    getTopPerformingSalesAgentsPerSite: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettopperformingsalesagentspersite",
        method: "POST",
        body: {
          ...credentials,
          siteid: data?.siteid,
          startdate: data?.startdate,
          enddate: data?.enddate,
          limit: data?.limit,
        },
      }),
    }),
    getTransactionDetailsByCreator: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettransactiondetailsbycreator",
        method: "POST",
        body: {
          ...credentials,
          creator: data?.creator,
          currencycode: data?.currencycode,
          limit: data?.limit,
          offset: data?.offset,
        },
      }),
    }),
    getTransactionDetailsByCreatorAndSite: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettransactiondetailsbycreatorandsite",
        method: "POST",
        body: {
          ...credentials,
          siteid: data?.siteid,
          creator: data?.creator,
          limit: data?.limit,
          offset: data?.offset,
        },
      }),
    }),
    getTransactionDetailsByPaymentId: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettransactiondetailsbypaymentid",
        method: "POST",
        body: {
          ...credentials,
          paymenttransactionid: data?.paymenttransactionid,
        },
      }),
    }),
    getTransactionDetailsBySiteId: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettransactiondetailsbysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: data?.siteid,
          limit: data?.limit,
          offset: data?.offset,
        },
      }),
    }),
  }),
});

export const {
  useGetSalesBySysAdminMutation,
  useGetSalesByProjectManagerMutation,
  useGetCustomerMeterVendHistoryMutation,
  useGetTopPerformingAgentSiteWideMutation,
  //
  useGetCumulativeSalesAgentAnalyticsMutation,
  useGetCustomerMeterPurchasesMutation,
  useGetCustomersWithDecliningOrdersMutation,
  useGetDailySalesAgentAnalyticsMutation,
  useGetDetailedTransactionAnalyticsMutation,
  useGetOrderDetailsByChannelIdAndCreatorMutation,
  useGetProjectManagerAnalyticsMutation,
  useGetSystemManagerAnalyticsMutation,
  useGetTopPerformingSalesAgentsOrganizationWideMutation,
  useGetTopPerformingSalesAgentsPerSiteMutation,
  useGetTransactionDetailsByCreatorMutation,
  useGetTransactionDetailsByCreatorAndSiteMutation,
  useGetTransactionDetailsByPaymentIdMutation,
  useGetTransactionDetailsBySiteIdMutation,
} = salesanalyticsAPI;
