import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const projectSiteAPI = createApi({
  reducerPath: "sitePath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    getSiteDetails: build.mutation({
      query: (data) => ({
        url: "sitemanagement/getspecificsitebysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: Number(data?.siteid),
        },
      }),
    }),
    // Mutation for adding a new site
    addNewSite: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/addnewsite",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    // Mutation for deleting a site
    deleteSite: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/deletesite",
        method: "POST",
        body: {
          ...credentials,
          sitename: data.sitename,
        },
      }),
      invalidatesTags: ["Sites"],
    }),
    // Mutation for getting all site names
    getAllSiteNames: build.mutation({
      query: () => ({
        url: "/sitemanagement/getallsitenames",
        method: "POST",
        body: {
          ...credentials,
        },
      }),
      invalidatesTags: ["Sites"],
    }),
    // Mutation for getting all site details
    getAllSiteDetails: build.mutation({
      query: () => ({
        url: "/sitemanagement/getallsitesdetails",
        method: "POST",
        body: {
          ...credentials,
        },
      }),
    }),
    // Mutation for getting a specific site by site ID
    getSpecificSiteBySiteId: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/getspecificsitebysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: data.siteid,
        },
      }),
      providesTags: ["Sites"],
    }),
    // Mutation for getting a specific site by site name
    getSpecificSiteBySiteName: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/getspecificsitebysitename",
        method: "POST",
        body: {
          ...credentials,
          sitename: data.sitename,
        },
      }),
    }),
    // Mutation for updating the country by site ID
    updateCountryBySiteId: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updatecountrybysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: data.siteid,
          country: data.country,
        },
      }),
    }),
    // Mutation for updating the country by site name
    updateCountryBySiteName: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updatecountrybysitename",
        method: "POST",
        body: {
          ...credentials,
          sitename: data.sitename,
          country: data.country,
        },
      }),
    }),
    // Mutation for updating financing by site ID
    updateFinancingBySiteId: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updatefinancingbysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: data.siteid,
          financing: data.financing,
        },
      }),
    }),
    // Mutation for updating GPS by site ID
    updateGPSBySiteId: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updategpsbysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: data.siteid,
          gps: data.gps,
        },
      }),
    }),
    // Mutation for updating GPS by site name
    updateGPSBySiteName: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updategpsbysitename",
        method: "POST",
        body: {
          ...credentials,
          sitename: data.sitename,
          gps: data.gps,
        },
      }),
    }),
    // Mutation for updating site name by site ID
    updateSiteNameBySiteId: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updatesitenamebysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: data.siteid,
          newsitename: data.newsitename,
        },
      }),
    }),
    // Mutation for updating site name by site name
    updateSiteNameBySiteName: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updatesitenamebysitename",
        method: "POST",
        body: {
          ...credentials,
          sitename: data.sitename,
          newsitename: data.newsitename,
        },
      }),
    }),
    // Mutation for updating site type
    updateSiteType: build.mutation({
      query: (data) => ({
        url: "/sitemanagement/updatesitetype",
        method: "POST",
        body: {
          ...credentials,
          sitename: data.sitename,
          sitetype: data.sitetype,
        },
      }),
    }),
  }),
});

export const { useGetSiteDetailsMutation } = projectSiteAPI;
