import CloseButton from "../../../../CloseButton";
import Rule from "../../../../Rule/Index";
import { useForm } from "react-hook-form";
import ErrorText from "../../../../Errors/ErrorText";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../../../utils/axios";
import { Button, Empty, Select } from "antd";
import { CustomButton } from "../../../../CustomButton";
import { meterController } from "../../../../../controllers/MeterController";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../../../controllers/errorBlock";
import BottomModal from "../../../../GeneralModals/BottomModal";
// import AllMetersOnDCU from "../../Table/AllMetersOnDCU";
import TableSkimmer from "../../../../TableSkimmer";
import ConfirmationModal from "../../../../Settings/Modals/ConfirmationModal";
import { useRemoveMeterFromDCUMutation } from "../../../../../services/dcu.services";

const ViewMeterOnDCU = ({ open, setopen, detials, setrefresh }) => {
  const [loading, setloading] = useState(false);
  const [loadingmeter, setloadingmeter] = useState(false);
  const [addmeter, setaddmeter] = useState(false);
  const [inputfield, setinputfield] = useState("");
  const [selectedmeter, setselectedmeter] = useState("");
  const [data, setdata] = useState([]);
  const [confirmation, setconfirmation] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { siteid } = useSelector((state) => state.projectSite);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/sitemanagement/dcu/getmetersondcu", {
          dcuid: detials?.dcuid,
        });

        setdata(res.data.body);
        setloading(false);
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, []);

  const handleAddMeter = async (data) => {
    try {
      setloadingmeter(true);
      let res = await axios.post("/sitemanagement/dcu/addmetertodcu", {
        siteid: siteid,
        dcuid: detials?.dcuid,
        meterid: data?.meterid,
      });
      let result = meterController(res);

      if (result.type !== "success") {
        setloadingmeter(false);
        toast[result.type](result.message);
        return;
      }
      setaddmeter(false);
      toast.success("Meter added to DCU succefully.");
      // setopen(false);
      setrefresh((prev) => !prev);
    } catch (err) {
      errorBlock(err);
      setloadingmeter(false);
    }
  };
  const [removeMeterFromDCU, { isLoading, data: removeData, error }] =
    useRemoveMeterFromDCUMutation();

  const handleRemoveMeter = async (data) => {
    try {
      removeMeterFromDCU({
        dcuid: detials?.dcuid,
        meterid: selectedmeter,
      })
        .then((res) => {
          if (res.error) {
            errorBlock(res);
            return;
          }
          if (res.data.response !== "success") {
            toast.error("Failed to remove meter from DCU");
            setconfirmation(false);

            return;
          }
          toast.success(res.data.response);
          setconfirmation(false);
        })
        .catch((err) => {
          errorBlock(err);
        });
    } catch (err) {
      errorBlock(err);
    }
  };

  const handleContiue = () => {
    setconfirmation(true);
    handleRemoveMeter(detials);
  };

  return (
    <BottomModal open={open}>
      {confirmation && (
        <ConfirmationModal
          title={"Remove meter from DCU"}
          handleContinue={handleContiue}
          description={`Are you sure you want to remove meter (${selectedmeter})
           from from DCU (${detials?.dcuid})? The meter will no longer receive updates from the DCU.
          Type <span class="bg-gray-100 rounded-md px-[4px] cursor-pointer">I understand</span> to continue.`}
          isModalOpen={confirmation}
          setIsModalOpen={setconfirmation}
          btntext={"Remove meter"}
        />
      )}
      <div className="flex  items-start w-full justify-between">
        <div className="">
          <div className="font-semibold text-[17px]">Meters in DCU</div>
          <div className="text-gray-700 font-light">
            List of meters available on DCU
          </div>
        </div>
        <div className="flex justify-end">
          <CloseButton closeBtn={setopen} />
        </div>
      </div>
      <Rule />
      <div className="">
        <div className="flex justify-between items-center">
          <div className="flex">
            <div className="">
              DCU Id: <span className="font-semibold">{detials?.dcuid}</span>{" "}
            </div>
            <div className="border-l border-gray-300 mx-2 border-[1px]"></div>
            <div className="">
              DCU name:{" "}
              <span className="font-semibold">{detials?.dcualias}</span>{" "}
            </div>
          </div>
          <div className="flex items-center">
            <div className="ml-2 flex overflow-hidden md:w-[200px] lg:w-[200px] items-center w-full border-[1px] rounded-md border-gray-300  h-[40px] w-100">
              <input
                type={"text"}
                placeholder={"Search for meter..."}
                onChange={(e) => setinputfield(e.target.value)}
                className="p-2 w-full placeholder:text-sm block w-100 text-[13] placeholder:font-normal outline-none border-[0px]"
              />
            </div>

            <CustomButton
              onClick={() => {
                setaddmeter(true);
              }}
              className={
                "border-[1px] cursor-pointer ml-2 bg-black rounded h-[40px] text-white text-[12px] font-light px-4 py-2"
              }
            >
              <p className="m-0">Add new meter</p>
            </CustomButton>
          </div>
        </div>

        {loading && (
          <div className="">
            {Array.from(Array(4)).map((i, index) => (
              <div key={index} className="my-2 ">
                <TableSkimmer entries={3} />
              </div>
            ))}
          </div>
        )}
      </div>
      <Rule />

      {!addmeter &&
        (data.length == 0 ? (
          <div className="w-full flex  items-center justify-center">
            <Empty description={"No meter has been added on the DCU"} />
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-4 place-content-center ">
            {data.map((i, index) => (
              <div className="border rounded p-2" key={index}>
                <div className="">
                  <p className="m-0 font-semibold"> Submeter ID</p>
                  <div className="block w-fit bg-gray-100 px-3 py-2 rounded-md my-3 underline">
                    {i.meter}
                  </div>
                  <div className="grid grid-cols-1 gap-4 mt-6">
                    <Button
                      onClick={() => {
                        setselectedmeter(i.meter);
                        setconfirmation(true);
                      }}
                      className="!bg-red-500 !border-none !outline-none !text-white"
                    >
                      Remove meter
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}

      {addmeter && (
        <form
          onSubmit={handleSubmit(handleAddMeter)}
          className="w-[430px] mx-auto"
        >
          <div className="mt-4">
            <label>Enter meter number</label>
            <div className="flex w-full bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[40px]">
              <input
                required
                {...register("meterid", { required: true })}
                type={"text"}
                placeholder={"Enter"}
                className="bg-gray-100  p-2 placeholder:font-thin w-full outline-none border-[0px]"
              />
            </div>
            {errors.meterid && <ErrorText />}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <CustomButton
              onClick={(e) => {
                e.preventDefault();
                setaddmeter(false);
              }}
              className={
                "border border-black flex items-center justify-center h-[40px] w-full mt-4 rounded-md"
              }
            >
              <p className="m-0">Cancel</p>
            </CustomButton>
            <CustomButton
              loading={loadingmeter}
              className={
                "bg-black flex items-center justify-center h-[40px] w-full mt-4 rounded-md"
              }
            >
              <p className="text-white m-0">{"Add"}</p>
            </CustomButton>
          </div>
        </form>
      )}
    </BottomModal>
  );
};

export default ViewMeterOnDCU;
