import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
// import MeterInfoModal from "../../MeterInfoModal";
import { toast } from "react-hot-toast";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import SetLoadPolicy from "../Modals/SetLoadPolicy";
import MeterInfoModal from "../../modals/MeterInfoModal";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Dropdown, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import { tableCustomStyles } from "../../../../helperFuncs";
import AssignMeter from "../../modals/AssignMeter";

const AllMeterTable = ({ data }) => {
  const [selectMeter, setselectMeter] = useState(null);
  const [loading, setloading] = useState(null);
  const [openassign, setopenassign] = useState(false);
  const [customer, setcustomer] = useState(null);
  const [loadType, setloadType] = useState(null);
  const [showSet, setshowSet] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);
  const userDetials = useSelector((state) => state.userDetails);

  const handleLoad = async (type, number) => {
    try {
      if (type == "set") {
        return setshowSet(true);
      } else if (type == "get") {
        let res = await axios.post("/meter/getpermeterloadcontrolpolicy", {
          meternumber: number,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);

          setloadType(null);
          setselectMeter(null);
          return;
        }
        // console.log(result.message, "/get");
        setloadType(null);
        setselectMeter(null);
        setloading(false);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", selectedRows);
  };

  const columns = [
    {
      name: "Meter no",
      center: true,
      selector: (row) => row.meternumber,
      grow: 1,
      sortable: true,
    },
    {
      name: "Batch No",
      center: true,
      selector: (row) => row.batchid,
      grow: 2,
      sortable: true,
    },
    {
      name: "Serial no",
      center: true,
      selector: (row) => row.device_serial,
      grow: 1,
      sortable: true,
    },
    {
      name: "Phases",
      center: true,
      selector: (row) => row.phases,
      // grow: 1,
      sortable: true,
    },

    {
      name: "Assigned to",
      center: true,
      selector: (row) => row.assignedto,
      grow: 1,
      sortable: true,
    },

    // {
    //   name: "Load limit",
    //   center: true,
    //   selector: (row) => row.loadlimit,
    //   grow: 1,
    //   sortable: true,
    // },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[13px]">
              {moment(item.dateregistered).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.siteid,
      // grow: userDetials?.role != "Project Manager" ? 2 : 4,
      center: true,
      cell: (item) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label:
                    item?.assignedto === "-" ? (
                      <div
                        onClick={() => {
                          setSelectedId(item?.meternumber);
                          setModal(true);
                        }}
                        className="text-[12px]"
                      >
                        View details
                      </div>
                    ) : (
                      <Link
                        to={`/meter-details/${item.assignedto}/${item.meternumber}`}
                        className="text-[12px]"
                      >
                        View details
                      </Link>
                    ),
                },
                {
                  key: "2",
                  disabled:
                    userDetials?.role === "Customer Manager" &&
                    item?.assignedto === "-"
                      ? false
                      : true,
                  label: (
                    <div
                      onClick={() => {
                        // setSelectedId(item?.meternumber);
                        // setModal(true);
                        setcustomer(item);

                        setopenassign(true);
                      }}
                      className="text-[12px]"
                    >
                      Assign to customer
                      {/* {JSON.stringify(userDetials)} */}
                    </div>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <TbDotsVertical color="black" />
              </Space>
            </a>
          </Dropdown>
          // <div className="flex relative rounded-md items-center justify-between">
          //   <div
          //     onClick={() => {
          //       // console.log(item, "meter details");
          //       setSelectedId(item?.meternumber);
          //       setModal(true);
          //     }}
          //     className="flex border-[1px] cursor-pointer ml-2 rounded border-secondary text-[12px] text-secondary font-light px-4 py-2"
          //   >
          //     Details
          //   </div>
          //   {/* {userDetials?.role == "Project Manager" && (
          //     <div className="flex ">
          //       <div
          //         onClick={() => {
          //           setloadType("get");
          //           setselectMeter(item?.number);
          //           handleLoad("get", item?.number);
          //         }}
          //         className=" border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
          //       >
          //         Get load policy
          //       </div>
          //       <div
          //         onClick={() => {
          //           setselectMeter(item?.number);
          //           setshowSet(true);
          //         }}
          //         // to={`/dashboard/systemadmin/projectsite/${item.sitename}`}
          //         className=" border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
          //       >
          //         Set load policy
          //       </div>
          //     </div>
          //   )} */}
          // </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        borderTop: "1px solid #eee",
      }}
    >
      {openassign && (
        <AssignMeter
          open={openassign}
          setopen={setopenassign}
          user={customer}
        />
      )}
      {/* AssignMeter */}
      {modal && (
        <MeterInfoModal
          setopen={setModal}
          open={modal}
          meterNumber={selectedId}
        />
      )}
      {showSet && (
        <SetLoadPolicy meterId={selectMeter} closeModal={setshowSet} />
      )}
      <DataTable
        // selectableRows
        // onSelectedRowsChange={handleChange}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default AllMeterTable;
