import { useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import AddDCUToSite from "../modals/AddDCUToSite";
import DCUInfoModal from "../modals/DCUInfoModal";
import { CustomButton } from "../../../CustomButton";
import toast from "react-hot-toast";
import { errorBlock } from "../../../../controllers/errorBlock";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import ViewMeterOnDCU from "../modals/ViewMeterOnDCU";
import { tableCustomStyles } from "../../../../helperFuncs";
import ConfirmationModal from "../../../Settings/Modals/ConfirmationModal";

const AllDCUInSite = ({ data, loading, refreshbtn, tabstate }) => {
  const [details, setdetails] = useState(null);
  const [showinfo, setshowinfo] = useState(false);
  const [selected, setselected] = useState(null);
  const [loadingDCU, setloadingDCU] = useState(false);
  const [open, setopen] = useState(false);
  const [confirmation, setconfirmation] = useState(false);
  const { siteid } = useSelector((state) => state.projectSite);

  const handleRemoveDCU = async (data) => {
    try {
      let res = await axios.post("/sitemanagement/dcu/removedcufromsite", {
        siteid: Number(siteid),
        dcuid: data?.dcuid,
      });

      let result = meterController(res);

      setconfirmation(false);
      toast(result.message?.response);
      refreshbtn((prev) => !prev);
    } catch (err) {
      errorBlock(err);
      setloadingDCU(false);
    }
  };

  const handleContiue = () => {
    setconfirmation(true);
    handleRemoveDCU(selected);
  };
  const columns = [
    {
      name: "DCU Alias",
      center: true,
      selector: (row) => row.dcualias,
      grow: 2,
    },
    {
      name: "DCU Id",
      center: true,
      selector: (row) => row.dcuid,
      grow: 3,
    },
    {
      name: "Date Added",
      center: true,
      selector: (row) => row.dateadded,
      grow: 2,
    },
    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 4,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <div
              onClick={() => {
                setselected(item);
                setopen(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-secondary text-[12px] text-sky-600 font-light px-4 py-2"
            >
              <p className="m-0 text-secondary">View meters on DCU</p>
            </div>
            <CustomButton
              loading={selected?.dcu == item?.dcu && loadingDCU ? true : false}
              className={
                "border-[1px] cursor-pointer ml-2 bg-red-400 rounded border-red-400 text-white text-[12px] font-light px-4 py-2"
              }
              onClick={() => {
                setselected(item);
                setconfirmation(true);
                // handleRemoveDCU(item);
              }}
            >
              <p className="m-0">Remove DCU</p>
            </CustomButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {confirmation && (
        <ConfirmationModal
          title={"Remove DCU from site"}
          handleContinue={handleContiue}
          description={`Are you sure you want to remove DCU (${selected?.dcuid})
           from site ? This action will remove all meter connected to DCU.
          Type <span class="bg-gray-100 rounded-md px-[4px] cursor-pointer">I understand</span> to continue.`}
          isModalOpen={confirmation}
          setIsModalOpen={setconfirmation}
          btntext={"Remove from site"}
        />
      )}
      {open && (
        <ViewMeterOnDCU detials={selected} open={open} setopen={setopen} />
      )}

      {details && (
        <DCUInfoModal open={showinfo} setopen={setshowinfo} dcuinfo={details} />
      )}

      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        customStyles={tableCustomStyles}
        paginationPerPage={10}
      />
    </>
  );
};

export default AllDCUInSite;
