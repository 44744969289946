import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../../utils/axios";
import { Input, Select, Button, Form } from "antd";
import { useSelector } from "react-redux";
import { useEditCustomerMeterMutation } from "../../../../services/customer.services";
import { errorBlock } from "../../../../controllers/errorBlock";

const EditCustomerMeter = ({ open, details, setopen, refresh }) => {
  const [CustomerType, setCustomerType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedcst, setselectedcst] = useState(details?.customertype ?? "");
  const [form] = Form.useForm();
  const siteDetails = useSelector((state) => state.projectSite);

  const [editCustomerMeter, { isLoading }] = useEditCustomerMeterMutation();

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/settings/getdropdown", {
          comboname: "customertype",
        });
        setCustomerType(res.data.list.split(",").map((i) => i.trim()));
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const payload = {
        customerid: details?.customerid,
        meter: details?.meter,
        customertype: selectedcst || details?.customertype,
        section: values.section,
        gps: values.lat && values.lng ? `${values.lat},${values.lng}` : null,
        polenumber: values.polenumber,
        projectzone: Number(siteDetails?.siteid),
        address: values.address,
      };

      const res = await editCustomerMeter(payload).unwrap();

      if (res?.response !== "success") {
        toast.error(res?.response || "Update failed");
        return;
      }

      toast.success("Customer info updated successfully");
      setopen(false);
      refresh((prev) => !prev);
    } catch (err) {
      errorBlock(err);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div>
              <div className="font-semibold text-[17px]">
                Update Customer Info
              </div>
              <div className="text-gray-700">
                Fill the fields to update{" "}
                <span className="font-semibold">
                  {details?.customerid[0].toUpperCase() +
                    details?.customerid.substring(1)}
                  's
                </span>{" "}
                information
              </div>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              polenumber: details?.polenumber,
              section: details?.section,
              lat: details?.gps ? details.gps.split(",")[0] : "",
              lng: details?.gps ? details.gps.split(",")[1] : "",
              address: details?.address,
              customertype: details?.customertype,
            }}
          >
            <Form.Item name="polenumber" label="Pole Number">
              <Input placeholder="Enter pole number..." />
            </Form.Item>

            <Form.Item name="section" label="Section">
              <Input placeholder="Enter section..." />
            </Form.Item>

            <div className="grid grid-cols-2 gap-4">
              <Form.Item name="lat" label="Latitude">
                <Input placeholder="Enter latitude..." />
              </Form.Item>

              <Form.Item name="lng" label="Longitude">
                <Input placeholder="Enter longitude..." />
              </Form.Item>
            </div>

            <Form.Item name="address" label="Address">
              <Input.TextArea placeholder="Enter address..." rows={2} />
            </Form.Item>

            <Form.Item name="customertype" label="Customer Type">
              <Select
                placeholder="Select a customer type"
                options={CustomerType.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => setselectedcst(value)}
              />
            </Form.Item>

            <Rule />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isLoading}
                className="!bg-black !outline-none !border-none !text-white"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Backdrop>
  );
};

export default EditCustomerMeter;
