import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const externalAPI = createApi({
  reducerPath: "externalPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    // Get company info by virtual DCU ID
    getCompanyInfoByDCUID: build.mutation({
      query: (data) => ({
        url: "/external/headend/getcompanyinfobydcuid",
        method: "POST",
        body: {
          ...credentials,
          virtualdcuid: data?.virtualdcuid,
        },
      }),
    }),

    // Get company info by meter ID
    getCompanyInfoByMeterID: build.mutation({
      query: (data) => ({
        url: "/external/headend/getcompanyinfobymeterid",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
        },
      }),
    }),

    // Get company name by company alias
    getCompanyNameByAlias: build.mutation({
      query: (data) => ({
        url: "/external/headend/getcompanynamebycompanyalias",
        method: "POST",
        body: {
          ...credentials,
          companyalias: data?.companyalias,
        },
      }),
    }),

    // Get company name by meter ID
    getCompanyNameByMeterID: build.mutation({
      query: (data) => ({
        url: "/external/headend/getcompanynamebymeterid",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
        },
      }),
    }),
  }),
});

export const {
  useGetCompanyInfoByDCUIDMutation,
  useGetCompanyInfoByMeterIDMutation,
  useGetCompanyNameByAliasMutation,
  useGetCompanyNameByMeterIDMutation,
} = externalAPI;
