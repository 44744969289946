import { useEffect, useState } from "react";
import {
  useParams,
  useSearchParams,
  Link,
  Outlet,
  useNavigate,
} from "react-router-dom";
import BackBtn from "../../../components/BackBtn";

import StatsCard from "../../../components/Dashboards/StatsCard";
import Layout from "../../../components/Layout";

import axios from "../../../utils/axios";
// import axios from "axios"
import moment from "moment";
import TableTop from "../../../components/TableTop";
import Rule from "../../../components/Rule/Index";
import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import { useDispatch, useSelector } from "react-redux";
import { errorBlock } from "../../../controllers/errorBlock";
import RevenueChart from "../../../components/Dashboards/SystemAdmin/Chart/RevenueChart";
import { useAsync } from "../../../utils/Hooks/useAsync";
import { client } from "../../../utils/api";
import { setCountry } from "../../../redux/slice/projectsiteSlice";
import { IoIosLink } from "react-icons/io";
import ProjectsiteManageStats from "../../ProjectManager/Tabs/ProjectsiteManageStats";
import toast from "react-hot-toast";
import MeterClassTable from "../../../components/ProjectManager/MeterClass/Table/MeterClassTable";
import { countryList } from "../../../helperFuncs/countryList";
import { DatePicker } from "antd";
import { useGetProjectManagerRevenueMutation } from "../../../services/revenue.services";
import { useGetDCUsMutation } from "../../../services/dcu.services";
import DateRangePicker from "../../../components/DateRangePicker";
import ProjectManagerWKModal from "../../../components/Walkthrough/ProjectManagerWKModal";
import { convertToNigerianTime } from "../../../utils/timezones";
const ProjectManagerDashboard = () => {
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.userDetails);
  let projectSiteName = useSelector((state) => state.projectSite).sitename;
  let projectSiteId = useSelector((state) => state.projectSite).siteid;
  let country = useSelector((state) => state.projectSite).country;
  let timezone = useSelector((state) => state.projectSite).timezone;
  const [currency, setcurrency] = useState(
    countryList[useSelector((state) => state.projectSite)?.country][
      "Currency Code"
    ]
  );
  const navigate = useNavigate();
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [steps, setsteps] = useState(searchParams.get("steps"));
  // console.log(steps, "steps");
  const [sitedetails, setsitedetails] = useState({});
  const [startDate, setStartDate] = useState(
    moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [refresh, setRefresh] = useState(false);
  const [projectManagers, setProjectManagers] = useState([]);
  const [customerManagers, setCustomerManagers] = useState([]);
  const [meters, setMeters] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [opensteps, setopensteps] = useState(false);
  const [tabstate, settabstate] = useState(1);

  const [
    getProjectManagerRevenue,
    { isLoading: revenueLoading, error: revenueError, data: revenueData },
  ] = useGetProjectManagerRevenueMutation();

  useEffect(() => {
    getProjectManagerRevenue({
      username,
      startdate: convertToNigerianTime(startDate, timezone),
      enddate: convertToNigerianTime(endDate, timezone),
    })
      .then((res) => {
        // console.log(res.data, "response");
      })
      .catch((err) => {
        errorBlock(err);
      });
  }, [refresh]);
  // cmRoles
  const {
    data: cmRoles,
    run: getCmRoles,
    status: cmRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  const [getDCUs, { isLoading: dculoading, error: dcuError, data: dcuData }] =
    useGetDCUsMutation();

  // get all Customer manager
  useEffect(() => {
    getCmRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(projectSiteId)]),
          role: "Customer Manager",
        },
        method: "POST",
      })
    );
  }, [getCmRoles]);

  // crRoles
  const {
    data: crRoles,
    run: getCrRoles,
    status: crRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  // get all Customer representative
  useEffect(() => {
    getCrRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(projectSiteId)]),
          role: "Customer Representative",
        },
        method: "POST",
      })
    );
  }, [getCrRoles]);

  // sl roles
  const {
    data: slRoles,
    run: getSlRoles,
    status: slRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    getSlRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(projectSiteId)]),
          role: "Sales Agent",
        },
        method: "POST",
      })
    );
  }, [getSlRoles]);

  // get site details
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/sitemanagement/getspecificsitebysiteid", {
          siteid: projectSiteId,
        });
        setsitedetails(res.data);

        dispatch(setCountry(res.data.country));
      } catch (err) {
        errorBlock(err);
      }
    })();
  }, []);

  const AgentTopCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        {
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
        }
        <div className="text-3xl font-semibold">
          {Number(value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      </div>
    );
  };

  const WalletBalanceCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
          <div className="font-semibold text-[13px] bg-secondary text-white py-1 px-2 rounded">
            {country ? countryList[country]["Currency Code"] : ""}
          </div>
        </div>

        <div className="text-3xl font-semibold">
          {Number(value)?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
          <sup className="text-[10px]">
            {country ? countryList[country]["Currency Code"] : ""}
          </sup>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getDCUs({ projectsite: projectSiteId })
      .unwrap()
      .catch((err) => errorBlock(err));
  }, []);

  return (
    <Layout>
      {steps === "true" && (
        <ProjectManagerWKModal
          isModalVisible={steps}
          setIsModalVisible={setsteps}
        />
      )}
      <div className=" mt-4">
        <div className="bg-white shadow-sm p-2 rounded-md">
          {/* <BackBtn text={"Go back"} /> */}
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="h-[30px] mr-2 w-[20px] rounded bg-orange-200"></div>
              <div className="">
                <div className="font-semibold ">
                  {projectSiteName} site details
                </div>
                <p className="m-0">Details of the site your manage</p>
              </div>
            </div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              refreshBtn={setRefresh}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
            {/* <   /> */}
          </div>

          {/* top cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-6">
            <WalletBalanceCard
              title={"Revenue "}
              value={revenueData?.cumulativeStats[currency]?.totalRevenue ?? 0}
              caption={`Revenue from site`}
              icon={"credit"}
            />
            <WalletBalanceCard
              title={"Commissions"}
              value={
                revenueData?.cumulativeStats[currency]?.totalCommission ?? 0
              }
              caption={`Total commission paid out`}
              icon={"credit"}
            />
            <AgentTopCard
              title={"Total units sold (kWh)"}
              value={revenueData?.cumulativeStats[currency]?.totalKWh ?? 0}
              caption={`Total units recharged across all meters`}
              icon={"units"}
            />
            <AgentTopCard
              title={"DCU"}
              value={dcuData?.meta.count ?? 0}
              caption={"Total DCUs in site"}
            />
            {/* <AgentTopCard
              title={"Completed sales"}
              value={`0`}
              caption={"Total number of completed sales"}
            /> */}
          </div>
        </div>

        {/* stats and Project site */}
        <div className="grid grid-cols-2 mt-2 gap-2">
          <div className=" p-2 w-full bg-white shadow-md rounded-md">
            <div className="font-semibold">Project site details</div>
            <div className="font-bold text-[30px] mt-1">{projectSiteName}</div>
            <Rule />
            <div className="py-2 flex items-center justify-between">
              <div className="">Site type:</div>
              <div className="font-semibold">
                {sitedetails ? sitedetails?.sitetype : ""}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">GPS:</div>
              {sitedetails?.gps ? (
                <Link
                  to={`/projectsite/location?lat=${
                    sitedetails.gps.split(",")[0]
                  }&long=${sitedetails.gps.split(",")[1]}&siteid=${
                    sitedetails?.siteid
                  }`}
                  className=" flex items-center justify-end font-semibold underline"
                >
                  {sitedetails ? sitedetails?.gps : ""}
                  <div className="">
                    <IoIosLink className="" size={15} />
                  </div>
                </Link>
              ) : (
                <div className=" flex items-center justify-end font-semibold underline">
                  {sitedetails ? sitedetails?.gps : ""}
                  <div className="">
                    <IoIosLink className="" size={15} />
                  </div>
                </div>
              )}
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">Country:</div>
              <div className="font-semibold">
                {sitedetails?.country ?? "-Nil-"}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">Timezone:</div>
              <div className="font-semibold">
                {sitedetails?.timezone ?? "-Nil-"}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">Date created:</div>
              <div className="font-semibold">
                {sitedetails
                  ? moment(sitedetails?.datecreated).format("lll")
                  : ""}
              </div>
            </div>
          </div>
          <div className="row bg-white shadow-md rounded-md">
            <StatsCard
              sitedetails={sitedetails}
              meters={meters}
              meterTxt="Total meters in site"
              customerManagers={crRoles?.count ?? 0} // Customer Representative
              customers={cmRoles?.count ?? 0} // customer
              revenue={slRoles?.count ?? 0} // Sales Agent
            />
          </div>
        </div>

        {/* Switch tabs */}
        <div className="bg-white shadow-md rounded mt-2 p-2">
          <div className="">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => {
                  settabstate(1);
                  navigate("/dashboard/projectmanager");
                }}
                className={
                  tabstate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center rounded-md cursor-pointer px-2 py-[3px] mx-1  text-[14px]`
                }
              >
                Roles in site
              </div>

              <div
                onClick={() => {
                  settabstate(3);
                  navigate("/dashboard/projectmanager/consumption-analytics");
                }}
                className={
                  tabstate == 3
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center rounded-md cursor-pointer px-2 py-[3px] mx-1 text-[14px] `
                }
              >
                View consumption analytics
              </div>
              {/* <div
                onClick={() => {
                  settabstate(4);
                  navigate("/dashboard/projectmanager/topup-history");
                }}
                className={
                  tabstate == 4
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center border-[1px] border-gray-300 rounded-md cursor-pointer px-2 py-[3px] mx-1 text-gray-400 text-[14px] `
                }
              >
                Top up history
              </div> */}
            </div>
          </div>
          {tabstate == 1 && <ProjectsiteManageStats />}
          <Outlet />
        </div>
      </div>
      <div className="h-[300px]"></div>
    </Layout>
  );
};

export default ProjectManagerDashboard;
