export const getInitials = (name) => {
  if (name) {
    const initials = name
      .split(" ")
      .map((name) => `${name.charAt(0).toUpperCase()}`)
      .join("");

    return initials;
  }
  return "N/A";
};

export const isWWWhostname = () => {
  let hostname = window.location.hostname;
  if (/^www\./.test(window.location.hostname)) {
    return hostname.split(".")[1];
  } else if (hostname.startsWith("localhost")) {
    return "ikj";
  } else {
    return hostname.split(".")[0];
  }
};

export const CamelCaseToTitleCase = (str) => {
  const regex = /[a-z][A-Z]/g;
  return str
    .replace(regex, (match) => match[0] + " " + match[1].toUpperCase())
    .replace(/\b\w/g, (match) => match.toUpperCase());
};

export const formatName = (name) => name[0].toUpperCase() + name.substring(1);
