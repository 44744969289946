// import { Button } from "../../components/Button";
import Layout from "../../components/Layout";
import Rule from "../../components/Rule/Index";
import TableSkimmer from "../../components/TableSkimmer";
// import ViewPaymentPlan from "../../components/PaymentPlan/Modals/ViewPaymentPlan";
// import AddPlan from "../../components/PaymentPlan/Drawer/AddPlan";
// import PlanType from "../../components/PaymentPlan/Modals/PlanType";
// import ViewMeterClass from "../../components/MeterClass/Modals/ViewMeterClass";
// import AddMeterClass from "../../components/MeterClass/Drawers/AddMeterClass";
import ProcessingCard from "../../components/Loader/ProcessingCard";
// import MeterClassCard from "../../components/MeterClass/Card/MeterClassCard";
// import { MdOutlineGasMeter } from "react-icons/md";
// import Skimmer from "../../components/Loader/Skimmer";
// import { FaTachometerAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
// import { CustomButton } from "../../components/CustomButton";
import MeterClassTable from "../../components/ProjectManager/MeterClass/Table/MeterClassTable";
import AddMeterClass from "./AddMeterClass";
import AddMeterClassNew from "../../components/ProjectManager/MeterClass/Modals/AddMeterClassNew";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import toast from "react-hot-toast";
import {
  // useGetCustomerClassInSiteMutation,
  useGetOthersiteCustomerClassMutation,
} from "../../services/customerclass.services";
import { errorBlock } from "../../controllers/errorBlock";
import SiteCustomerClassTable from "../../components/ProjectManager/MeterClass/Table/SiteCustomerClassTable";
import { Input, Button } from "antd";
const MeterClass = () => {
  const [data, setdata] = useState([]);
  const [inputname, setinputname] = useState("");
  const [tabstate, settabstate] = useState(1);
  const [loading, setloading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [refresh, setrefresh] = useState(false);

  // const [addplanmodal, setaddplanmodal] = useState(false);
  // const [selectedplantype, setselectedplantype] = useState("");
  const [opendrawer, setopendrawer] = useState(false);
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [openmodal, setopenmodal] = useState(false);
  const [createloading, setcreateloading] = useState(false);
  const [getOthersiteCustomerClass, { isLoading, error, data: othersiteData }] =
    useGetOthersiteCustomerClassMutation();

  useEffect(() => {
    getOthersiteCustomerClass()
      .unwrap()
      .catch((err) => errorBlock(err));

    if (error) {
      errorBlock(error);
    }
  }, []);

  // console.log(othersiteData?.sitesByClasses, "dat");

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/settings/customerclass/getallcustomerclassesforsite",
          {
            siteid: Number(siteid),
          }
        );
        setdata(res.data.customerClasses);
        setloading(false);
      } catch (err) {
        if (err?.response) {
          toast.error(err.response?.data?.response);
          setloading(false);
          return;
        }
        toast.error(err.message);
        setloading(false);
      }
    })();
    return () => {};
  }, [refresh]);

  return (
    <>
      {openmodal && (
        <AddMeterClassNew
          refresh={setrefresh}
          setopen={setopenmodal}
          open={openmodal}
        />
      )}

      {/* {modal && (
        <ViewMeterClass
          details={details}
          setopen={setmodal}
          open={modal}
          refreshBtn={setrefresh}
        />
      )} */}
      {/* {addplanmodal && (
        <PlanType
          open={addplanmodal}
          setopen={setaddplanmodal}
          setselectedplantype={setselectedplantype}
          opendrawer={setopendrawer}
        />
      )} */}

      {createloading && <ProcessingCard open={createloading} />}

      <Layout>
        <div className="mt-2">
          <div className="bg-white h-[90vh] drop-shadow-md rounded mb-24 ">
            <div className=" p-2">
              <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
                <div
                  onClick={() => settabstate(1)}
                  className={
                    tabstate == 1
                      ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                      : `text-center cursor-pointer px-2 py-1 text-[14px]`
                  }
                >
                  Your site customer class
                </div>
                <div
                  onClick={() => settabstate(2)}
                  className={
                    tabstate == 2
                      ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                      : `text-center cursor-pointer text-[14px] px-2 py-1 `
                  }
                >
                  Other site customer class
                </div>
              </div>
            </div>
            <Rule />
            <div className="flex px-3 items-center justify-between">
              <div className="">
                <div className=" text-[17px] font-semibold text-gray-800">
                  All customer class
                </div>
                {tabstate === 1 && (
                  <div className="text-gray-600">
                    List of all customer class created in {sitename}
                  </div>
                )}
                {tabstate === 2 && (
                  <div className="text-gray-600">
                    All project site customer class
                  </div>
                )}
              </div>
              <div className="">
                {tabstate === 1 && (
                  <Button
                    onClick={() => setopenmodal(true)}
                    className="!bg-secondary mr-3 !text-white !border-none !outline-none "
                    size="large"
                  >
                    Add customer class
                  </Button>
                )}
                <Input
                  placeholder="search for meter class..."
                  value={inputname}
                  onChange={(e) => setinputname(e.target.value)}
                  className="h-[40px] block px-2 bg-gray-100 !w-[250px] rounded"
                />
              </div>
            </div>

            {tabstate == 1 && (
              <div className="">
                {loading ? (
                  <TableSkimmer entries={10} col={7} />
                ) : (
                  <>
                    <Rule />
                    <MeterClassTable
                      data={data}
                      setdetails={setdetails}
                      setmodal={setmodal}
                    />
                  </>
                )}
              </div>
            )}
            {tabstate == 2 && (
              <div className="p-2">
                {loading ? (
                  <TableSkimmer entries={10} col={7} />
                ) : (
                  <SiteCustomerClassTable
                    refresh={setrefresh}
                    loading={isLoading}
                    data={othersiteData?.sitesByClasses ?? []}
                  />
                )}
              </div>
            )}
            <div className="block md:hidden p-3">
              {/* {loading
                ? Array.from(Array(6)).map((_, i) => (
                    <div className="rounded-md overflow-hidden" key={i}>
                      <Skimmer heigth={100} />
                    </div>
                  ))
                : data.map((i, index) => (
                    <MeterClassCard
                      data={i}
                      key={index}
                      setdetails={setdetails}
                      setmodal={setmodal}
                    />
                  ))} */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MeterClass;
