import "./styles.css";
import moment from "moment";
import { useState } from "react";

import { Button } from "../../../../Button";
import { themeColor as color } from "../../../../../constant/color";
import Backdrop from "../../../../Backdrop";
import Rule from "../../../../Rule/Index";

const SiteSalesInfo = ({ setopen, open, salesinfo }) => {
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[500px] bg-white rounded-md p-3">
          {/* {JSON.stringify(salesinfo)} */}
          <div className=" pb-1 flex items-center justify-between">
            <div className="font-semibold">Sales Info</div>
            <div className=" cursor-pointer" onClick={() => setopen(false)}>
              {/* <IoClose size={18} color={color.darkColor} /> */}
              Close
            </div>
          </div>
          <hr />
          <div className=" h-[500px] overflow-x-hidden overflow-y-scroll">
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Meter number:</div>
              <div className="font-semibold">{salesinfo?.meter ?? "-"}</div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Iniated by:</div>
              <div className="font-semibold">
                {salesinfo?.initiatedBy ?? "-"}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Units:</div>
              <div className="font-semibold">
                {!salesinfo?.kwh ? "-" : `${salesinfo?.kwh} kWh`}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Plan purchase:</div>
              <div className="font-semibold">
                {" "}
                {salesinfo?.planAlias == "kWh##Untimed"
                  ? "Untimed purchase"
                  : salesinfo?.planAlias}
              </div>
            </div>
            {/* REVENUE */}
            <div className="font-semibold  p-1 rounded mt-2">Revenue</div>
            <div className=" mt-2 flex items-center justify-between">
              <div className="">Amount:</div>
              <div className="flex font-semibold">
                {Number(salesinfo?.amount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Net revenue:</div>
              <div className="flex font-semibold">
                {Number(salesinfo?.netRevenue).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Commission paid:</div>
              <div className="flex font-semibold">
                {Number(salesinfo?.commission).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Gateway used:</div>
              <div className="flex font-semibold">
                {salesinfo?.paymentGateway ?? 0}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Transaction Id:</div>
              <div className="flex font-semibold">
                {salesinfo?.paymentTransactionId ?? 0}
              </div>
            </div>
            {/* BREAKDOWN */}
            {salesinfo?.breakdown ? (
              <>
                <div className="font-semibold p-1 rounded mt-2">Breakdown</div>
                <div className="flex bg-gray-100 p-1 items-center justify-between">
                  <div className="">Units (kwh)</div>
                  <div className="">Price/kwh</div>
                  <div className="">Total cost</div>
                </div>
                {salesinfo?.breakdown.map((i) => (
                  <div
                    key={i}
                    className="px-1 mt-2 pb-1 odd:border-b-[1px] border-gray-200 flex items-center justify-between"
                  >
                    <div className="">{i.kWh.toFixed(2)}</div>
                    <div className="">{i.pricePerKWh}</div>
                    <div className="">{i.totalCost ?? 0}</div>
                  </div>
                ))}
              </>
            ) : (
              ""
            )}
            <Rule />
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Order status message:</div>
              <div className="font-semibold">
                {salesinfo?.orderStatusMessage
                  ? moment(salesinfo?.orderStatusMessage)
                      .subtract("1", "week")
                      .format("DD-MM-YYYY HH:mm")
                  : "-"}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Order date:</div>
              <div className="font-semibold">
                {salesinfo?.orderDate
                  ? moment(salesinfo?.orderDate)
                      .subtract("1", "week")
                      .format("DD-MM-YYYY HH:mm")
                  : "-"}
              </div>
            </div>
            <div className="row mt-4 d-flex align-items-center justify-content-end">
              <div className="col-4" onClick={() => setopen(false)}>
                <Button
                  text={"Done"}
                  bg={color.black}
                  color={color.whiteColor}
                  height={"35px"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default SiteSalesInfo;
