import moment from "moment";
import { toast } from "react-hot-toast";
import { channelController } from "../../../../controllers/channelController";
import { Dropdown, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import axios from "../../../../utils/axios";
import { FcCalendar } from "react-icons/fc";
import DataTable from "react-data-table-component";

import { useState } from "react";
import { tableCustomStyles } from "../../../../helperFuncs";
import { formatName } from "../../../../utils/helpers";
import { AppLoader } from "../../../AppLoader";
import ConfirmationModal from "../../../Settings/Modals/ConfirmationModal";
import { errorBlock } from "../../../../controllers/errorBlock";
import { UserProfileUpdate } from "../../../Dashboards/SystemAdmin/Modal/UserProfileUpdate";

const UserTable = ({ data, refreshBtn }) => {
  const [username, setUsername] = useState("");
  const [modal, setmodal] = useState(false);
  const [selecteduser, setSelecteduser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleActivate = async (item) => {
    try {
      if (!item?.username) {
        toast.error("Please select a username");
        return;
      }
      setLoading(true);
      let res = await axios.post("/auth/activateuseraccount", {
        username: item?.username,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setMsg("Activating user account");
      toast.success("Account deactivated successfully.");
      setLoading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      setLoading(false);
      // toast.error(err.message);
      errorBlock(err);
    }
  };

  const handleDeactivate = async () => {
    try {
      if (!selecteduser?.username) {
        toast.error("Please select a username");
        return;
      }
      setLoading(true);
      let res = await axios.post("/auth/deactivateuseraccount", {
        username: selecteduser?.username,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setMsg("Activating user account");
      toast.success("Account deactivated successfully.");
      setLoading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      errorBlock(err);
      setLoading(false);
      // toast.error(err.message);
    }
  };

  const columns = [
    {
      name: "Username",
      center: true,
      selector: (row) => row.username,
      grow: 1,
      cell: (item) => {
        return (
          <div className="text-[dodgerblue]">
            {formatName(item?.username ?? "Nil")}
          </div>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.email,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="">
            {!item?.firstname
              ? "Incomplete registration"
              : formatName(item?.firstname) + " " + formatName(item?.lastname)}
          </div>
        );
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      center: true,
      sortable: true,
      grow: 3,
      cell: (item) => {
        return (
          <div>
            {item?.email ?? (
              <span className="text-[dodgerblue]">Incomplete registration</span>
            )}
          </div>
        );
      },
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      center: true,
      grow: 2,
    },
    {
      name: "Active status",
      center: true,
      selector: (row) => row.activestatus,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div
            className={`font-semibold ${
              item?.activestatus == "active" ? "text-green-700" : "text-red-700"
            }`}
          >
            {item?.activestatus}
          </div>
        );
      },
    },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.dateregistered).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 1,
      center: true,
      cell: (item) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <div className="">
                      {item.activestatus == "active" ? (
                        <div
                          onClick={() => {
                            setSelecteduser(item);
                            setmodal(true);
                            // handleDeactivate(item);
                          }}
                          className=""
                        >
                          Deactivate
                        </div>
                      ) : (
                        <div onClick={() => handleActivate(item)} className="">
                          Activate
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <div
                      onClick={() => {
                        setSelecteduser(item);
                        setIsModalVisible(true);
                      }}
                    >
                      Update Account
                    </div>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <TbDotsVertical color="black" />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {modal && (
        <ConfirmationModal
          title={"Account Deactivation"}
          description={`Are you sure you want to deactivate this user's account? This action will temporarily suspend all account activities, including access to services and functionalities. The user will no longer be able to perform any actions until the account is reactivated. Type <span class="bg-gray-100 rounded-md px-[4px] cursor-pointer">I understand</span> to continue.`}
          isModalOpen={modal}
          setIsModalOpen={setmodal}
          handleContinue={handleDeactivate}
        />
      )}
      {isModalVisible && (
        <UserProfileUpdate
          refreshBtn={refreshBtn}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          selecteduser={selecteduser}
        />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        className=" cursor-pointer"
        columns={columns}
        data={data}
        pagination
        // highlightOnHover
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </>
  );
};

export default UserTable;
