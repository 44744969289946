import { useParams } from "react-router-dom";
import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import axios from "../../..//utils/axios";
import DateRangePicker from "../../../components/DateRangePicker";
import { getToken } from "../../../utils/token";
import { useSelector } from "react-redux";
import {
  useGetAgentRevenueMutation,
  useGetSalesAgentCummulativeAnalyticsMutation,
} from "../../../services/revenue.services";
import SalesAgentRevenueTable from "../../../components/Dashboards/SalesAgent/Table/SalesAgentRevenueTable";
import { currencyOptions } from "../../Wallet/walletData";
import Rule from "../../../components/Rule/Index";
import FilterDateRange from "../../../components/FilterDateRange";
import { revenueFields } from "../../../helperFuncs";
import { convertToNigerianTime } from "../../../utils/timezones";
const SalesAnalytics = () => {
  const { site } = useParams();
  const [currency, setcurrency] = useState(0);
  const [selectedRevenue, setselectedRevenue] = useState(revenueFields[0]);

  const { sitename, siteid, timezone } = useSelector(
    (state) => state.projectSite
  );
  const { username } = useSelector((state) => state.userDetails);
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD HH:mm:ss")
  );
  const [tabstate, settabstate] = useState(2);
  const [offset, setoffset] = useState(0);
  const [modal, setmodal] = useState(false);
  const [limit, setlimit] = useState(100);
  const [getAgentRevenue, { isLoading, error, data }] =
    useGetAgentRevenueMutation();

  const [
    getSalesAgentCummulativeAnalytics,
    {
      isLoading: cummulativeLoading,
      error: cummulativeError,
      data: cummulativeData,
    },
  ] = useGetSalesAgentCummulativeAnalyticsMutation();
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [refresh, setRefresh] = useState(false);
  const [paymentHistory, setPaymetHistory] = useState([]);
  const [revenue, setRevenue] = useState(0);

  const SalesBalanceCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
          {/* <div className="font-semibold text-[13px] bg-secondary text-white py-1 px-2 rounded">
                {country ? countryList[country]["Currency Code"] : "-Nil-"}
            </div> */}
        </div>
        <div className="text-3xl font-semibold">
          {Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) ?? 0}
          <sup className="mt-[-10px] text-[12px]">
            {/* {currencyOptions[currency].tag}{" "} */}
          </sup>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getAgentRevenue({
      username,
      siteid,
      startdate: timezone
        ? convertToNigerianTime(startdate, timezone)
        : startdate,
      enddate: timezone ? convertToNigerianTime(enddate, timezone) : enddate,
      limit,
      offset,
    }).unwrap();
  }, [refresh]);

  useEffect(() => {
    getSalesAgentCummulativeAnalytics({
      username,
      siteid,
      startdate: timezone
        ? convertToNigerianTime(startdate, timezone)
        : startdate,
      enddate: timezone ? convertToNigerianTime(enddate, timezone) : enddate,
    }).unwrap();
  }, [refresh]);

  const AgentTopCard = ({ title, value, caption, icon, type }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-3xl font-semibold">
          {type == "count"
            ? value
            : Number(value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          {(type == "sales" || type == "balance" || type == "spent") && (
            <sup className="mt-[-10px] text-[12px]">
              {currencyOptions[currency].tag}{" "}
            </sup>
          )}
          {type == "units" && <sup className=" text-[12px] font-bold">kWh</sup>}
        </div>
      </div>
    );
  };

  const handleRevenuefilter = (e) => {
    switch (e) {
      case "today":
        setstartdate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );

        break;
      case "week":
        setstartdate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );

        break;
      case "month":
        setstartdate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }
    setenddate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));

    setRefresh((prev) => !prev);
  };
  return (
    <Layout>
      <div className="bg-white rounded rounde-lg mb-10 mt-1 h-auto drop-shadow-md p-3">
        <div className="flex items-center justify-between">
          <PageTitle
            title={"Sales Analytics in" + sitename} //sitename
            caption={"Sales recorded in site"}
          />
          <div className="flex">
            <DateRangePicker
              setEndDate={setstartdate}
              setRefresh={setRefresh}
              startDate={startdate}
              endDate={enddate}
              refreshBtn={setRefresh}
            />
            {/* <FilterDateRange
              setEndDate={setstartdate}
              setRefresh={setRefresh}
              open={modal}
              setopen={setmodal}
              handlefilter={handleRevenuefilter}
              setStartDate={setenddate}
            /> */}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-6">
          <SalesBalanceCard
            title={"Total amount of sales"}
            value={data?.cumulativeStats.totalRevenue ?? 0}
            caption={`Summary of sales made in site`}
            icon={"balance"}
            type={"sales"}
          />
          <AgentTopCard
            title={"Number of transactions"}
            value={data?.cumulativeStats.transactionCount ?? 0}
            caption={`Total number sales made `}
            icon={"balance"}
            type={"count"}
          />
          <AgentTopCard
            title={"Total units sold (kWh)"}
            type={"units"}
            value={data?.cumulativeStats.totalKWh ?? 0}
            caption={`Total units recharged acroos all meters`}
            icon={"credit"}
          />
          <AgentTopCard
            title={"Total paid out commission"}
            value={data?.cumulativeStats.totalCommission ?? 0}
            caption={`Total units recharged acroos all meters`}
            icon={"credit"}
            type={"sales"}
          />
          {/* <AgentTopCard title={"Pending sales"} value={id} /> */}
        </div>

        <div className="flex mt-4 items-center justify-between">
          <div className="text-[16px] font-semibold">Sales records</div>
          {/* tab state */}
          <div className="px-3 ">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => settabstate(1)}
                className={
                  tabstate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center cursor-pointer px-2 py-1 text-[14px]`
                }
              >
                Chart
              </div>
              <div
                onClick={() => settabstate(2)}
                className={
                  tabstate == 2
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center cursor-pointer text-[14px] px-2 py-1 `
                }
              >
                Table
              </div>
            </div>
          </div>
        </div>
        <Rule />
        {tabstate == 1 && (
          <div className="">
            <div className="flex mb-3 items-center  mt-3 border-gray-200 w-full">
              <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
                {tabstate == 1 &&
                  revenueFields.map((i) => (
                    <div className="px-1">
                      <div
                        onClick={() => setselectedRevenue(i)}
                        className={
                          selectedRevenue.id == i.id
                            ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                            : `text-center cursor-pointer text-gray-500 px-2 py-1 text-[14px]`
                        }
                      >
                        {i.title}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="h-[500px]">
              <SalesLineChart
                selectedRevenue={selectedRevenue}
                data={data?.dailyStats ?? []}
                currency={currencyOptions[currency].tag ?? ""}
              />
            </div>
          </div>
        )}
        {tabstate == 2 && (
          <div className="h-[80vh]">
            <SalesAgentRevenueTable data={data?.dailyStats ?? []} />
          </div>
        )}
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default SalesAnalytics;
