import React, { useEffect } from "react";
import { Modal, Button, Typography, Carousel, Image } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useGetAllPaymentPlansForSiteMutation } from "../../services/payment.service";
import PlanImg from "../../assets/svg/priceplan.svg";
const { Paragraph, Text } = Typography;

// Custom arrow components
const CustomPrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    // style={{
    //   ...style,
    //   fontSize: "16px",
    //   color: "red", // Change the arrow color here
    //   zIndex: 1,
    // }}
    onClick={onClick}
  >
    <IoIosArrowBack color="black" size={20} />
  </div>
);

const CustomNextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    // style={{
    //   ...style,
    //   fontSize: "16px",
    //   color: "red", // Change the arrow color here
    //   zIndex: 1,
    // }}
    onClick={onClick}
  >
    <IoIosArrowForward color="black" size={20} />
  </div>
);

const ProjectManagerWKModal = ({ isModalVisible, setIsModalVisible }) => {
  const { sitename, siteid } = useSelector((state) => state.projectSite);
  const [getAllPaymentPlansForSite, { isLoading, error, data }] =
    useGetAllPaymentPlansForSiteMutation();

  // console.log(error, "error");
  // console.log(data, "data");
  useEffect(() => {
    getAllPaymentPlansForSite(siteid).then((res) => {
      if (res.error) {
        console.log(res.error, "error");
      } else {
        console.log(res.data, "data");
      }
    });
  }, []);

  const navigate = useNavigate();

  const carouselItems = [
    {
      title: "Create Plan",
      description:
        "Use the option to create customer tariffs or payment plans for all customers in " +
        sitename,
      link: "/paymentplan",
    },
    {
      title: "Add DCU to Site",
      description: "Add Device Concentrator Units to " + sitename,
      link: "/dcu",
    },
    {
      title: "Create Customer Class",
      description: "Create customer class to group each customer type",
      link: "/meterclass",
    },
    {
      title: "Revenue",
      description: "Use this option to view sales recorded in " + sitename,
      link: "/projectmanager/revenue",
    },
  ];

  const handleNavigate = (link) => {
    navigate(link);
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Project Manager Quick Guide"
      open={isModalVisible}
      footer={
        null
        // [
        //   <Button
        //     key="cancel"
        //     type="primary"
        //     className="!bg-black !text-white !border-none !outline-none"
        //     onClick={() => setIsModalVisible(false)}
        //   >
        //     Cancel
        //   </Button>,
        // ]
      }
      onCancel={() => setIsModalVisible(false)}
      centered
    >
      <Carousel
        arrows
        dots={false}
        className="!w-full !mt-4 bg-red-400"
        prevArrow={<CustomPrevArrow className={"px-4 bg-green-600"} />}
        nextArrow={<CustomNextArrow />}
      >
        {carouselItems.map((item, index) => (
          <div key={index} className="!px-6 !flex !flex-col !items-center">
            <Image
              className=" object-contain !w-[60px] !h-[60px]"
              src={PlanImg}
            />
            <Typography className=" text-center !mb-4">
              <Text className="text-lg font-semibold">{item.title}</Text>
              <Paragraph className="text-gray-600 mt-2">
                {item.description}
              </Paragraph>
            </Typography>
            <Button
              type="primary"
              className="!bg-blue-500 !text-white !border-none"
              onClick={() => handleNavigate(item.link)}
            >
              Go to {item.title}
            </Button>
          </div>
        ))}
      </Carousel>
    </Modal>
  );
};

export default ProjectManagerWKModal;
