import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASEURL, APIHeaders } from "../config";
import { apiURL } from "../utils/axios";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const paymentAPI = createApi({
  reducerPath: "paymentAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiURL.mmsUrlDev + "vending/paymentplan",
    headers: APIHeaders,
  }),
  endpoints: (builder) => ({
    // Create Cumulative Energy Plan
    createCumulativeEnergyPlan: builder.mutation({
      query: (data) => ({
        url: "/createcumulativeenergyplan",
        method: "POST",
        body: data,
      }),
    }),

    // Create Daily Energy Plan
    createDailyEnergyPlan: builder.mutation({
      query: (data) => ({
        url: "/createdailyenergyplan",
        method: "POST",
        body: data,
      }),
    }),

    // Create Daily Power Limit Plan
    createDailyPowerLimitPlan: builder.mutation({
      query: (data) => ({
        url: "/createdailypowerlimitplan",
        method: "POST",
        body: data,
      }),
    }),

    // Delete Payment Plan
    deletePaymentPlan: builder.mutation({
      query: (data) => ({
        url: "/deletepaymentplan",
        method: "POST",
        body: data,
      }),
    }),

    // Edit Payment Plan
    editPaymentPlan: builder.mutation({
      query: (data) => ({
        url: "/editpaymentplan",
        method: "POST",
        body: data,
      }),
    }),

    // Get All Payment Plans for Meter
    getAllPaymentPlansForMeter: builder.mutation({
      query: (data) => ({
        url: "/getallpaymentplansformeter",
        method: "POST",
        body: data,
      }),
    }),

    // Get All Payment Plans for Site
    getAllPaymentPlansForSite: builder.mutation({
      query: (data) => ({
        url: "/getallpaymentplansforsite",
        method: "POST",
        body: { ...credentials, siteid: data },
      }),
    }),

    // Get All Payment Plans for Site and Customer Class
    getAllPaymentPlansForSiteAndCustomerClass: builder.mutation({
      query: (data) => ({
        url: "/getallpaymentplansforsiteandcustomerclass",
        method: "POST",
        body: data,
      }),
    }),

    // Get All Payment Plans Grouped by Site
    getAllPaymentPlansGroupedBySite: builder.mutation({
      query: (data) => ({
        url: "/getallpaymentplansgroupedbysite",
        method: "POST",
        body: data,
      }),
    }),

    // Get Specific Payment Plan Info
    getSpecificPaymentPlanInfo: builder.mutation({
      query: (data) => ({
        url: "/getspecificpaymentplaninfo",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useCreateCumulativeEnergyPlanMutation,
  useCreateDailyEnergyPlanMutation,
  useCreateDailyPowerLimitPlanMutation,
  useDeletePaymentPlanMutation,
  useEditPaymentPlanMutation,
  useGetAllPaymentPlansForMeterMutation,
  useGetAllPaymentPlansForSiteMutation,
  useGetAllPaymentPlansForSiteAndCustomerClassMutation,
  useGetSpecificPaymentPlanInfoMutation,
  useGetAllPaymentPlansGroupedBySiteMutation,

  //   useGetAllPaymentPlansForMeter,
  //   useGetAllPaymentPlansForSiteQuery,
  //   useGetAllPaymentPlansForSiteAndCustomerClassQuery,
  //   useGetAllPaymentPlansGroupedBySiteQuery,
  //   useGetSpecificPaymentPlanInfoQuery,
} = paymentAPI;
