import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../helperFuncs";
import { useLocation } from "react-router-dom";
import Layout from "../../Layout";
import { convertToNigerianTime } from "../../../utils/timezones";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../controllers/errorBlock";
import { useSegmentCustomersMutation } from "../../../services/segmentation.services";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  notification,
  DatePicker,
  List,
  Typography,
} from "antd";
import { formatName } from "../../../utils/helpers";
import { countryList } from "../../../helperFuncs/countryList";
import { BiSearch } from "react-icons/bi";
import { useGetDropdownMutation } from "../../../services/generalSetting.service";
import { useGetCustomerClassInSiteMutation } from "../../../services/customerclass.services";
import toast from "react-hot-toast";
import { useGetAllPaymentPlansForSiteMutation } from "../../../services/payment.service";
import { SaveSegmentModal } from "./SaveSegmentModal";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const CustomerSegmentResult = ({}) => {
  const [showResults, setShowResults] = useState(false);
  const [modal, setmodal] = useState(false);
  const [dddata, setdddata] = useState([]);
  const [ppdata, setppdata] = useState([]);
  const [form] = Form.useForm();
  const [ccdata, setccdata] = useState([]);
  const [segmentCustomers, { isLoading }] = useSegmentCustomersMutation();
  const location = useLocation();
  const [resultData, setResultData] = useState([]);
  const [formValues, setFormValues] = useState({});

  const [
    getAllPaymentPlansForSite,
    { isLoading: planslaoding, data: plandata },
  ] = useGetAllPaymentPlansForSiteMutation();

  const { siteid, timezone, country } = useSelector(
    (state) => state.projectSite
  );
  const [getDropdown, { isLoading: droploading, error }] =
    useGetDropdownMutation();
  const [getCustomerClassInSite, { isLoading: cclass, error: ccerror }] =
    useGetCustomerClassInSiteMutation();

  useEffect(() => {
    getAllPaymentPlansForSite(siteid).then((res) => {
      if (res.error) {
        toast.error("Failed to fetch payment plan");
      } else {
        setppdata(res.data.body);
      }
    });
  }, []);

  useEffect(() => {
    getCustomerClassInSite(siteid).then((res) => {
      if (res.error) {
        toast.error("Failed to fetch customer class");
      } else {
        setccdata(res.data?.customerClasses);
      }
    });
  }, []);

  useEffect(() => {
    getDropdown({
      comboname: "customertype",
    }).then((res) => {
      if (res.error) {
        toast.error("Failed to fetch customer type");
      } else {
        setdddata(res.data?.list.split(","));
      }
    });
  }, []);

  const statedefaultValues = location.state?.formattedValues;

  const formatDate = (date) => {
    return timezone
      ? convertToNigerianTime(date, timezone, "YYYY-MM-DD")
      : date;
  };

  const handleFinish = (values) => {
    const formattedValues = {
      customerType: values?.customerType || null,
      customerClass: values?.customerClass || null,
      meterType: values?.meterType || null,
      minTotalPurchase:
        values?.minTotalPurchase === 0 ? null : values?.minTotalPurchase,
      maxTotalPurchase:
        values?.maxTotalPurchase === 0 ? null : values?.maxTotalPurchase,
      minPurchaseFrequency:
        values?.minPurchaseFrequency === 0
          ? null
          : values?.minPurchaseFrequency,
      maxPurchaseFrequency:
        values?.maxPurchaseFrequency === 0
          ? null
          : values?.maxPurchaseFrequency,
      phaseType: values?.phaseType ?? null,
      startDate: values.dateRange ? formatDate(values.dateRange[0]) : null,
      endDate: values.dateRange ? formatDate(values.dateRange[1]) : null,
      paymentPlan: values?.paymentPlan ?? null,
      siteid,
    };

    segmentCustomers(formattedValues)
      .then((res) => {
        if (res.error) {
          errorBlock(res);
          return;
        }
        if (res.data.response === "success" && res.data.count > 0) {
          setResultData(res.data.customers);
        } else {
          setResultData(res.data.customers);
          notification.success({
            message: "Success",
            description: "No result found.",
          });
        }
      })
      .catch((err) => {
        errorBlock(err);
      });

    setFormValues(values); // Update form values state
  };

  useEffect(() => {
    if (statedefaultValues) {
      //   console.log(statedefaultValues, "got default values");
      form.setFieldsValue(statedefaultValues);
      setFormValues(statedefaultValues);
      handleFinish(statedefaultValues);
    }
  }, [statedefaultValues]);

  //   const handleSaveSegment = () => {
  //     // Use formValues here to save the segment
  //     console.log("Saving segment with values:", formValues);
  //   };

  const columns = [
    {
      name: "Customer ID",
      selector: (row) => row.customerId,
      sortable: true,
      center: true,
    },
    {
      name: "Full name",
      selector: (row) => row.firstName,
      sortable: true,
      center: true,
      cell: (row) => (
        <p className="capitalize m-0">
          {row.firstName} {row.lastName}
        </p>
      ),
    },
    {
      name: "Customer Type",
      selector: (row) => row.customerType,
      sortable: true,
      center: true,
    },
    {
      name: "Customer Class",
      selector: (row) => row.customerClass,
      center: true,
    },
    {
      name: "Meter Number",
      selector: (row) => row.meterNumber,
      center: true,
    },
    {
      name: "Phase Type",
      selector: (row) => row.phaseType,
      sortable: true,
      center: true,
    },
    {
      name: "Total Purchase",
      selector: (row) => row.totalPurchase,
      sortable: true,
      center: true,
      format: (row) =>
        `${
          countryList[country]["Currency Code"]
        }${row.totalPurchase.toLocaleString()}`,
    },
    {
      name: "Purchase Freq.",
      selector: (row) => row.purchaseFrequency,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Layout>
      <SaveSegmentModal visible={modal} onClose={setmodal} data={formValues} />
      <div className="p-4 bg-white rounded-md drop-shadow-sm">
        <div className="flex mb-2 border-b-[1px] pb-2 items-center justify-between">
          <h1 className=" m-0 text-lg font-semibold ">
            Customer Segment Results
          </h1>
          {resultData.length > 0 && (
            <Button
              type="primary"
              htmlType="submit"
              className="w-fit !bg-primary !border-none"
              onClick={() => setmodal(true)}
            >
              Save Customer Segment
            </Button>
          )}
        </div>
        <div className="">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={formValues}
            onValuesChange={(changedValues, allValues) => {
              setFormValues(allValues);
            }}
          >
            {showResults && <p className="">No result found for filter</p>}

            <div className="grid grid-cols-5  gap-x-4">
              <Form.Item name="customerType" label={"Customer type"}>
                <Select
                  showSearch
                  showAction
                  placeholder="Select Customer Type"
                >
                  {dddata.map((i, index) => (
                    <Option key={index} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="customerClass" label={"Customer class"}>
                <Select showSearch placeholder="Select Customer Class">
                  {ccdata.map((i, index) => (
                    <Option key={index} value={i.customerClass}>
                      {i.customerClass}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="paymentPlan" label={"Payment plan"}>
                <Select showSearch placeholder="Select Payment plan">
                  {ppdata.map((i, index) => (
                    <Option key={index} value={i.planalias}>
                      {i.planalias}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="minTotalPurchase"
                label={"Mininum Total Purchase"}
              >
                <InputNumber
                  placeholder="Enter Minimum Total Purchase"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="maxTotalPurchase"
                label={"Maximum Total Purchase"}
              >
                <InputNumber
                  placeholder="Enter Maximum Total Purchase"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="minPurchaseFrequency"
                label={"Minimum Purchase Frequency"}
              >
                <InputNumber
                  placeholder="Enter Minimum Purchase Frequency"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="maxPurchaseFrequency"
                label={"Maximum Purchase Frequency"}
              >
                <InputNumber
                  placeholder="Enter Maximum Purchase Frequency"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item name="dateRange" label={"Date Range"}>
                <RangePicker
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item className="flex items-end">
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  className="w-full m-0 !bg-primary !border-none"
                >
                  search
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>

        <DataTable
          columns={columns}
          data={resultData ?? []}
          pagination
          customStyles={tableCustomStyles}
        />
      </div>
    </Layout>
  );
};

export default CustomerSegmentResult;
