import { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import PageTitle from "../../../../components/PageTitle";
import BackBtn from "../../../../components/BackBtn";
import DateRangePicker from "../../../../components/DateRangePicker";
import moment from "moment";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "../../../../utils/axios";
import toast from "react-hot-toast";
import ProjectSitesTable from "../../../../components/ProjectManager/ProjectSite/Tables/ProjectSiteTable";
import { CustomButton } from "../../../../components/CustomButton";
import AddSite from "../../../../components/ProjectManager/ProjectSite/AddSite";
import { errorBlock } from "../../../../controllers/errorBlock";

const ProjectSiteOverview = () => {
  const navigate = useNavigate();
  const [tabstate, settabstate] = useState(1);
  const [addSite, setAddSite] = useState(false);

  const [count, setcount] = useState(0);
  const [selectedSite, setselectedSite] = useState({});
  const [map, setMap] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [projectSites, setProjectSites] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const controller = new AbortController();
  const [loading, setLoading] = useState(true);

  const [startdate, setStartDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  );

  const AgentTopCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-3xl font-semibold">{value}</div>
      </div>
    );
  };

  function getProjectSites() {
    axios
      .post("/sitemanagement/getallsitesdetails", {})
      .then((response) => {
        setLoading(false);
        const { body, meta } = response.data;
        setcount(meta.count);
        setProjectSites(response.data.body);
      })
      .catch((err) => {
        setLoading(false);
        errorBlock(err);
      });
  }

  useEffect(() => {
    getProjectSites();
    return () => {
      controller.abort();
    };
  }, [refresh]);

  useEffect(() => {
    axios
      .post("/settings/getdropdown", { comboname: "Site type" })
      .then((response) => {
        // if (!Object.entries(response.data).length == 0) return null;
        // console.log(response, "dropdown");
        setSiteTypes(response.data.list.split(",").map((t) => t.trim()));
      })
      .catch((err) => {
        errorBlock(err);
      });
    return () => {};
  }, []);
  return (
    <Layout>
      {addSite && (
        <AddSite
          closeModal={setAddSite}
          siteTypes={siteTypes}
          refresh={getProjectSites}
        />
      )}
      <div className="bg-white p-2 shadow rounded">
        <div className="flex items-center justify-between">
          {/* <BackBtn /> */}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="h-[30px]  mr-2 w-[20px] rounded bg-orange-200"></div>
            <PageTitle
              title={"Overview of your project sites"}
              caption={"Insight of activites across your project sites."}
            />
          </div>
          <CustomButton
            onClick={() => setAddSite(true)}
            className="border-[1px] bg-black cursor-pointer ml-2 rounded text-[12px] font-light px-4 py-2"
          >
            <p className="m-0 text-white">Add new site</p>
          </CustomButton>
        </div>

        {/* Top stats card */}
        <div className="hidden grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-6">
          <AgentTopCard
            title={"Project sites"}
            value={count}
            caption={`Number of site manager.`}
            icon={"balance"}
          />
          <AgentTopCard
            title={"Revenue"}
            value={"0"}
            caption={`Total revenue generated across sites`}
            icon={"balance"}
          />
          <AgentTopCard
            title={"Tickets"}
            value={"0"}
            caption={`Total number of tickets raised across site`}
            icon={"credit"}
          />
          <AgentTopCard title={"Pending sales"} value={""} />
        </div>

        {/* Switch tabs */}
        <div className=" p-2">
          {/* <hr className="my-2" /> */}
          <div className="">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => {
                  settabstate(1);
                  navigate("/projectsite");
                }}
                className={
                  tabstate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center  border-gray-300 rounded-md cursor-pointer px-2 py-[3px] mx-1 text-gray-400 text-[14px]`
                }
              >
                Site Overview
              </div>
              {/* <div
                onClick={() => {
                  settabstate(3);
                  navigate("/projectsite/consumption-analytics");
                }}
                className={
                  tabstate == 3
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center  border-gray-300 rounded-md cursor-pointer px-2 py-[3px] mx-1 text-gray-400 text-[14px]`
                }
              >
                Consumption
              </div> */}
              <div
                onClick={() => {
                  settabstate(4);
                  navigate("/projectsite/map-location");
                }}
                className={
                  tabstate == 4
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center  border-gray-300 rounded-md cursor-pointer px-2 py-[3px] mx-1 text-gray-400 text-[14px]`
                }
              >
                Site Location
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />

      {tabstate == 1 && (
        <div className="mt-2 h-[500px] p-2 bg-white shadow-md rounded-md">
          <ProjectSitesTable
            data={projectSites}
            loading={loading}
            selectedSite={selectedSite}
            refresh={setrefresh}
            siteTypes={siteTypes}
            setselectedSite={setselectedSite}
          />
        </div>
      )}
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default ProjectSiteOverview;
