import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Dropdown, Menu, notification } from "antd";
import { RxDotsHorizontal } from "react-icons/rx";
import { tableCustomStyles } from "../../../helperFuncs";
import { useDeleteSegmentMutation } from "../../../services/segmentation.services";
import ConfirmationModal from "../../Settings/Modals/ConfirmationModal";

const CustomerSegmentTable = ({ segmentData, refresh }) => {
  const [selectedSegment, setSelectedSegment] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [deleteSegment, { isLoading }] = useDeleteSegmentMutation();

  const handleContinue = () => {
    deleteSegment(selectedSegment)
      .then((res) => {
        if (res.error) {
          notification.error({
            message: "Failed",
            description: "Failed to delete segment",
          });
        } else {
          notification.success({
            message: "Success",
            description: "Segment deleted successfully",
          });
          setConfirmation(false);
          refresh((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err, "Error in catch block");
      });
  };

  // Columns configuration for the table
  const columns = [
    {
      name: "Alias",
      selector: (row) => row.alias,
      center: true,
      grow: 1,
    },
    {
      name: "Filters Used",
      grow: 4,
      selector: (row) => row.filters,
      center: true,
      cell: (row) => (
        <div className="flex">
          {row.filters
            ? Object.entries(row.filters)
                .filter(([key, value]) => value !== null)
                .map(([key, value], index) => (
                  <div
                    key={index}
                    className="bg-gray-200 rounded-md p-[3px] mx-[2px]"
                  >
                    {key}: {value}
                  </div>
                ))
            : ""}
        </div>
      ),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      center: true,
    },
    {
      name: "Action",
      grow: 1,
      center: true,
      cell: (row) => {
        // Dropdown menu for the "Action" column
        const dropdownMenu = (
          <Menu className="w-[200px]">
            {/* <Menu.Item key="details">Details</Menu.Item> */}
            <Menu.Item
              key="delete"
              className="text-red-400 flex items-center justify-center"
              onClick={() => {
                setSelectedSegment(row.alias); // Set the selected segment alias
                setConfirmation(true); // Open the confirmation modal
              }}
            >
              Delete
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={dropdownMenu} trigger={["hover"]}>
            <div className="cursor-pointer">
              <RxDotsHorizontal />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {confirmation && (
        <ConfirmationModal
          title={"Delete Segmentation"}
          handleContinue={handleContinue}
          description={`Are you sure you want to delete (${selectedSegment})?
            The segment will no longer be available for usage.
            Type <span class="bg-gray-100 rounded-md px-[4px] cursor-pointer">I understand</span> to continue.`}
          isModalOpen={confirmation}
          setIsModalOpen={setConfirmation}
          btntext={"Remove segment"}
        />
      )}
      <DataTable
        columns={columns}
        data={segmentData ?? []}
        pagination
        customStyles={tableCustomStyles}
      />
    </>
  );
};

export default CustomerSegmentTable;
