import { combineReducers } from "redux";
import { authReducerNew } from "../slice/authslice";
import { projectSiteReducer } from "../slice/projectsiteSlice";
import { userDetailsReducer } from "../slice/userDetailSlice";
import { layoutReducer } from "../slice/layoutSlice";
import { domainReducer } from "../slice/domainslice";
import { DCUAPI } from "../../services/dcu.services";
import { meterAPI } from "../../services/meter.services";
import { customerClassAPI } from "../../services/customerclass.services";
import { customerAPI } from "../../services/customer.services";
import { revenueAPI } from "../../services/revenue.services";
import { salesanalyticsAPI } from "../../services/salesanalytics.services";
import { rolesAPI } from "../../services/roles.services";
import { projectSiteAPI } from "../../services/projectsite.services";
import { siteMapAPI } from "../../services/map.services";
import { analyticAPI } from "../../services/analytics.services";
import { commandsAPI } from "../../services/commands.services";
import { commandAnalyticsAPI } from "../../services/command.analytics.services";
import { externalAPI } from "../../services/external.services";
import { authAPI } from "../../services/auth.service";
import { segmentationApi } from "../../services/segmentation.services";
import { paymentAPI } from "../../services/payment.service";

export const rootReducer = combineReducers({
  auth: authReducerNew,
  projectSite: projectSiteReducer,
  userDetails: userDetailsReducer,
  layout: layoutReducer,
  domain: domainReducer,
  [DCUAPI.reducerPath]: DCUAPI.reducer,
  [meterAPI.reducerPath]: meterAPI.reducer,
  [customerClassAPI.reducerPath]: customerClassAPI.reducer,
  [customerAPI.reducerPath]: customerAPI.reducer,
  [revenueAPI.reducerPath]: revenueAPI.reducer,
  [salesanalyticsAPI.reducerPath]: salesanalyticsAPI.reducer,
  [rolesAPI.reducerPath]: rolesAPI.reducer,
  [projectSiteAPI.reducerPath]: projectSiteAPI.reducer,
  [siteMapAPI.reducerPath]: siteMapAPI.reducer,
  [analyticAPI.reducerPath]: analyticAPI.reducer,
  [commandsAPI.reducerPath]: commandsAPI.reducer,
  [commandAnalyticsAPI.reducerPath]: commandAnalyticsAPI.reducer,
  [externalAPI.reducerPath]: externalAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [segmentationApi.reducerPath]: segmentationApi.reducer,
  [paymentAPI.reducerPath]: paymentAPI.reducer,
});
