import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const generalSettingsApi = createApi({
  reducerPath: "generalSettingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL + "settings",
    headers: APIHeaders,
  }),
  endpoints: (builder) => ({
    getDropdown: builder.mutation({
      query: ({ callerid, sessionid, comboname }) => ({
        url: "/getdropdown",
        method: "POST",
        body: { ...credentials, comboname },
      }),
    }),
    getZoneMeterLoadControl: builder.mutation({
      query: ({ callerid, sessionid, zone, metertype }) => ({
        url: "/getzonemeterloadcontrol",
        method: "POST",
        body: { ...credentials, zone, metertype },
      }),
    }),
    getZoneMeterPowerControl: builder.mutation({
      query: ({ callerid, sessionid, zone, metertype }) => ({
        url: "/getzonemeterpowercontrol",
        method: "POST",
        body: { ...credentials, zone, metertype },
      }),
    }),
    setDropdown: builder.mutation({
      query: ({ callerid, sessionid, combolist, comboname }) => ({
        url: "/setdropdown",
        method: "POST",
        body: { ...credentials, combolist, comboname },
      }),
    }),
    setSecretForHeadend: builder.mutation({
      query: ({ callerid, sessionid, secretkey }) => ({
        url: "/setsecretforheadend",
        method: "POST",
        body: { ...credentials, secretkey },
      }),
    }),
    setZoneMeterLoadControl: builder.mutation({
      query: ({ callerid, sessionid, metertype, zone, value }) => ({
        url: "/setzonemeterloadcontrol",
        method: "POST",
        body: { ...credentials, metertype, zone, value },
      }),
    }),
    setZoneMeterPowerControl: builder.mutation({
      query: ({ callerid, sessionid, metertype, zone, value }) => ({
        url: "/setzonemeterpowercontrol",
        method: "POST",
        body: { ...credentials, metertype, zone, value },
      }),
    }),
    getAllActiveMinimumVendsAcrossSites: builder.mutation({
      query: ({ callerid, sessionid }) => ({
        url: "/vending/getallactiveminimumvendsacrosssites",
        method: "POST",
        body: { callerid, sessionid },
      }),
    }),
    getMinimumVendAmount: builder.mutation({
      query: ({ callerid, sessionid, siteid }) => ({
        url: "/vending/getminimumvendamount",
        method: "POST",
        body: { ...credentials, siteid },
      }),
    }),
    getMinimumVendHistory: builder.mutation({
      query: ({ callerid, sessionid, siteid }) => ({
        url: "/vending/getminimumvendhistory",
        method: "POST",
        body: { ...credentials, siteid },
      }),
    }),
  }),
});

export const {
  useGetDropdownMutation,
  useGetZoneMeterLoadControlMutation,
  useGetZoneMeterPowerControlMutation,
  useSetDropdownMutation,
  useSetSecretForHeadendMutation,
  useSetZoneMeterLoadControlMutation,
  useSetZoneMeterPowerControlMutation,
  useGetAllActiveMinimumVendsAcrossSitesMutation,
  useGetMinimumVendAmountMutation,
  useGetMinimumVendHistoryMutation,
} = generalSettingsApi;
