import { useSelector } from "react-redux";
import ActiveDCUImg from "../../../assets/svg/activedcuimg.svg";
import CustomerImg from "../../../assets/svg/customerimg.svg";
import ProjectImg from "../../../assets/svg/projectimg.svg";
import "./styles.css";
import { Link, useParams } from "react-router-dom";

const StatsCard = ({
  meters,
  meterTxt,
  projectManagers,
  customers,
  customerManagers,
  revenue,
  sitedetails,
}) => {
  const { siteid: siteidProjectRole } = useSelector(
    (state) => state.projectSite
  );
  const { siteid } = useParams();

  return (
    <div className="w-full pt-2 py-3 px-2">
      <div className="font-bold">Project site stats.</div>
      <div className="grid grid-cols-2 gap-4 mt-2">
        {/* Total meter is site */}
        <div className="my-2 relative hidden">
          <div
            className="stats-cover p-3 flex items-center justify-center"
            style={{
              background: "#fff8ef",
            }}
          >
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={ActiveDCUImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="stats-details__cover px-2">
              <div className="stats-value text-center">{meters}</div>
              <div className="stats-caption" style={{}}>
                {meterTxt || "Active Meters"}
                {/* Total meters in <br /> site */}
              </div>
            </div>
          </div>
        </div>
        {/* START */}
        <div className="my-2 relative px-1 pt-4 p-0 bg-[#fff8ef] border-[1px] border-[#fdbc67]">
          <div className="flex justify-start items-center">
            <div className="stats-img_cover mx-3 flex items-center justify-center">
              <img
                alt={"img"}
                src={ActiveDCUImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className=" font-semibold text-gray-700">
              {meterTxt || "Active Meters"}
            </div>
          </div>

          <h2 className="text-3xl text-end">{meters}</h2>
          <Link
            className="absolute underline bottom-2 left-4"
            to={`/projectsite/meters/${siteid ?? siteidProjectRole}`}
          >
            View all meters
          </Link>
        </div>
        {/* END */}
        {/* Number Customer Support Representative */}
        <div className="my-2 px-1 relative pt-4 p-0 bg-[#FFF2FE] border-[1px] border-[#D93BCA]">
          <div className="flex justify-center items-center">
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={ProjectImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className=" font-semibold text-gray-700">
              {customerManagers || customerManagers == 0
                ? "Number of Customer Support Representative"
                : "Number of Project Managers"}
              {/* <div className="stats-value text-center">
                {customerManagers || customerManagers == 0
                  ? customerManagers
                  : projectManagers}
              </div> */}
              {/* <div className="stats-caption" style={{}}>
                {customerManagers || customerManagers == 0
                  ? "Number of Customer Support Rep."
                  : "Project Managers"}
              </div> */}
            </div>
          </div>

          <h2 className="text-3xl text-end">
            {customerManagers || customerManagers == 0
              ? customerManagers
              : projectManagers}
          </h2>
          <Link
            className="absolute underline bottom-2 left-4"
            to={`/userroles/projectzone?role=Customer Representative&sitename=${sitedetails?.sitename}&siteid=${sitedetails?.siteid}`}
          >
            View managers
          </Link>
          {/* <div className="stats-details__cover px-2">
              <div className="stats-value text-center">
                {customerManagers || customerManagers == 0
                  ? customerManagers
                  : projectManagers}
              </div>
              <div className="stats-caption" style={{}}>
                {customerManagers || customerManagers == 0
                  ? "Number of Customer Support Rep."
                  : "Project Managers"}
              </div>
            </div> */}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-2">
        <div className="my-2 hidden">
          <div
            className="stats-cover p-3 flex items-center justify-center"
            style={{
              background: "#F8F8F8",
              border: "1px solid #000000",
            }}
          >
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={CustomerImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="stats-details__cover px-2">
              <div className="stats-value text-center">{customers}</div>
              <div className="stats-caption" style={{}}>
                Number of Customer Managers
              </div>
            </div>
          </div>
        </div>
        {/* START */}
        {/* New Customer Managers */}
        <div className="my-2 px-1 relative pt-4 p-0 bg-[#F8F8F8] border-[1px] border-[#000000]">
          <div className="flex justify-start items-center">
            <div className="stats-img_cover mx-3 flex items-center justify-center">
              <img
                alt={"img"}
                src={CustomerImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className=" font-semibold text-gray-700">
              Number of Customer Managers
            </div>
          </div>

          <h2 className="text-3xl text-end">{customers}</h2>
          <Link
            className="absolute underline bottom-2 left-4"
            to={`/userroles/projectzone?role=Customer Manager&sitename=${sitedetails?.sitename}&siteid=${sitedetails?.siteid}`}
          >
            View managers
          </Link>
        </div>
        {/* END */}

        {/* New Sales Agent */}
        <div className="my-2 px-1 relative pt-4 p-0 bg-[#F8F8F8] border-[1px] border-[#000000]">
          <div className="flex justify-start items-center">
            <div className="stats-img_cover mx-3 flex items-center justify-center">
              <img
                alt={"img"}
                src={CustomerImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className=" font-semibold text-gray-700">
              Number of Sales <br />
              Agents
            </div>
          </div>

          <h2 className="text-3xl text-end">{revenue}</h2>
          <Link
            className="absolute underline bottom-2 left-4"
            to={`/userroles/projectzone?role=Sales Agent&sitename=${sitedetails?.sitename}&siteid=${sitedetails?.siteid}`}
          >
            View all agents
          </Link>
        </div>
        {/* END */}

        <div className="my-2 hidden">
          <div
            className="stats-cover p-3 flex items-center justify-center"
            style={{
              background: "#F3FBFF",
              border: "1px solid #2CA3E5",
            }}
          >
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={CustomerImg}
                width={"100%"}
                height={"100%"}
              />
              {/* <img
                alt={"img"}
                src={RevenueImg}
                width={"100%"}
                height={"100%"}
              /> */}
            </div>
            <div className="stats-details__cover px-2">
              <div className="stats-value text-center">{revenue}</div>
              <div className="stats-caption" style={{}}>
                Number of Sales <br />
                Agents
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
