import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import SideNav from "../SideNav";
import LaptopImg from "../../assets/png/4417111_browser_laptop_on_web_browsing_icon.png";
import TopNav from "../TopNav";

const Layout = ({ children }) => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the screen size is mobile
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 800); // You can adjust the width based on your requirement
    };

    // Initial check
    checkIsMobile();

    // Add event listener to update on resize
    window.addEventListener("resize", checkIsMobile);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  // Custom component for mobile view
  const MobileViewComponent = () => (
    <div className="flex items-center justify-center h-screen w-screen bg-white">
      <div className="bg-white p-3  ">
        <div className="p-2 flex flex-col items-center justify-center border rounded ">
          <div className="w-[100px] h-[100px] ">
            <img className="w-full h-full" src={LaptopImg} />
          </div>
          <p className="font-semibold text-center mt-3">
            Kindly use a laptop or a desktop, for a experience
          </p>
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    return <MobileViewComponent />;
  }

  return (
    <div className="h-screen w-screen z-40 fixed items-start top-0 md:flex lg:flex left-0">
      {location.pathname !== "/userroles" && <SideNav />}
      <div
        className="h-screen relative top-0 left-0 bg-[#f1f0f0] overflow-y-scroll"
        style={{
          width: "100%",
        }}
      >
        <div className="sticky top-0 left-0 z-40">
          <TopNav />
        </div>
        <div className="lg:px-4 pt-4 h-screen !z-50">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
