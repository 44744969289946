import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import DataTable from "react-data-table-component";
import { MdOutlineDeleteForever } from "react-icons/md";
import axios from "../../../../utils/axios";
import { TbDotsVertical } from "react-icons/tb";
import toast from "react-hot-toast";
import { tableCustomStyles } from "../../../../helperFuncs";
import { errorBlock } from "../../../../controllers/errorBlock";
import { Dropdown, message, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import Rule from "../../../Rule/Index";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useSelector } from "react-redux";
import { useCreateCustomerClassMutation } from "../../../../services/customerclass.services";

const SiteCustomerClassTable = ({ data, refresh }) => {
  const [selectedsite, setselectedsite] = useState(Object.keys(data)[0] ?? "");
  const [loading, setloading] = useState(false);
  const handleDelete = async (item) => {
    try {
      setloading(true);
      let res = await axios.post("/meterclass/deletesavedmeterclasses", {
        meterclasscode: item,
      });
      toast.success("Meterclass deleted successfully.");
      setloading(false);
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };
  const { siteid } = useSelector((state) => state.projectSite);
  const { username } = useSelector((state) => state.userDetails);
  const [createCustomerClass, { isLoading, data: meterclassdata, error }] =
    useCreateCustomerClassMutation();

  const onSubmit = async (data) => {
    try {
      toast.success("Adding to site", {
        duration: 1000,
      });
      createCustomerClass({
        siteid: Number(siteid),
        customerclass: data.customerClass,
        customertype: data.customerType,
        timeofuse: data?.timeOfUse,
        createdby: username,
      })
        .then((res) => {
          if (res.error) {
            errorBlock(res);
            return;
          }
          if (res.data.respoonse != "success") {
            toast.error(res.data.message);
            return;
          }
          toast.success("Added to site");
        })
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      setloading(false);
      errorBlock(error);
    }
  };

  const columns = [
    {
      name: "Customer class",
      center: true,
      selector: (row) => row.customerClass,
      grow: 1,
    },
    {
      name: "Time of use",
      center: true,
      selector: (row) => row.timeOfUse,
      grow: 2,
    },
    {
      name: "Class type",
      center: true,
      selector: (row) => row.customerType,
      grow: 2,
    },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.dateCreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.dateCreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <div
                        role="button"
                        onClick={() => onSubmit(item)}
                        className="text-[12px]"
                      >
                        Add to site
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div className="text-[12px]">View class details</div>
                    ),
                  },
                ],
              }}
              placement="bottomCenter"
              arrow
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <TbDotsVertical color="black" />
                </Space>
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="">
        {Object.keys(data).map((i, index) => (
          <div key={index}>
            <hr className="my-[2px] " />
            <div
              className="flex p-2 cursor-pointer hover:bg-gray-50 items-center justify-between"
              onClick={() => setselectedsite(i)}
            >
              <div className="text-[14px]">
                Sitename:{" "}
                <span className="font-semibold text-secondary">{i}</span>
              </div>
              <div className=" cursor-pointer">
                {selectedsite == i ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
            </div>
            {selectedsite == i && (
              <div className="mt-2">
                <DataTable
                  style={{
                    height: "100%",
                  }}
                  // progressPending={loadingData}
                  columns={columns}
                  data={data[i]}
                  pagination
                  paginationPerPage={10}
                  customStyles={tableCustomStyles}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default SiteCustomerClassTable;
