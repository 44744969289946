import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const customerAPI = createApi({
  reducerPath: "customerPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    // customer address
    getcustomerAddress: build.mutation({
      query: (data) => ({
        url: "customer/getallmetercustomeraddresses",
        method: "POST",
        body: {
          ...credentials,
          customerid: data,
        },
      }),
    }),
    editCustomerMeter: build.mutation({
      query: (data) => ({
        url: "customer/editcustomeraccount",
        method: "POST",
        body: {
          ...credentials,
          // customerid: data?.customerid,
          meter: data?.meter,
          customertype: data?.customertype,
          section: data?.section,
          gps: data?.gps,
          polenumber: data?.polenumber,
          projectzone: Number(data?.projectzone),
          address: data?.address,

          // meter, customertype, section, gps, polenumber, projectzone, address
        },
      }),
    }),
    // search for user
    searchUser: build.mutation({
      query: (data) => ({
        url: "auth/useraccount/searchuser",
        method: "POST",
        body: {
          ...credentials,
          keyword: data?.keyword,
        },
      }),
    }),
    // get Customer meter
    getAllMeterCustomers: build.mutation({
      query: (data) => ({
        url: "customer/getallmetercustomers",
        method: "POST",
        body: {
          ...credentials,
          column: data?.column, // e.g., meter/customerid/phone/email/agent/section
          columnparameter: data?.columnparameter, // Parameter value for the column
          zone: data?.siteid, // Integer zone
        },
      }),
    }),
    // get all meters in zone
    getCustomerInZone: build.mutation({
      query: (data) => ({
        url: "customer/getallmetercustomersbyzone",
        method: "POST",
        body: {
          ...credentials,
          ...data, // Integer zone
        },
      }),
    }),
  }),
});

export const {
  useGetcustomerAddressMutation,
  useEditCustomerMeterMutation,
  useSearchUserMutation,
  useGetAllMeterCustomersMutation,
  useGetCustomerInZoneMutation,
} = customerAPI;
