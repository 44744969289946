import React, { useState } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { useSaveSegmentMutation } from "../../../services/segmentation.services";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const SaveSegmentModal = ({ visible, onClose, onSave, data }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [saveSegment, { isLoading, error }] = useSaveSegmentMutation();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        saveSegment({
          ...data,
          alias: values.alias,
          description: values.description,
        }).then((res) => {
          if (res.error) {
            notification.error({
              message: "Failed",
              description: "Failed to save customer segmentation",
            });
            return;
          } else {
            notification.success({
              message: "Success",
              description: "Save customer segment successfully",
            });
            navigate("/manage-meter?tab=3");
            onClose();
            form.resetFields(); // Reset form fields
          }
        });
      })
      .catch((error) => {
        toast.error("Validation Failed");
      });
  };

  const handleCancel = () => {
    form.resetFields(); // Reset form fields
    onClose(); // Close the modal
  };

  return (
    <Modal
      title="Save Segment"
      visible={visible}
      onCancel={handleCancel}
      footer={null} // Custom footer to use Ant Design buttons
    >
      <Form
        form={form}
        className="text-sm"
        size="large"
        layout="vertical"
        initialValues={{
          alias: "",
          description: "",
        }}
      >
        <p>Fill the below to save the segment you have filtered</p>
        {/* Alias Field */}
        <Form.Item
          name="alias"
          label="Segment name"
          rules={[
            { required: true, message: "Segment name is required" },
            { max: 50, message: "Segment name must not exceed 50 characters" },
          ]}
        >
          <Input className="!text-sm" placeholder="Enter segment name" />
        </Form.Item>

        {/* Description Field */}
        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: "Description is required" },
            { max: 100, message: "Description must not exceed 100 characters" },
          ]}
        >
          <Input.TextArea
            placeholder="Enter description (max 100 characters)"
            maxLength={100}
            rows={4}
            className="!text-sm"
          />
        </Form.Item>

        {/* Modal Footer */}
        <Form.Item>
          <div className="flex justify-end gap-2">
            {/* <Button onClick={handleCancel}>Cancel</Button> */}
            <Button
              type="primary"
              htmlType="submit"
              handleOk
              loading={isLoading}
              size="default"
              onClick={handleOk}
              className="w-fit !bg-primary !border-none"
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
