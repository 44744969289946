import { useSelector } from "react-redux";
import BackBtn from "../../../components/BackBtn";
import { CustomButton } from "../../../components/CustomButton";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { useEffect, useState, useMemo } from "react";
import { errorBlock } from "../../../controllers/errorBlock";
import axios from "../../../utils/axios";
import PaymentPlanTable from "../../../components/ProjectManager/PaymentPlan/PaymentPlanTable";
import AddPlanModal from "../../../components/ProjectManager/PaymentPlan/modals/AddPlanModal";
import AddDailyEnergyPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddDailyEnergyPlan";
import AddDailyPowerPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddDailyPowerPlan";
import AddCumulativeEnergyPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddCumulativeEnergyPlan";
import AddUnlimitedPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddUnlimitedPlan";
import OtherSitePaymentPlan from "../../../components/ProjectManager/PaymentPlan/OtherSitePaymentPlan";

const PaymentPlan = () => {
  const AgentTopCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-3xl font-semibold">{value}</div>
      </div>
    );
  };
  const [tabstate, settabstate] = useState(1);

  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);
  const [count, setcount] = useState(0);
  const [customerclass, setcustomerclass] = useState([]);
  const [openplanmodal, setopenplanmodal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [openena, setopenena] = useState(false); // energy plan
  const [openenb, setopenenb] = useState(false); // power plan
  const [openenc, setopenenc] = useState(false); // cumulative plan
  const [openend, setopenend] = useState(false); // end

  const [inputname, setinputname] = useState("");

  // get all site plan
  useEffect(() => {
    (async () => {
      try {
        let res;
        if (tabstate == 1) {
          res = await axios.post(
            "/vending/paymentplan/getallpaymentplansforsite",
            {
              siteid: Number(siteid),
            }
          );
          setdata(res.data.body);
          setcount(res.data.meta.count);
          setloading(false);
          return;
        }

        if (tabstate == 2) {
          res = await axios.post(
            "/vending/paymentplan/getallpaymentplansgroupedbysite",
            {}
          );
          // console.log(res.data, "response");
          setdata(res.data.body);
          setloading(false);
          return;
        }
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, [refresh, tabstate]);

  // ENA count
  const enaCount = useMemo(() => {
    return data.filter((i) => i.commandtype == "ENA").length;
  }, [data]);

  // ENB
  const enbCount = useMemo(() => {
    return data.filter((i) => i.commandtype == "ENB").length;
  }, [data]);

  // ENC
  const encCount = useMemo(() => {
    return data.filter((i) => i.commandtype == "ENC").length;
  }, [data]);

  // get customer class
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/settings/customerclass/getallcustomerclassesforsite",
          {
            siteid: Number(siteid),
          }
        );
        setcustomerclass(res.data.customerClasses);
      } catch (err) {
        errorBlock(err);
      }
    })();
    return () => {};
  }, [refresh]);

  return (
    <Layout>
      {openplanmodal && (
        <AddPlanModal
          setopenena={setopenena}
          setopen={setopenplanmodal}
          setopenenb={setopenenb}
          setopenenc={setopenenc}
          setopenend={setopenend}
          open={openplanmodal}
        />
      )}

      {openena && (
        <AddDailyEnergyPlan
          customerclass={customerclass}
          setopen={setopenena}
          open={openena}
          refreshbtn={setrefresh}
        />
      )}

      {openenb && (
        <AddDailyPowerPlan
          customerclass={customerclass}
          setopen={setopenenb}
          open={openenb}
          refreshbtn={setrefresh}
        />
      )}
      {openenc && (
        <AddCumulativeEnergyPlan
          customerclass={customerclass}
          setopen={setopenenc}
          open={openenc}
          refreshbtn={setrefresh}
        />
      )}

      {openend && (
        <AddUnlimitedPlan
          setopen={setopenend}
          open={openend}
          refreshbtn={setrefresh}
          customerclass={customerclass}
        />
      )}
      {/* first vp */}
      <div className="bg-white p-2 shadow rounded">
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="h-[30px]  mr-2 w-[20px] rounded bg-orange-200"></div>
            <PageTitle
              title={
                tabstate == 1
                  ? `Available payment plans on ${sitename}`
                  : "Plans available in other projects sites"
              }
              caption={""}
            />
          </div>
          <CustomButton
            onClick={() => setopenplanmodal(true)}
            className=" w-[120px] bg-gray-900 p-2 h-[40px] rounded-md flex items-center justify-center"
          >
            <p className="m-0 text-white"> Add Plan</p>
          </CustomButton>
        </div>

        <hr className="mt-2" />

        {/* tabs */}
        <div className="mt-2">
          <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
            <div
              onClick={() => settabstate(1)}
              className={
                tabstate == 1
                  ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                  : `text-center cursor-pointer px-2 py-1 text-[14px]`
              }
            >
              {sitename} plans
            </div>
            <div
              onClick={() => settabstate(2)}
              className={
                tabstate == 2
                  ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                  : `text-center cursor-pointer text-[14px] px-2 py-1 `
              }
            >
              Other site plans
            </div>
          </div>
        </div>

        {tabstate == 1 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-2">
            <AgentTopCard
              title={"Payment plans"}
              value={count}
              caption={`Total number of plans avaliable in ${sitename} `}
              icon={"balance"}
            />
            <AgentTopCard
              title={"Daily Energy plan"}
              value={enaCount}
              caption={`Total daily energy plan available to ${sitename} `}
              icon={"credit"}
            />
            <AgentTopCard
              title={"Cummulative Energy plan"}
              value={encCount}
              caption={`Total cummulative energy plan available to ${sitename}`}
              icon={"credit"}
            />
            <AgentTopCard
              title={"Daily Power plan"}
              value={enbCount}
              caption={`Total daily power plan available to ${sitename}`}
              icon={"credit"}
            />
          </div>
        )}
        <hr className="mt-4 mb-2" />

        <div className="flex justify-between items-end">
          <PageTitle
            title={
              tabstate == 1
                ? `All plans created in ${sitename}`
                : "All plans created across sites in your company"
            }
            caption={
              "The plans includes cummulative and daily energy/power plans."
            }
          />

          {/* {tabstate == 2 && (
            <input
              placeholder="Enter for project site."
              value={inputname}
              onChange={(e) => setinputname(e.target.value)}
              className="h-[40px] px-2 bg-gray-100 w-[250px] rounded"
            />
          )} */}
        </div>

        <hr className="mb-2 mt-2" />
        {tabstate == 1 && (
          <div className="">
            <PaymentPlanTable
              data={data}
              tabstate={tabstate}
              loading={loading}
              refreshbtn={setrefresh}
            />
          </div>
        )}

        {tabstate == 2 && (
          <OtherSitePaymentPlan
            data={data}
            tabstate={tabstate}
            loading={loading}
          />
        )}
      </div>
    </Layout>
  );
};

export default PaymentPlan;
