import { TbDeviceHeartMonitor } from "react-icons/tb";
import { Link } from "react-router-dom";

const CustomerCardMeter = ({ title, caption, value = 0, type }) => {
  return (
    <div className="border rounded-md p-3">
      <div className="flex items-center justify-between">
        <div className="">
          <div className="text-gray-800 font-semibold ">{title}</div>
          <div className="text-gray-500 font-normal ">{caption}</div>
        </div>
        <div className="">
          <TbDeviceHeartMonitor size={30} />
        </div>
      </div>
      <div
        className={`flex  items-end ${
          type === "withoutmeters" ? "justify-between" : "justify-start "
        } `}
      >
        <div className=" text-[30px] pt-4 font-bold text-secondary">
          {value}
        </div>
        {type === "withoutmeters" && (
          <Link to={"/manage-meter?tab=2"} className="underline text-secondary">
            View all
          </Link>
        )}
      </div>
    </div>
  );
};

export default CustomerCardMeter;
