import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

const CustomerMeterChart = ({ data }) => {
  const chartRef = useRef(null); // Reference to the chart canvas

  // Extract timestamps, voltage, frequency, and current values from data
  const timestamps = data.map((entry) => entry.timestamp);
  const voltageData = data.map((entry) => parseFloat(entry.voltage));
  const frequencyData = data.map((entry) => parseFloat(entry.frequency));
  const currentData = data.map((entry) => parseFloat(entry.current));

  // Create gradient fills for the datasets
  const createGradient = (ctx, colorStart, colorEnd) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Gradient from top to bottom
    gradient.addColorStop(0, colorStart); // Start color
    gradient.addColorStop(1, colorEnd); // End color
    return gradient;
  };

  // Chart data configuration
  const chartData = {
    labels: timestamps, // X-axis labels
    datasets: [
      {
        label: "Voltage (V)",
        data: voltageData,
        borderColor: "red",
        borderWidth: 2,
        fill: true, // Enable background fill
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          return createGradient(
            ctx,
            "rgba(255, 99, 132, 0.5)",
            "rgba(255, 99, 132, 0.1)"
          );
        },
      },
      {
        label: "Frequency (Hz)",
        data: frequencyData,
        borderColor: "green",
        borderWidth: 2,
        fill: true, // Enable background fill
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          return createGradient(
            ctx,
            "rgba(75, 192, 192, 0.5)",
            "rgba(75, 192, 192, 0.1)"
          );
        },
      },
      {
        label: "Current (A)",
        data: currentData,
        borderColor: "blue",
        borderWidth: 2,
        fill: true, // Enable background fill
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          return createGradient(
            ctx,
            "rgba(54, 162, 235, 0.5)",
            "rgba(54, 162, 235, 0.1)"
          );
        },
      },
    ],
  };

  // Chart options configuration
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Meter Consumption Analytics", // Chart title
      },
      legend: {
        display: true,
        position: "bottom", // Legend position
      },
      tooltip: {
        intersect: true,
      },
    },
    scales: {
      x: {
        title: {
          display: false, // Hide the X-axis title if present
        },
        ticks: {
          display: false, // Hide the X-axis labels
        },
        grid: {
          display: false, // Optional: Hide the X-axis grid lines
        },
      },
      y: {
        title: {
          display: true,
          text: "Values", // Y-axis label
        },
        grid: {
          display: true,
        },
      },
    },
  };

  return <Line data={chartData} options={chartOptions} ref={chartRef} />;
};

export default CustomerMeterChart;
