import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout";
import axios from "../../utils/axios";
import { MdElectricMeter } from "react-icons/md";
import DateRangePicker from "../../components/DateRangePicker";
import AssignMeter from "../../components/Meter/modals/AssignMeter";
import AllMeterTable from "../../components/Meter/Table/AllMeterTable";
import { meterController } from "../../controllers/MeterController";
import { useSelector } from "react-redux";
import AllBatchMeterTable from "../../components/Meter/Table/AllBatchMeterTable";
import { generalController } from "../../controllers/generalController";
import { errorBlock } from "../../controllers/errorBlock";
import { Radio, Input, Select } from "antd";
import { useSearchParams } from "react-router-dom";
import { useMeterSearchMutation } from "../../services/meter.services";
import { formatName } from "../../utils/helpers";
import { convertToNigerianTime } from "../../utils/timezones";

const Meters = () => {
  const user = useSelector((state) => state.userDetails);
  const { companyname } = useSelector((state) => state.domain);
  const { timezone } = useSelector((state) => state.projectSite);
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD HH:mm:ss")
  );

  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [data, setData] = useState([]);
  const [batch, setbatch] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setlimit] = useState(100);
  const controller = new AbortController();
  const [addMeterModal, setAddMeterModal] = useState(false);
  let [searchParams] = useSearchParams();
  const [page] = useState(searchParams.get("page"));
  const [order, setorder] = useState("Ascending (by date)");
  const [tabstate, settabstate] = useState(page == "meter" ? 2 : 1);
  const [meterstats, setmeterstats] = useState({
    decommissioned: 0,
    registered: 0,
    assigned: 0,
    unassigned: 0,
    deployed: 0,
  });

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmeters", {
          limit: 500,
          offset: 0,
          startdate: timezone
            ? convertToNigerianTime(startDate, timezone)
            : startDate,
          enddate: timezone
            ? convertToNigerianTime(endDate, timezone)
            : endDate,
          datesortorder: order == "Ascending (by date)" ? true : false,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setData(result.message.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);
  const [
    meterSearch,
    {
      isLoading: unassignRFLoading,
      error: unassignRFError,
      data: unassignRFData,
    },
  ] = useMeterSearchMutation();
  const [meterinput, setmeterinput] = useState("");

  const handleSearch = (value) => {
    try {
      meterSearch({
        searchkeyword: value,
        limit: limit,
        offset: offset,
        datesortorder: order == "Ascending (by date)" ? true : false,
      })
        .then((res) => {
          if (res.error) {
            errorBlock(res.error);
            return;
          }
          setData(res.data.body);
        })
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  };

  // get all meter by batch
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmeterbatches", {});
        const result = generalController(res);

        // console.log(result.message.body);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }

        setbatch(result.message.body);
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, []);

  // meter stats
  useEffect(() => {
    Promise.all([
      axios.post("/meter/countalldecommisionedmeters", {
        startdate: timezone
          ? convertToNigerianTime(startDate, timezone)
          : startDate,
        enddate: timezone ? convertToNigerianTime(endDate, timezone) : endDate,
      }),
      axios.post("/meter/countallmetersinservice", {
        startdate: timezone
          ? convertToNigerianTime(startDate, timezone)
          : startDate,
        enddate: timezone ? convertToNigerianTime(endDate, timezone) : endDate,
      }),
      axios.post("/meter/countallregisteredmeters", {
        startdate: timezone
          ? convertToNigerianTime(startDate, timezone)
          : startDate,
        enddate: timezone ? convertToNigerianTime(endDate, timezone) : endDate,
      }),
      axios.post("/meter/countallmetersnotassignedtocustomers", {}),
      axios.post("/meter/countallmetersassignedtocustomers", {}),
    ])
      .then((res) => {
        let response = res.map((item) => meterController(item));
        // decommissionedmeter
        setmeterstats((prev) => {
          return {
            decommissioned: response[0].message?.count,
            registered: response[4].message?.count,
            assigned: response[2].message?.count,
            unassigned: response[3].message?.count,
            deployed: response[1].message?.count,
          };
        });
        // meters in service
        // registered meters in service
        //  meters not assigned in service
        //  meters assigned in service
      })
      .catch((err) => {
        errorBlock(err);
      });

    return () => {
      controller.abort();
    };
  }, [refresh]);
  const [batchinput, setbatchinput] = useState("");

  const filteredData = batch.filter(
    (i) =>
      i?.batchid?.toLowerCase().trim().includes(batchinput.toLowerCase()) ||
      i?.phases.toLowerCase().trim().includes(batchinput.toLowerCase()) ||
      i?.model.toLowerCase().trim().includes(batchinput.toLowerCase()) ||
      String(i?.numberofchannels).toLowerCase().trim().includes(batchinput)
  );

  const filteredMeter = data.filter(
    (i) =>
      i?.meternumber.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.batchid.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.phases.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.type.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.device_serial
        .toLowerCase()
        .trim()
        .includes(meterinput.toLowerCase()) ||
      i?.registrar.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.assignedto.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      String(i?.loadlimit).toLowerCase().trim().includes(meterinput)
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Layout>
      {addMeterModal && <AssignMeter closeModal={setAddMeterModal} />}
      <div
        className=" mt-2 bg-white h-auto rounded shadow-md"
        style={{
          marginBottom: 200,
        }}
      >
        <div className="w-full ">
          <div className="p-1 flex items-center justify-between mb-3">
            <div className="flex items-center ">
              <div className=" w-[15px] h-[25px] bg-[#45e4df7b] rounded-sm"></div>
              <div className="font-semibold ml-2">
                All meters available in organisation{" "}
                <span className="">({formatName(companyname)})</span>
              </div>
            </div>
            <div className="">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                refreshBtn={setRefresh}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 p-2 md:grid-cols-4 lg:grid-cols-4 my-1 gap-4">
            {/* Assigned Meters */}
            <div
              className={`h-[150px] p-2 border-[1px] rounded border-gray-200 shadow-sm`}
            >
              <div className="flex items-start justify-between">
                <div className="">
                  <div className="pt-2 font-bold">Assigned Meters</div>
                  <p> Meters allocated to customers</p>
                  <div className="pt-2 font-bold text-3xl">
                    {meterstats.registered ?? 0}
                  </div>
                </div>

                <div className="h-[50px] mt-2 w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                  <MdElectricMeter
                    size={35}
                    color=" text-sky-500"
                    className=" text-green-500"
                  />
                </div>
              </div>
            </div>
            {/* Owned meter */}
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="flex items-start justify-between">
                <div className="">
                  <div className="pt-2 font-bold">Company Meters</div>
                  <p>All meters you procured</p>
                  <div className="pt-2 font-bold text-3xl">
                    {meterstats.deployed ?? 0}
                  </div>
                </div>

                <div className="h-[50px] mt-2 w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                  <MdElectricMeter
                    size={35}
                    color=" text-sky-500"
                    className=" text-sky-400"
                  />
                </div>
              </div>
            </div>
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="flex items-start justify-between">
                <div className="">
                  <div className="pt-2 font-bold">Decommissioned Meters</div>
                  <p>Meters you took out of service</p>
                  <div className="pt-2 font-bold text-3xl">
                    {meterstats.decommissioned ?? 0}
                  </div>
                </div>

                <div className="h-[50px] mt-2 w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                  <MdElectricMeter
                    size={35}
                    color=" text-sky-500"
                    className=" text-gray-600"
                  />
                </div>
              </div>
            </div>
            {/* unassigned meters */}
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="flex items-start justify-between">
                <div className="">
                  <div className="pt-2 font-bold">Unassigned Meters</div>
                  <p>Meters not allocated to customers</p>
                  <div className="pt-2 font-bold text-3xl">
                    {meterstats.unassigned ?? 0}
                  </div>
                </div>

                <div className="h-[50px] mt-2 w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                  <MdElectricMeter
                    size={35}
                    color=" text-sky-500"
                    className=" text-orange-500"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-1 rounded-md">
            <div className="flex items-center justify-between px-3 mb-4">
              <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
                <div
                  onClick={() => settabstate(1)}
                  className={
                    tabstate == 1
                      ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                      : `text-center cursor-pointer px-2 py-1 text-[14px]`
                  }
                >
                  View meters by Batch Id
                </div>
                <div
                  onClick={() => settabstate(2)}
                  className={
                    tabstate == 2
                      ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                      : `text-center cursor-pointer text-[14px] px-2 py-1 `
                  }
                >
                  View all meters
                </div>
              </div>
              <div className="flex items-center">
                <div className="mr-4">
                  <Select
                    className="w-[200px]"
                    defaultValue={order}
                    placeholder={"Enter order of date"}
                    options={[
                      { label: "Ascending (by date)", value: "true" },
                      { label: "Descending (by date)", value: "false" },
                    ]}
                  />
                </div>

                {/* <Button onClick={showModal} className="mr-3">
                  Filter
                </Button> */}

                {tabstate == 1 ? (
                  <Input.Search
                    size=""
                    onChange={(e) => {
                      setbatchinput(e.target.value);
                    }}
                    onSearch={(e) => {
                      // setbatchinput(e.target.value);
                      // console.log(e.target.value, "value");
                    }}
                    placeholder="Search for a batch"
                    className=" placeholder:text-[12px] placeholder:font-light "
                  />
                ) : (
                  <Input.Search
                    size=""
                    onSearch={(value) => {
                      handleSearch(value); // Pass the input value to the handleSearch function
                    }}
                    placeholder="Search for a meter"
                    className="placeholder:text-[12px] placeholder:font-light"
                  />
                )}
              </div>
            </div>

            <div className="h-auto overflow-y-hidden overflow-x-auto">
              {tabstate == 1 ? (
                <AllBatchMeterTable data={filteredData} />
              ) : (
                <AllMeterTable data={filteredMeter} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[300px] w-full"></div>
    </Layout>
  );
};

export default Meters;
