import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../../components/Layout";
// import MeterTable from "../../../components/Meters/Table/MeterTable";
import TableTop from "../../../components/TableTop";
import axios from "../../../utils/axios";
import TopCard from "../../../components/TopCard";
import TopNav from "../../../components/TopNav";
import TopNavTitle from "../../../components/TopNav/TopNavTitle";
// import { color } from "../../../constant/color";
import { meterController } from "../../../controllers/MeterController";
import TableSkimmer from "../../../components/TableSkimmer";
import { useParams } from "react-router-dom";
import Rule from "../../../components/Rule/Index";
import MeterTable from "../../../components/Meter/Table/MeterTable";
import { errorBlock } from "../../../controllers/errorBlock";
import { useGetSiteDetailsMutation } from "../../../services/projectsite.services";
import moment from "moment";
import BackBtn from "../../../components/BackBtn";
const ProjectSiteMeters = () => {
  const controller = new AbortController();
  const [meter, setMeter] = useState([]);
  const [loading, setloading] = useState(false);
  const { id } = useParams();

  const [getSiteDetails, { data, isLoading, error }] =
    useGetSiteDetailsMutation();

  // Effect to handle side effects like showing error/toast
  useEffect(() => {
    getSiteDetails({
      siteid: id,
    })
      .then((res) => {
        if (res.error) {
          errorBlock(res.error);
          return;
        }
      })
      .catch((err) => {
        errorBlock(err);
      });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmetersinzone", {
          zone: +id,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setMeter(result.message.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  const AgentTopCard = ({ title, value, caption, icon, type }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-[20px] font-semibold">
          {type == "date" ? moment(value).format("lll") : value}
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className=" bg-white p-2 rouned-md mt-2">
        <BackBtn />
        <div className="flex mt-3">
          <div className="flex items-start ">
            <div className="w-[15px] h-[25px] bg-[#45e4df7b] rounded-sm"></div>
            <div className="ml-2 mt-[-5px]">
              <h1 className="m-0 font-semibold">
                Meters available in {data?.sitename ?? ""}
              </h1>
              <p>List of meters assigned to customers</p>
              {/* <span className="">({formatName(companyname)})</span> */}
            </div>
          </div>
          <div className=""></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-2">
          <AgentTopCard
            title={"Site Name"}
            value={data?.sitename ?? ""}
            caption={`Total number sales made`}
            icon={"balance"}
            type={"sales"}
          />
          <AgentTopCard
            title={"Site Type"}
            value={data?.sitetype ?? ""}
            caption={`Total units recharged acroos all meters`}
            icon={"balance"}
          />
          <AgentTopCard
            title={"Country"}
            value={`${data?.country} (${data?.timezone})` ?? ""}
            caption={`Total units recharged acroos all meters`}
            icon={"balance"}
          />
          <AgentTopCard
            title={"Date created"}
            value={data?.datecreated ?? "0"}
            caption={`Total units recharged acroos all meters`}
            icon={"balance"}
            type={"date"}
          />
        </div>
        <Rule />
        <div className="">
          <MeterTable data={meter} />
        </div>
      </div>
    </Layout>
  );
};

export default ProjectSiteMeters;
