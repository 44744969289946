import Layout from "../../../components/Layout";
// import UserRolesTable from "../../../components/Roles/Tables";

import { useState, useEffect } from "react";
import AddRoles from "../../../components/Roles/AddRole";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
// import { rolesController } from "../../../controllers/RolesController";
import "./styles.css";
// import AllRolesTable from "../../../components/Roles/AllRolesTable";
import { commandController } from "../../../controllers/CommandController";
import AllRolesTable from "../../../components/Roles/Tables/Tables/AllRolesTable";
import { errorBlock } from "../../../controllers/errorBlock";
import { Button, Input } from "antd";
const AllRoles = () => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        // let user =
        let res = await axios.post("/roles/getallroles", {});
        let result = commandController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }

        // console.log(result.message.roles);

        setData(result.message.roles);
        setloading(false);
      } catch (err) {
        errorBlock(err);
        setloading(false);
      }
    })();
  }, [refresh]);
  const [roleinput, setroleinput] = useState("");

  const filteredData = data.filter(
    (i) =>
      i?.role.toLowerCase().trim().includes(roleinput.toLowerCase()) ||
      i?.sitename.toLowerCase().trim().includes(roleinput.toLowerCase()) ||
      i?.country.toLowerCase().trim().includes(roleinput.toLowerCase()) ||
      i?.creator.toLowerCase().trim().includes(roleinput.toLowerCase()) ||
      i?.username.toLowerCase().trim().includes(roleinput.toLowerCase())
  );

  return (
    <Layout>
      {modal && <AddRoles closeModal={setModal} refreshBtn={setRefresh} />}
      <div className="">
        <div
          className="bg-white pt-2 h-[90vh] mt-2 rounded-md shadow-md"
          style={{
            marginBottom: "200px",
          }}
        >
          <div className="flex items-center justify-between px-2 mb-4">
            <h1>Staff in your organization and their roles.</h1>
            <div className="flex items-center justify-end">
              <Input.Search
                size=""
                style={{
                  width: 200,
                }}
                onChange={(e) => {
                  setroleinput(e.target.value);
                }}
                placeholder="Search for a user"
                className=" mx-4 placeholder:text-[12px] placeholder:font-light "
              />
              <Button
                onClick={() => setModal(true)}
                className="!text-white !bg-black !border-none"
              >
                Assign role to staff
              </Button>
            </div>
          </div>

          <AllRolesTable data={filteredData} loading={loading} />
        </div>
      </div>
    </Layout>
  );
};

export default AllRoles;
