import { useSelector } from "react-redux";
import Rule from "../../../Rule/Index";
import CloseButton from "../../../CloseButton";
import Backdrop from "../../../Backdrop";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { CustomButton } from "../../../CustomButton";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ErrorText from "../../../Errors/ErrorText";
import { Select, Space, TimePicker } from "antd";
import { AiFillPlusCircle } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import moment from "moment";
import toast from "react-hot-toast";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import { errorBlock } from "../../../../controllers/errorBlock";

const AddMeterClassNew = ({ open, setopen, refresh }) => {
  const [entries, setEntries] = useState([]);
  const [loading, setloading] = useState(false);
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const { username } = useSelector((state) => state.userDetails);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const addEntry = (e) => {
    e.preventDefault();
    setEntries([
      ...entries,
      {
        bp: "",
        startTime: null,
        endTime: null,
      },
    ]);
  };

  const removeEntry = (index) => {
    setEntries(entries.filter((_, i) => i !== index));
  };

  const updateEntry = (index, field, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index][field] = value;
    setEntries(updatedEntries);
  };

  const onSubmit = async (data) => {
    try {
      if (entries.length == 0) {
        return toast.error("Select atleast one TOU");
      }

      setloading(true);
      const formattedTou = entries.map(
        (i) =>
          `#${moment(i.startTime)
            .format("HH:mm:ss")
            .replaceAll(":", "")}${moment(i.endTime)
            .format("HH:mm:ss")
            .replaceAll(":", "")}${
            Number(i.bp) < 10
              ? `00${Number(i.bp)}`
              : Number(i.bp) == 10
              ? `0${Number(i.bp)}`
              : Number(i.bp) > 10 && Number(i.bp) < 99
              ? `0${Number(i.bp)}`
              : Number(i.bp) == 99
              ? `0${Number(i.bp)}`
              : Number(i.bp)
          }`
      );

      let res = await axios.post(
        "/settings/customerclass/createcustomerclass",
        {
          siteid: Number(siteid),
          customerclass: data.customerclass,
          customertype: data.customertype,
          timeofuse: formattedTou.join(""),
          createdby: username,
        }
      );
      let response = meterController(res);
      if (response.type !== "success") {
        toast[response.type](res.data.message);
        setloading(false);
        return;
      }

      // console.log(response, "response");

      toast.success("Meter class created successfully");
      setloading(false);
      setopen((prev) => !prev);
      refresh((prev) => !prev);
    } catch (error) {
      setloading(false);
      errorBlock(error);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[500px] rounded-md bg-white drop-shadow-md  p-3">
          <div className="flex items-start justify-between">
            <div className="">
              <div className=" font-semibold text-[17px]">
                Create customer class for customer in{" "}
                <span className="text-secondary">{sitename}</span>
              </div>
              <div className="w-[100%] text-gray-700">
                Fill in the fields to create customer class for customers
              </div>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <input
                type={"text"}
                {...register("customertype", {
                  required: true,
                })}
                placeholder={"Enter customer type (e.g Residential)"}
                className="bg-gray-100 mt-2 h-[40px] border-[1px] rounded-sm p-2 placeholder:font-light w-full outline-none "
              />
            </div>
            {errors.customertype && (
              <ErrorText text={"Customer type is required."} />
            )}

            <input
              type={"text"}
              {...register("customerclass", {
                required: true,
                maxLength: 3,
              })}
              placeholder={"Enter 3-char customer class (e.g R12 ) "}
              className="bg-gray-100 mt-4 h-[40px] border-[1px] rounded-sm p-2 placeholder:font-light w-full outline-none "
            />
            {errors.customerclass && (
              <ErrorText
                text={
                  "Customer class is required or must be 3 characters long."
                }
              />
            )}

            {entries.map((entry, index) => (
              <div className="flex items-center mt-4" key={index}>
                <div className="bg-gray-100 flex flex-row-reverse h-[40px] w-[fit-content] border-[1px] rounded-sm placeholder:font-light  outline-none ">
                  <input
                    value={entry.bp}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      }
                    }}
                    maxLength={3}
                    onChange={(e) => {
                      updateEntry(index, "bp", e.target.value);
                    }}
                    placeholder="Enter billing %"
                    className="bg-transparent p-1 w-[100px] placeholder:text-sm placeholder:font-light outline-none border-[0px]"
                  />
                  <div className="border-l-[1px] border-gray-300"></div>
                  <TimePicker.RangePicker
                    style={{
                      fontSize: 10,
                    }}
                    bordered={false}
                    value={[entry.startTime, entry.endTime]}
                    onChange={(e) => {
                      updateEntry(index, "startTime", e ? e[0] : null);
                      updateEntry(index, "endTime", e ? e[1] : null);
                    }}
                    size="default"
                  />
                </div>
                <div className="flex items-center ml-2 cursor-pointer">
                  <CustomButton
                    className="h-[34px] w-[34px] p-1 rounded flex items-center justify-center"
                    onClick={() => removeEntry(index)}
                  >
                    <MdDeleteOutline color="tomato" size={20} />
                    {/* <TiDelete color="red" size={20} /> */}
                  </CustomButton>
                </div>
              </div>
            ))}

            <div className="flex items-center mt-4">
              <CustomButton
                className="p-1 rounded border border-green-700 flex items-center justify-center"
                onClick={addEntry}
              >
                <CiCirclePlus color="green" size={20} />
                <p className="m-0 text-[13px] text-green-700">Add TOU</p>
              </CustomButton>
            </div>

            <div className="border-[1px] rounded-md p-2 mt-2">
              <p className="m-0 underline">Time of use (TOU):</p>
              <ul className="list-none list-inside">
                <li className="font-light text-[12px]">
                  The TOU refers to the time the settings of the customer class
                  woudl be active on the customer's meter
                </li>
              </ul>
            </div>

            <hr className="mt-3" />
            <CustomButton
              loading={loading}
              type="submit"
              className={
                "mt-4 border-[1px] bg-black text-center w-full p-2 border-gray-300 rounded-md flex items-center justify-center"
              }
            >
              {!loading && <p className="m-0 text-white pr-2">Submit</p>}
            </CustomButton>
          </form>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddMeterClassNew;
