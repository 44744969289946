import React, { useState, useEffect, useCallback, useMemo } from "react";
import Layout from "../../../../components/Layout";
import { useAsync } from "../../../../utils/Hooks/useAsync";
import { client } from "../../../../utils/api";
import { Link, useParams } from "react-router-dom";
import ProfileImg from "../../../../assets/svg/projectimg.svg";
import moment from "moment";
import SiteDetailsCard from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCard";
import SiteDetailsCardRight from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCardRight";
import BackBtn from "../../../../components/BackBtn";
import { FaUserShield } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
/** Styles */
import Rule from "../../../../components/Rule/Index";
import { useGetSiteDetailsMutation } from "../../../../services/projectsite.services";
import { countryList } from "../../../../helperFuncs/countryList";
import { errorBlock } from "../../../../controllers/errorBlock";
import { useGetAllCustomerMetersInSiteMutation } from "../../../../services/meter.services";
import AssignedMetersTable from "../../../../components/CustomerManager/Tables/AssignedMetersTable";
import { useGetCustomerInZoneMutation } from "../../../../services/customer.services";
import { useGetMetersInSiteMutation } from "../../../../services/meter.services";
import { useGetDetailedTransactionAnalyticsMutation } from "../../../../services/salesanalytics.services";

const CustomerManagerDetails = () => {
  const [cardtype, setcardtype] = useState({});
  const [currency, setcurrency] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [tab, settab] = useState("customer");
  const { data: userDetails, run: getUser } = useAsync({
    data: [],
    status: "pending",
  });
  const [
    getDetailedTransactionAnalytics,
    { isLoading: revenueloading, data: revenueData, error: revenueError },
  ] = useGetDetailedTransactionAnalyticsMutation();
  const [
    getCustomerInZone,
    { isLoading: customerloading, data: customerData, error: customerError },
  ] = useGetCustomerInZoneMutation();
  const [
    getMetersInSite,
    { isLoading: meterloading, data: meterData, error: meterError },
  ] = useGetMetersInSiteMutation();

  const { data: meters, run: getallmetersinzone } = useAsync({
    data: {},
    status: "pending",
  });

  const { siteid, username } = useParams();

  // get user
  useEffect(() => {
    getUser(
      client(`/auth/useraccount/getuser`, {
        data: { username },
        method: "POST",
      })
    );
  }, [getUser, username]);

  // get sales history per zone based on vending
  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  useEffect(() => {
    getSiteDetails({ siteid });
  }, []);

  const [
    getAllCustomerMetersInSite,
    { isLoading: metersloading, error: metersError, data: metersData },
  ] = useGetAllCustomerMetersInSiteMutation();

  // get Site details
  useEffect(() => {
    if (metersError) {
      errorBlock(metersError);
    }
  }, [metersError]);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  // customers in zone
  useEffect(() => {
    try {
      getCustomerInZone({
        siteid,
      })
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, []);

  useEffect(() => {
    if (customerError) {
      errorBlock(customerError);
    }
  }, []);

  // meters in zone
  useEffect(() => {
    try {
      getMetersInSite(siteid)
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, []);

  useEffect(() => {
    if (customerError) {
      errorBlock(customerError);
    }
  }, []);

  return (
    <Layout>
      <div className="mb-40">
        {<BackBtn text={"Go back"} />}
        <div className="">
          <div className=" p-2 rounded shadow mt-3 bg-white items-center">
            <div className="flex items-center justify-between">
              <div className="">
                <div className="flex">
                  <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                  <div className="flex flex-col items-start">
                    <div className="ml-2">Details of customer manager: </div>
                    <div className="ml-2">
                      Username:{" "}
                      <span className=" font-semibold">
                        {userDetails?.username
                          ? `${userDetails?.username[0].toUpperCase()}${userDetails?.username.substring(
                              1
                            )} `
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-[170px] justify-end">
                Site managed:
                {siteData?.sitename && (
                  <Link
                    to={`/dashboard/projectsite/${siteid}`}
                    className=" font-semibold underline pl-2"
                  >
                    {siteData?.sitename}
                  </Link>
                )}
              </div>
            </div>
            {/* user details */}
            <div className=" mt-4 grid grid-cols-4 gap-4 w-full py-4 border rounded">
              {/* full name */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaUserShield size={24} />
                </div>
                <div className="text-gray-400">Full Name</div>
                <div className="font-semibold">
                  {!userDetails?.firstname
                    ? "Registration not complete"
                    : userDetails?.firstname + " " + userDetails?.lastname}
                </div>
              </div>
              {/* Email */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdMarkEmailRead size={24} />
                </div>
                <div className="text-gray-400">Email</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.email}
                </div>
              </div>
              {/* Phone */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaPhone size={24} />
                </div>
                <div className="text-gray-400">Phone</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.phone}
                </div>
              </div>
              {/* Date registered */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdDateRange size={24} />
                </div>
                <div className="text-gray-400">Date registered</div>
                <div className="font-semibold">
                  {moment(userDetails?.dateregistered).format("LLL")}
                </div>
              </div>
            </div>

            <div className="w-full hidden project-datails__title">
              Project manager details for{" "}
              <span className="zone-name">{siteid}</span>
            </div>
          </div>
          <div className="hidden flex-row p-3 bg-white">
            <div className="hidden md:block lg:block xl:block w-2/12">
              <div className="project-img flex items-center justify-center">
                <img
                  src={ProfileImg}
                  alt="img"
                  style={{ width: "70px", height: "70px" }}
                />
              </div>
            </div>
            <div className="hidden flex-grow-0 flex-shrink-0 w-1/3">
              <div className="flex-row items-center justify-between">
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry flex items-center justify-between  ">
                      <div className="project-title text-sm">Full Name</div>
                      <div className="project-value">
                        {!userDetails?.firstname
                          ? "Registration not complete"
                          : userDetails?.firstname +
                            " " +
                            userDetails?.lastname}
                      </div>
                    </div>
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title">Phone Number</div>
                      <div className="project-value">{userDetails?.phone}</div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title ">Email</div>
                      <div className="project-value">
                        {!userDetails?.email
                          ? "Registration not complete."
                          : userDetails?.email}
                      </div>
                    </div>
                    <div className="project-entry flex mt-2 items-center justify-between">
                      <div className="project-title">Date Registered</div>
                      <div className="project-value">
                        {moment(userDetails?.dateregistered).format("LLL")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Revenue generate in the site  */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div
              onClick={() => {
                settab("revenue");
              }}
              className="flex bg-blue-50 p-2 cursor-pointer items-center justify-between"
            >
              <div className="font-semibold">Revenue and Customer Records</div>
              <div className=" cursor-pointer">
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            <div className="grid grid-cols-2 gap-2 mt-2">
              {/* Revenue */}
              <div className="border flex flex-col justify-between rounded-md p-2">
                <div className="">
                  <div className="flex items-start justify-between">
                    <h1 className="font-normal">
                      Number of registered (active) meters in site since{" "}
                      {userDetails ? userDetails?.username : " "} <br />
                      was made a project manager.
                    </h1>
                    <Link
                      to={`/dashboard/records/revenue/${siteid}/${username}`}
                      className="hidden underline"
                    >
                      Explore more
                    </Link>
                  </div>

                  <p className="font-bold m-0 pt-4 text-end !text-[30px]">
                    {meterData?.meta?.count ?? 0}
                    {/* <sup>{currency}</sup> */}
                  </p>
                </div>
              </div>

              {/* Customer */}
              <div className="border flex flex-col justify-between rounded-md p-2">
                <div className="">
                  <div className="flex items-start justify-between">
                    <h1 className="font-normal">
                      Number of customers onboarded since{" "}
                      {userDetails ? userDetails?.username : " "} <br />
                      was made a project manager.
                    </h1>
                    <Link
                      to={`/dashboard/records/revenue/${siteid}/${username}`}
                      className="hidden underline"
                    >
                      Explore more
                    </Link>
                  </div>

                  <p className="font-bold m-0 pt-4 text-end !text-[30px]">
                    {customerData?.meta?.count ?? 0}
                    {/* <sup>{currency}</sup> */}
                  </p>
                </div>
              </div>

              <div className="bg-white rounded-1 shadow-sm"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default CustomerManagerDetails;
