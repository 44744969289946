import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const segmentationApi = createApi({
  reducerPath: "segmentationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers) => {
      // Prepare headers if needed
    },
  }),
  endpoints: (builder) => ({
    // Segment customers with advanced filtering
    segmentCustomers: builder.mutation({
      query: (payload) => ({
        url: "/segment/customers",
        method: "POST",
        body: { ...credentials, ...payload },
      }),
    }),

    // Delete a saved segment by alias
    deleteSegment: builder.mutation({
      query: (alias) => ({
        url: "/segment/delete",
        method: "POST",
        body: { ...credentials, alias },
      }),
    }),

    // Retrieve all saved segments
    getAllSegments: builder.mutation({
      query: () => ({
        url: "/segment/getall",
        method: "POST",
        body: { ...credentials },
      }),
    }),

    // Retrieve customers using a saved segment alias
    getCustomersByAlias: builder.mutation({
      query: (alias) => ({
        url: "/segment/getbyalias",
        method: "POST",
        body: { ...credentials, alias },
      }),
    }),

    // Save current segmentation filters with an alias
    saveSegment: builder.mutation({
      query: (payload) => ({
        url: "/segment/save",
        method: "POST",
        body: { ...credentials, ...payload },
      }),
    }),
  }),
});

export const {
  useSegmentCustomersMutation,
  useDeleteSegmentMutation,
  useGetCustomersByAliasMutation,
  useGetAllSegmentsMutation,
  // useGetAllSegmentsQuery,
  // useGetCustomersByAliasQuery,
  useSaveSegmentMutation,
} = segmentationApi;
