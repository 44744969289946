import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const DCUAPI = createApi({
  reducerPath: "dcuPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    // Existing mutation
    getDCUs: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/getdcusfromsite",
        method: "POST",
        body: {
          ...credentials,
          siteid: data?.projectsite,
        },
      }),
    }),
    // New mutations
    addDCUToSite: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/adddcutosite",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    getAllDCUs: build.mutation({
      query: () => ({
        url: "sitemanagement/dcu/getalldcus",
        method: "POST",
        body: credentials,
      }),
    }),
    getDCUsAssignedToSites: build.mutation({
      query: () => ({
        url: "sitemanagement/dcu/getdcusassignedtosites",
        method: "POST",
        body: credentials,
      }),
    }),
    getDCUsNotAssignedToASite: build.mutation({
      query: () => ({
        url: "sitemanagement/dcu/getdcusnotassignedtoasite",
        method: "POST",
        body: credentials,
      }),
    }),
    getMetersOnDCU: build.mutation({
      query: (dcuid) => ({
        url: "sitemanagement/dcu/getmetersondcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid,
        },
      }),
    }),
    isMeterAttachedToDCU: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/ismeterattachedtoadcu",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    migrateDCUToAnotherSite: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/migratedcutoanothersite",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    migrateMetersToDCU: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/migratemeterstodcu",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    migrateMeterToDCU: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/migratemetertodcu",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    removeDCUFromSite: build.mutation({
      query: (dcuid) => ({
        url: "sitemanagement/dcu/removedcufromsite",
        method: "POST",
        body: {
          ...credentials,
          dcuid,
        },
      }),
    }),
    removeMeterFromDCU: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/removemeterfromdcu",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    updateDCUAlias: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/updatedcualias",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
  }),
});

export const {
  useGetDCUsMutation,
  useAddDCUToSiteMutation,
  useGetAllDCUsMutation,
  useGetDCUsAssignedToSitesMutation,
  useGetDCUsNotAssignedToASiteMutation,
  useGetMetersOnDCUMutation,
  useIsMeterAttachedToDCUMutation,
  useMigrateDCUToAnotherSiteMutation,
  useMigrateMetersToDCUMutation,
  useMigrateMeterToDCUMutation,
  useRemoveDCUFromSiteMutation,
  useRemoveMeterFromDCUMutation,
  useUpdateDCUAliasMutation,
} = DCUAPI;
