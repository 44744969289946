import React, { useState } from "react";
import { Modal, Input, Button } from "antd";
import { controlsData } from "../../../../screens/Users/Meter/CustomerMeterControls/data";
import {
  useSAIDIEnquiryMutation,
  useSAIFIEnquiryMutation,
} from "../../../../services/commands.services";
import toast from "react-hot-toast";
import { errorBlock } from "../../../../controllers/errorBlock";
import { useParams } from "react-router-dom";
const SetSAIDFIModal = ({
  isModalOpen,
  setIsModalOpen,
  selected,
  setselected,
}) => {
  const { customer, meterid } = useParams();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [
    SAIFIEnquiry,
    { isLoading: saifiLoading, data: saifiData, error: saifiError },
  ] = useSAIFIEnquiryMutation();

  const [
    SAIDIEnquiry,
    { isLoading: saidiLoading, data: saidiData, error: saidiError },
  ] = useSAIDIEnquiryMutation();

  const handleCommand = async (type) => {
    try {
      switch (type) {
        case "SAIDI":
          const saidiResult = await SAIDIEnquiry({ meterid }).unwrap();
          if (saidiResult.response === "success") {
            toast.success(saidiResult.response);
          } else {
            toast.error(saidiResult.error || "Operation failed");
          }

          break;

        case "SAIFI":
          const saifiResult = await SAIFIEnquiry({ meterid }).unwrap();
          if (saifiResult.response === "success") {
            toast.success(saifiResult.response);
          } else {
            toast.error(saifiResult.error || "Operation failed");
          }
          break;

        default:
          break;
      }
    } catch (error) {
      errorBlock(error);
    }
  };

  const processing = saidiLoading || saidiLoading;

  return (
    <Modal
      title={
        "Enter time for " + selected?.label + " " + "(" + selected?.short + ")"
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <Button
          loading={processing}
          disabled={processing}
          size="large"
          className="!outline-none !border-none w-full !bg-black !text-white"
        >
          Set {selected?.short}
        </Button>
      }
    >
      <p> {selected?.description}</p>
      <Input
        size="large"
        placeholder="enter a number"
        type="number"
        className="w-full"
      />
    </Modal>
  );
};

export default SetSAIDFIModal;
