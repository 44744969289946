import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackBtn from "../../../../../components/BackBtn";
import Layout from "../../../../../components/Layout";
import EnergyRecords from "../../../../../components/RecordsCharts/EnergyRecords";

import FilterDateRange from "../../../../../components/FilterDateRange";
import { useGetSiteDetailsMutation } from "../../../../../services/projectsite.services";
import { countryList } from "../../../../../helperFuncs/countryList";
import { errorBlock } from "../../../../../controllers/errorBlock";
import { Select } from "antd";
import Rule from "../../../../../components/Rule/Index";
import { useGetProjectDailyEnergyAnalyticsSummaryMutation } from "../../../../../services/analytics.services";
import DateRangePicker from "../../../../../components/DateRangePicker";
const SiteEnergyRecords = () => {
  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [currency, setcurrency] = useState("");

  const { projectsite, id } = useParams();

  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [refresh, setRefresh] = useState(false);
  const [modal, setmodal] = useState(false);
  const handlefilter = (e) => {
    switch (e) {
      case "today":
        setStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "week":
        setStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "month":
        setStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "year":
        setStartDate(
          moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }

    setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
    setRefresh((prev) => !prev);
  };

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  const [
    getProjectDailyEnergyAnalyticsSummary,
    { isLoading: dailyLoading, error: dailyError, data: dailyData },
  ] = useGetProjectDailyEnergyAnalyticsSummaryMutation();

  // get Site details
  useEffect(() => {
    getSiteDetails({
      siteid: projectsite,
    }).unwrap();
  }, []);

  // get Site details
  useEffect(() => {
    getProjectDailyEnergyAnalyticsSummary({
      siteid: projectsite,
      startdate,
      enddate,
    }).unwrap();
  }, []);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError || dailyError) {
      errorBlock(siteError || dailyError);
    }
  }, [siteError]);

  console.log(dailyData, "daily data");

  return (
    <Layout>
      <div className=" mt-2 bg-white rounded drop-shadow p-2 mb-10">
        <BackBtn />
        <div className="flex bg-gray-100 p-1 rounded items-center justify-between">
          <div className="font-bold">
            <div className="">
              Energy generated in{" "}
              <span className="">{siteData?.sitename ?? ""}</span>{" "}
            </div>
          </div>

          <div className="flex items-center ">
            <Select
              size="default"
              style={{
                width: "200px",
                marginRight: 10,
              }}
              defaultValue={"energy"}
              options={[
                { value: "energy", label: "Energy" },
                { value: "power", label: "Power" },
                { value: "voltage", label: "Voltage" },
              ]}
            />
            <DateRangePicker
              startDate={startdate}
              endDate={enddate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              refreshBtn={setRefresh}
              size={"default"}
            />

            {/* <FilterDateRange
              open={modal}
              setRefresh={setRefresh}
              setopen={setmodal}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              handlefilter={handlefilter}
            /> */}
          </div>
        </div>

        <Rule />

        <div className="h-[600px]">
          <EnergyRecords />
        </div>
      </div>
    </Layout>
  );
};

export default SiteEnergyRecords;
