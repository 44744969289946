import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const rolesAPI = createApi({
  reducerPath: "rolesPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {},
  }),
  endpoints: (build) => ({
    // Get user by role
    getAllUserByRole: build.mutation({
      query: (data) => ({
        url: "roles/getallusersbyrole",
        method: "POST",
        body: {
          ...credentials,
          role: data?.role,
        },
      }),
    }),
    // unassign roles
    getUserRoles: build.mutation({
      query: (data) => ({
        url: "roles/getuserroles",
        method: "POST",
        body: {
          ...credentials,
          targetusername: data?.username,
        },
      }),
    }),
    // get all user by roles
    getAUserRole: build.mutation({
      query: (data) => ({
        url: "roles/getallrolescreatedbyuserbyrole",
        method: "POST",
        body: {
          ...credentials,
          targetusername: data?.username,
        },
      }),
    }),
    // get all user by zone and role
    getUserByZoneAndRole: build.mutation({
      query: (data) => ({
        url: "roles/getallusersbyzoneandrole",
        method: "POST",
        body: {
          ...credentials,
          projectzonearray: data?.zone,
          role: data?.role,
        },
      }),
    }),
  }),
});

export const {
  useGetUserRolesMutation,
  useGetUserByZoneAndRoleMutation,
  useGetAllUserByRoleMutation,
} = rolesAPI;

//
