import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import moment from "moment";
import { useEffect, useState } from "react";
import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import DateRangePicker from "../../../components/DateRangePicker";
import CustomerTable from "../../../components/Dashboards/SalesAgent/Table/CustomerTable";
import { HiBuildingLibrary } from "react-icons/hi2";
import { GiPayMoney, GiWallet } from "react-icons/gi";
import { themeColor } from "../../../constant/color";
import { useParams } from "react-router-dom";
import SalesAgentRevenueChart from "../../../components/Dashboards/SalesAgent/Charts/doughnutChart";
import SalesAgentsCategoryLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesAgentsCategoryLineChart";
import TopSalesAgent from "../../../components/Dashboards/SalesAgent/Cards/TopSalesAgent";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import SalesAgentTable from "../../../components/Dashboards/SalesAgent/Table/SalesAgentTable";
import BackBtn from "../../../components/BackBtn";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { client } from "../../../utils/api";
import { useAsync } from "../../../utils/Hooks/useAsync";
import { FaUserShield, FaPhone } from "react-icons/fa6";
import { MdDateRange, MdMarkEmailRead } from "react-icons/md";
import Skimmer from "../../../components/Loader/Skimmer";
import { Button } from "../../../components/Button";
import { countryList } from "../../../helperFuncs/countryList";
import { errorBlock } from "../../../controllers/errorBlock";
import ProjectManagerTopUpWallet from "../../../components/ProjectManager/Modals/ProjectManagerTopUpWallet";
import SalesTopUpTable from "../../../components/SalesAgent/Table/SalesTopUpTable";
import ProjectManageTopups from "../../../components/SalesAgent/Charts/ProjectManageTopupsLineChart";
import ProjectManageTopupsLineChart from "../../../components/SalesAgent/Charts/ProjectManageTopupsLineChart";
import { meterController } from "../../../controllers/MeterController";
import { useGetAgentRevenueMutation } from "../../../services/revenue.services";
import FilterRevenue from "../../../components/sysadmin/modals/FilterRevenue";
import { useGetSiteDetailsMutation } from "../../../services/projectsite.services";
const SalesAgentDetails = () => {
  const [usernames, setUsernames] = useState([]);
  const { siteid, username } = useParams();

  const userObject = useSelector((state) => state.userDetails);
  const { country, role } = useSelector((state) => state.projectSite);
  const [tabstate, settabstate] = useState(1);
  const [tabstate1, settabstate1] = useState(1);

  const [refresh, setrefresh] = useState(true);
  const [openfilter, setopenfilter] = useState(false);
  const [currency, setcurrency] = useState("");
  const [topmodal, settopmodal] = useState(false);
  const [limit, setlimit] = useState(100);
  const [offset, setoffset] = useState(0);
  const [topups, settopups] = useState([]);
  const [recalltopup, setrecalltopup] = useState(false);
  const [salesStartDate, setsalesStartDate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD")
  );
  const [salesEndDate, setsalesEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const [
    getAgentRevenue,
    { isLoading: agentloading, error: agentError, data: agentData },
  ] = useGetAgentRevenueMutation();

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  // get Site details
  useEffect(() => {
    getSiteDetails({
      siteid,
    }).unwrap();
  }, [refresh]);

  // get revenue
  useEffect(() => {
    getAgentRevenue({
      username,
      siteid,
      startdate: salesStartDate,
      enddate: salesEndDate,
      limit,
      offset,
    }).unwrap();
  }, [refresh]);

  useEffect(() => {
    if (agentError) {
      errorBlock(agentError);
    }
  }, [agentData, refresh]);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"] ?? "");
    }
  }, [agentData, refresh]);

  // get agent tops history
  useEffect(() => {
    (async () => {
      try {
        if (siteData?.country) {
          const res = await axios.post("/wallet/salesagent/gettopup", {
            username,
            currencycode: countryList[siteData?.country]["Currency Code"],
            startdate: salesStartDate,
            enddate: salesEndDate,
          });

          const result = meterController(res);

          if (result.type != "success") {
            toast.error(result.message);
            return;
          }

          settopups(result.message?.body);
        }
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, [agentData, refresh]);

  const AgentTopCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        {
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
        }
        <div className="text-3xl font-semibold">
          {Number(value).toLocaleString("en-US", { minimumFractionDigits: 2 })}
        </div>
      </div>
    );
  };

  const WalletBalanceCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
          <div className="font-semibold text-[13px] bg-secondary text-white py-1 px-2 rounded">
            {siteData?.country
              ? countryList[siteData?.country]["Currency Code"]
              : ""}
          </div>
        </div>

        <div className="text-3xl font-semibold">
          {Number(value)?.toLocaleString("en-US", { minimumFractionDigits: 2 })}
          <sup className="text-[10px]">
            {siteData?.country
              ? countryList[siteData?.country]["Currency Code"]
              : ""}
          </sup>
        </div>
      </div>
    );
  };

  // wallet balance
  const {
    data: walletBalance,
    run: getWalletBalance,
    status: balanceloading,
    error: balanceError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    if (balanceError) {
      return errorBlock(balanceError);
    }
  }, []);

  // get agent wallet balance
  useEffect(() => {
    if (siteData?.country) {
      getWalletBalance(
        client(`/wallet/getwalletbalance`, {
          data: {
            username,
            currencycode: countryList[siteData?.country]["Currency Code"],
          },
          method: "POST",
        })
      );
    }
  }, [getWalletBalance, recalltopup, refresh, agentData]);

  // user details
  const {
    data: userDetails,
    run: getUser,
    status: userloading,
    error: userError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    getUser(
      client(`/auth/useraccount/getuser`, {
        data: { username },
        method: "POST",
      })
    );
  }, [getUser, username]);

  const handleRevenuefilter = (e) => {
    switch (e) {
      case "today":
        setsalesStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "week":
        setsalesStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "month":
        setsalesStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "year":
        setsalesStartDate(
          moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }

    setsalesEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
    setrefresh((prev) => !prev);
  };
  return (
    <Layout>
      {topmodal && (
        <ProjectManagerTopUpWallet
          refresh={setrefresh}
          setopen={settopmodal}
          open={topmodal}
        />
      )}

      {openfilter && (
        <FilterRevenue
          open={openfilter}
          setopen={setopenfilter}
          setEndDate={setsalesEndDate}
          setStartDate={setsalesStartDate}
          refreshBtn={setrefresh}
        />
      )}
      {/* first vp */}
      <div className="bg-white p-2 shadow rounded">
        <div className="flex items-center justify-between">
          <BackBtn />
          {userObject?.role == "Project Manager" && (
            <div className="w-[180px]">
              <Button
                text={"Top up wallet"}
                onClick={() => settopmodal(true)}
              />
            </div>
          )}
        </div>
        <hr className="mt-3" />
        <div className=" flex pb-2 items-end justify-between">
          <div className="flex items-center">
            <div className="h-[20px] mr-2 w-[20px] rounded bg-sky-200"></div>
            <h3 className="m-0">
              Agent username:{" "}
              <span className="font-semibold text-[18px] text-secondary">
                {username ?? "-Nil-"}
              </span>
            </h3>
          </div>
          <div className="px-2">
            Sitename:{" "}
            <span className="text-secondary font-semibold">
              {siteData?.sitename}
            </span>
          </div>
        </div>
        <hr className="mb-4" />
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <PageTitle title={`Sales Agent personal details`} caption={""} />
          </div>

          <div className="">
            <Select
              defaultValue={"today"}
              className="w-[200px]"
              onChange={(e) => {
                if (e == "custom") {
                  setopenfilter(true);
                  return;
                }
                handleRevenuefilter(e);
              }}
              options={[
                { value: "today", label: "Today" },
                { value: "week", label: "This week" },
                { value: "month", label: "This month" },
                { value: "custom", label: "Use custom date" },
              ]}
            />
          </div>
        </div>
        {/* sales Agent details */}
        <div className="grid grid-cols-4 border-[1px] gap-4 w-full py-4 rounded mt-2">
          {/* full name */}
          <div className="flex flex-col items-center justify-center">
            <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
              <FaUserShield size={24} />
            </div>
            <div className="text-gray-400">Full Name</div>
            <div className="font-semibold">
              {!userDetails?.firstname
                ? "Registration not complete"
                : userDetails?.firstname + " " + userDetails?.lastname}
            </div>
          </div>
          {/* Email */}
          <div className="flex flex-col items-center justify-center">
            <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
              <MdMarkEmailRead size={24} />
            </div>
            <div className="text-gray-400">Email</div>
            <div className="font-semibold">
              {!userDetails ? "Registration not complete" : userDetails?.email}
            </div>
          </div>
          {/* Phone */}
          <div className="flex flex-col items-center justify-center">
            <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
              <FaPhone size={24} />
            </div>
            <div className="text-gray-400">Phone</div>
            <div className="font-semibold">
              {!userDetails ? "Registration not complete" : userDetails?.phone}
            </div>
          </div>
          {/* Date registered */}
          <div className="flex flex-col items-center justify-center">
            <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
              <MdDateRange size={24} />
            </div>
            <div className="text-gray-400">Date registered</div>
            <div className="font-semibold">
              {moment(userDetails?.dateregistered).format("LLL")}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex items-center ">
            <PageTitle
              title={`Records for Sales Agent `}
              caption={`Insight of sales made by ${username}`}
            />
          </div>
          {/* top cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-6">
            <WalletBalanceCard
              title={"Wallet balance"}
              value={walletBalance?.response ?? 0}
              caption={`current wallet balance for agent`}
              icon={"credit"}
            />
            <AgentTopCard
              title={"Total Sales"}
              value={`${agentData?.cumulativeStats?.totalRevenue ?? 0}`}
              caption={`Total amount of sales made`}
              icon={"credit"}
            />
            <AgentTopCard
              title={"Commission"}
              value={`${agentData?.cumulativeStats?.totalCommission ?? 0}`}
              caption={"Total paid out commission"}
            />
            <AgentTopCard
              title={"Completed sales"}
              value={`${agentData?.cumulativeStats?.transactionCount ?? 0}`}
              caption={"Total number of completed sales"}
            />
          </div>
        </div>
      </div>
      {/* second vp */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:grid-cols-1 mt-2">
        <div className="h-[auto] p-4 border-[1px] bg-white shadow-sm rounded">
          <div className="flex items-center justify-between">
            <div className="">
              <div className=" items-center justify-between">
                <h2 className=" my-0 text-[15px] font-semibold">
                  Sales records
                </h2>
                <p className="my-0 text-[13px]">
                  Summary of sales by {username}
                </p>
              </div>
            </div>
            <div className="flex items-center ">
              {/* <div className="">
                <Select
                  defaultValue={"today"}
                  style={{
                    width: 150,
                  }}
                  onChange={(e) => handleSalesfilter(e)}
                  options={[
                    { value: "today", label: "Today" },
                    { value: "week", label: "This week" },
                    { value: "month", label: "This month" },
                    { value: "year", label: "This year" },
                  ]}
                />
              </div> */}

              {/* tabs */}
              <div className=" px-3">
                <div className="flex p-[2.5px] w-[fit-content] bg-gray-200 rounded-md items-center">
                  <div
                    onClick={() => settabstate(1)}
                    className={
                      tabstate == 1
                        ? `bg-white rounded-md text-[12px] px-2 py-1 text-center cursor-pointer`
                        : `text-center cursor-pointer px-2 py-1 text-[12px]`
                    }
                  >
                    Chart
                  </div>
                  <div
                    onClick={() => settabstate(2)}
                    className={
                      tabstate == 2
                        ? `bg-white rounded-md px-2 py-1 text-[12px] text-center cursor-pointer`
                        : `text-center cursor-pointer text-[12px] px-2 py-1 `
                    }
                  >
                    Table
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tabstate == 1 ? (
            <div className="">
              <SalesAgentsCategoryLineChart
                currency={agentData?.cumulativeStats?.currency ?? ""}
                data={!agentData?.dailyStats ? [] : agentData?.dailyStats}
              />
            </div>
          ) : (
            <div className="overflow-x-auto overflow-y-hidden">
              <div className="h-[600px] ">
                <SalesAgentTable
                  data={!agentData?.dailyStats ? [] : agentData?.dailyStats}
                  type={"agent"}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="h-[auto] p-4 border-[1px] mt-4 bg-white shadow-sm rounded">
        <div className="flex items-center justify-between">
          <div className="">
            <h2 className=" my-0 text-[15px] font-semibold">Top up history</h2>
            <p className="my-0 text-[13px]">
              Logs of wallets top up on account
            </p>
          </div>
          <div className=" px-3">
            <div className="flex p-[2.5px] w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => settabstate1(1)}
                className={
                  tabstate1 == 1
                    ? `bg-white rounded-md text-[12px] px-2 py-1 text-center cursor-pointer`
                    : `text-center cursor-pointer px-2 py-1 text-[12px]`
                }
              >
                Table
              </div>
              <div
                onClick={() => settabstate1(2)}
                className={
                  tabstate1 == 2
                    ? `bg-white rounded-md px-2 py-1 text-[12px] text-center cursor-pointer`
                    : `text-center cursor-pointer text-[12px] px-2 py-1 `
                }
              >
                Chart
              </div>
            </div>
          </div>
        </div>
        <div className="h-[500px] bg-white ">
          {tabstate1 == 1 && <SalesTopUpTable data={topups} />}
          {tabstate1 == 2 && (
            <ProjectManageTopupsLineChart data={topups} height={40} />
          )}
        </div>
      </div>

      {/* sales tables */}
      <div className="h-[100px] w-full"></div>
    </Layout>
  );
};

export default SalesAgentDetails;
