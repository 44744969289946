import React, { useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../../helperFuncs";
import { Button, Select } from "antd";

const { Option } = Select;

const SiteWideConsumption = ({ data, loading }) => {
  const dailySummaries = data?.daily_summaries || [];
  const dates = [...new Set(dailySummaries.map((item) => item.date))];

  const [selectedDate, setSelectedDate] = useState(dates[0]); // Default to the first date
  const [selectedPhase, setSelectedPhase] = useState("phase_1"); // Default to `phase_1`

  // Filter data by selected date and phase
  const filteredData = dailySummaries.filter(
    (item) => item.date === selectedDate && item[selectedPhase]
  );

  const columns = [
    {
      name: "Metric",
      selector: (row) => row.metric,
      center: true,
      sortable: true,
      cell: (item) => <div className=" !capitalize">{item?.metric}</div>,
    },
    {
      name: "Value",
      selector: (row) => row.value,
      center: true,
    },
  ];

  const getPhaseData = (phaseData) =>
    Object.entries(phaseData).map(([key, value]) => ({
      metric: key.replace(/_/g, " "),
      value,
    }));

  const handleDateChange = (value) => {
    setSelectedDate(value); // Set the selected date value
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="">
          {dailySummaries.length != 0 && (
            <p>
              Show result analytics for{" "}
              <span className="font-semibold">
                {selectedPhase.toUpperCase()}
              </span>
              submeters on <span className="font-semibold">{selectedDate}</span>
            </p>
          )}
        </div>
        {/* Date Dropdown */}
        <div className="flex space-x-4 mb-4 mr-4">
          {dailySummaries.length != 0 && (
            <Select
              className="!w-[200px]"
              value={selectedDate}
              placeholder="Select a date"
              defaultValue={selectedDate}
              onChange={handleDateChange}
            >
              {dates.map((date) => (
                <Option key={date} value={date}>
                  {moment(date).format("YYYY-MM-DD")}
                </Option>
              ))}
            </Select>
          )}

          {/* Phase Tabs */}
          <div className="flex space-x-4 mb-4">
            {["phase_1", "phase_3"].map((phase) => (
              <Button
                key={phase}
                className={`px-4 !outline-none !border-none py-2 ${
                  selectedPhase === phase
                    ? "!bg-blue-500 !text-white"
                    : "!bg-gray-200 text-gray-700"
                } rounded`}
                onClick={() => setSelectedPhase(phase)}
              >
                {phase.toUpperCase()}
              </Button>
            ))}
          </div>
        </div>
      </div>

      {/* Data Table */}
      {filteredData.length > 0 ? (
        <DataTable
          //
          columns={columns}
          data={getPhaseData(filteredData[0][selectedPhase])}
          pagination
          progressPending={loading}
          customStyles={tableCustomStyles}
        />
      ) : (
        <div className="text-center py-4">
          No data available for {selectedPhase.toUpperCase()} on{" "}
          {moment(selectedDate).format("YYYY-MM-DD")}.
        </div>
      )}
    </div>
  );
};

export default SiteWideConsumption;
