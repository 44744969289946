import { themeColor as color, themeColor } from "../../../constant/color";
import { Button } from "../../Button";
import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddRoleImg from "../../../assets/svg/adduser.svg";
import { useState, useEffect } from "react";
import { Input, Select } from "antd";
import "antd/dist/antd.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { userController } from "../../../controllers/UserController";
import { getToken } from "../../../utils/token";
import ReactDOM from "react-dom";
import { meterController } from "../../../controllers/MeterController";
import Rule from "../../Rule/Index";
import { useSelector } from "react-redux";
import { CustomButton } from "../../CustomButton";

const AddRoles = ({ closeModal, refreshBtn, isOpen }) => {
  const [showInput, setShowInput] = useState(false);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [fullName, setfullName] = useState(null);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const { Option } = Select;
  const [site, setSite] = useState([]);
  const { role } = useSelector((state) => state.userDetails);
  const [userRole, setUserRole] = useState("");
  const controller = new AbortController();
  const handleUserName = async () => {
    try {
      if (!username) return toast.error("Please enter a username");
      setLoading(true);
      let res = await axios.post("/auth/useraccount/getfirstandlastname", {
        username,
      });

      let result = userController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }

      if (!result.message.firstname && !result.message.lastname) {
        toast.error("You can only assign roles to verified accounts.");
        setLoading(false);
        return;
      }
      setLoading(false);
      setfullName(result.message);
      setShowInput(true);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/sitemanagement/getallsitesdetails", {});
        setSite(res.data.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  const handleAdd = async () => {
    try {
      if (!username || !selectedRole) {
        toast.error("All value are required");
        return;
      }
      let callerid = await getToken("spiral_username");
      setLoading1(true);
      let res = await axios.post("/roles/createandassignrole", {
        rolecreator: callerid,
        targetusername: username,
        role: selectedRole,
        projectzonearray: JSON.stringify([Number(selectedSite)]),
      });

      const result = meterController(res);

      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading1(false);
        return;
      }

      toast.success("Roles assigned successfully");
      refreshBtn((prev) => !prev);
      setLoading1(false);
      closeModal(false);
    } catch (err) {
      toast.error(err.message);
      setLoading1(false);
    }
  };

  const role_data = {
    sysadmin: [
      {
        value: "Project Manager",
        label: "Project Manager",
      },
      {
        value: "Customer Representative",
        label: "Customer Representative",
      },
    ],
    project: [
      {
        value: "Sales Agent",
        label: "Sales Agent",
      },
      {
        value: "Customer Representative",
        label: "Customer Representative",
      },
    ],
  };

  return (
    <div
      className="d-flex backdrop align-items-center justify-content-center"
      style={{}}
    >
      <div
        className="w-[95%] md:w-[450px] Select role and px-3 py-4 animate__animated animate__fadeInUp  p-2 rounded-2 shadow-sm bg-white rounded-md"
        style={{}}
      >
        <div className="flex items-center justify-between">
          <div className="font-semibold mb-1 ">Assign Role</div>
          <div className="text-secondary" onClick={() => closeModal(false)}>
            Close
          </div>
        </div>
        <Rule />
        {!showInput && (
          <>
            <div className="mb-4">
              To assign a role to a staff, start by searching for the staff
              account.
            </div>
            <div className="w-full mt-2">
              <Input
                type="text"
                className="w-full px-2 h-[40px] border-[1px] border-gray-400  rounded-md"
                placeholder={"Enter the staff username."}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </>
        )}

        {showInput && (
          <>
            <div className="mb-1">
              Select role and project site you would like to assign to this
              user.
            </div>
            <div className=" w-full mt-3 ">
              <input
                type="text"
                className="w-full h-[40px] outline-none border border-gray-300 px-2 rounded"
                // defaultValue={"David John"}
                placeholder={"Enter the username of the user."}
                disabled={true}
                value={`${fullName?.firstname} ${fullName?.lastname}`}
              />
            </div>

            <div className="w-full my-3">
              {/* <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                {userRole == "System Manager" && (
                  <>
                    <option value={"Project Manager"}>Project Manager.</option>
                    <option value={"Customer Representative"}>
                      Customer Representative
                    </option>
                  </>
                )}

                {userRole == "Project Manager" && (
                  <>
                    <option value={"Customer Representative"}>
                      Customer Representative.
                    </option>
                    <option value={"Sales Agent"}> Sales Agent.</option>
                  </>
                )}
              </select> */}
              <Select
                size="large"
                style={{
                  width: "100%",
                  height: "45px",
                }}
                placeholder={"Select role"}
                onChange={(e) => setSelectedRole(e)}
              >
                {role == "System Manager" && (
                  <>
                    <Option value={"Project Manager"}>Project Manager</Option>
                    <Option value={"Customer Manager"}>Customer Manager</Option>
                  </>
                )}

                {role == "Project Manager" && (
                  <>
                    <Option value={"Customer Manager"}>
                      Customer Manager.
                    </Option>
                    <Option value={"Customer Representative"}>
                      Customer Representative.
                    </Option>
                    <Option value={"Sales Agent"}> Sales Agent.</Option>
                  </>
                )}
              </Select>
            </div>

            {/* <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedSite(e.target.value)}
              >
                {site.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </select> */}

            <div className="w-100 mt-1">
              <Select
                size="large"
                style={{
                  width: "100%",
                  height: "45px",
                }}
                showSearch
                onChange={(e) => setSelectedSite(e)}
                placeholder={"Select sitename"}
              >
                {site.map((item, i) => (
                  <Option key={item.siteid} value={item.siteid}>
                    {item.sitename}
                  </Option>
                ))}
              </Select>
            </div>
          </>
        )}

        {!showInput && (
          <>
            <div className="flex items-center mt-3 justify-between w-full pt-3">
              {/* <div className="addrole-btn" onClick={() => closeModal(false)}>
                <Button
                  text="Cancel"
                  bg={color.btnFadeColor}
                  color={color.darkColor}
                  height={"40px"}
                />
              </div> */}
              <div className="w-full">
                {/* loading */}
                <CustomButton
                  loading={loading}
                  onClick={() => handleUserName()}
                  className={
                    "flex w-full items-center m-0 justify-center bg-black h-full   text-white  p-2 rounded "
                  }
                >
                  <p className="m-0">Search</p>
                </CustomButton>
              </div>
            </div>
          </>
        )}

        {showInput && (
          <>
            <div className="mt-3 grid gap-4 grid-cols-2 w-full pt-3">
              <div className="w-full">
                <CustomButton
                  className={
                    "border p-2 flex items-center justify-center w-full rounded border-black "
                  }
                  onClick={() => setShowInput(false)}
                >
                  <p className="m-0 text-black">Back</p>
                </CustomButton>
                {/* <Button text="Back" bg={themeColor.secondary} height={"40px"} /> */}
              </div>
              <div className="w-full">
                {/* <Button
                  text="Done"
                  height={"40px"}
                  status={loading1}
                  onClick={handleAdd}
                /> */}

                <CustomButton
                  loading={loading1}
                  onClick={handleAdd}
                  className={
                    "flex w-full items-center m-0 justify-center bg-black h-full  text-white  p-2 rounded "
                  }
                >
                  <p className="m-0">Done</p>
                </CustomButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddRoles;

{
  /* {userRole == "Customer Representative" && (
                  <>
                    <Option value={"Customer Representative"}>
                      Customer Representative.
                    </Option>
                    <Option value={"Sales Agent"}>Customer</Option>
                  </>
                )} */
}
