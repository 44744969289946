// import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
// import AddRoleImg from "../../../../assets/svg/adduser.svg";
import { useState, useEffect } from "react";
import { Select, Form, Input, Modal, Button } from "antd";
import "antd/dist/antd.css";
// import Events from "events";
import toast from "react-hot-toast";
import axios from "../../../../utils/axios";
import { userController } from "../../../../controllers/UserController";
// import { meterController } from "../../../../controllers/MeterController";
// import { GiConsoleController } from "react-icons/gi";
// import { getToken } from "../../../../utils/token";
// import { themeColor as color } from "../../../../constant/color";
// import { Button } from "../../../Button";
// import CloseButton from "../../../CloseButton";
import { useSelector } from "react-redux";
// import { errorBlock } from "../../../../controllers/errorBlock";
// import { mqttEvents } from "../../../../context/MqttContext";
// import { CustomButton } from "../../../CustomButton";
// import Backdrop from "../../../Backdrop";
import {
  useAssignMeterMutation,
  useAssignRFMeterMutation,
  useGetMeterDetailsMutation,
} from "../../../../services/meter.services";
import { useGetDCUsMutation } from "../../../../services/dcu.services";
import { useGetCustomerClassInSiteMutation } from "../../../../services/customerclass.services";
import { useGetcustomerAddressMutation } from "../../../../services/customer.services";
import { errorBlock } from "../../../../controllers/errorBlock";

const AssignMeter = ({ open, setopen, refresh, user }) => {
  let username = user?.customerid;

  const [tabState, setTabState] = useState(user?.customerid ? 1 : 0);

  const [customerId, setCustomerId] = useState(user?.customerid || "");
  const [meterId, setMeterId] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [customerClass, setCustomerClass] = useState("");
  const [dcuid, setDcuid] = useState("");
  const [addressOptions, setAddressOptions] = useState([]);
  const [form] = Form.useForm();
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  // get all DCU
  const [getDCUs, { isLoading: dculoading, error: dcuError, data: dcuData }] =
    useGetDCUsMutation();

  const [
    getcustomerAddress,
    { isLoading: addressloading, error: addressError, data: addressData },
  ] = useGetcustomerAddressMutation();
  // assign meter
  const [
    assignMeter,
    { isLoading: assignloading, error: assignError, data: assignData },
  ] = useAssignMeterMutation();

  // assign Rf meter
  const [
    assignRFMeter,
    { isLoading: assignRfloading, error: assignRfError, data: assignRfData },
  ] = useAssignRFMeterMutation();

  // get customer class
  const [
    getCustomerClassInSite,
    {
      isLoading: customerclassloading,
      error: customerclassError,
      data: customerclassData,
    },
  ] = useGetCustomerClassInSiteMutation();

  const [
    getMeterDetails,
    { isLoading: meterloading, error: meterError, data: meterData },
  ] = useGetMeterDetailsMutation();

  useEffect(() => {
    if (meterId.length === 13) {
      // Make the API call
      getMeterDetails(meterId).unwrap();
    }
  }, [meterId]);

  const handleUserSearch = async () => {
    try {
      if (!customerId) return toast.error("Please enter a username");
      let res = await axios.post("/auth/utility/customeraccount/searchuser", {
        keyword: customerId,
      });

      let result = userController(res);

      if (result.type !== "success") {
        toast[result.type](result.message);
        return;
      }

      if (result.message.body.length === 0) {
        toast.error("No user found with this username.");
        return;
      }

      setCustomerId(result.message.body[0]?.username);
      setTabState(1); // Move to the next step
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleAssignMeter = async () => {
    try {
      if (!(customerId && selectedAddress && customerClass)) {
        toast.error("Please fill all required fields.");
        return;
      }

      const payload = {
        meternumber: meterId,
        customerid: customerId ?? username,
        address: selectedAddress,
        siteid,
        customerclass: customerClass,
        dcuid: dcuid || undefined, // Optional for RF meters
      };

      const res = meterData?.rfmeter
        ? await assignRFMeter(payload).unwrap()
        : await assignMeter(payload).unwrap();

      if (res.response !== "success") {
        toast.error(res.response || "Meter assignment failed");
        return;
      }
      refresh();

      toast.success("Meter assigned successfully");
      setopen(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (user) {
      setCustomerId(user?.customerid);
      setTabState(1);
    }
  }, [user]);

  useEffect(() => {
    if (customerId) {
      getcustomerAddress(customerId)
        .unwrap()
        .then((res) => {
          setAddressOptions(
            res.body.map((addr) => ({ value: addr, label: addr }))
          );
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [customerId]);

  // get customer class

  useEffect(() => {
    getCustomerClassInSite(siteid)
      .unwrap()
      .catch((err) => errorBlock(err));
  }, []);

  useEffect(() => {
    getDCUs({ projectsite: siteid })
      .unwrap()
      .catch((err) => errorBlock(err));
  }, []);

  return (
    <Modal
      title="Assign Meter to Customer"
      visible={open}
      onCancel={() => setopen(false)}
      footer={null}
      width={450}
    >
      {tabState === 0 && (
        <Form form={form} layout="vertical" onFinish={handleUserSearch}>
          <Form.Item
            label="Customer Username"
            name="customerId"
            rules={[
              { required: true, message: "Please enter the customer username" },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter customer username"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}
            />
          </Form.Item>
          <Button
            className="!text-white mt-3 w-full !bg-black !border-none !outline-none"
            type="primary"
            htmlType="submit"
          >
            Search
          </Button>
        </Form>
      )}

      {tabState === 1 && (
        <Form form={form} layout="vertical" onFinish={handleAssignMeter}>
          <Form.Item label="Customer ID" name="customerId">
            <Input value={customerId} disabled />
            {JSON.stringify()}
          </Form.Item>

          <Form.Item
            label="Meter Number"
            name="meterId"
            rules={[
              { required: true, message: "Please enter a valid meter number" },
            ]}
          >
            <Input
              placeholder="Enter meter number"
              value={meterId}
              onChange={(e) => setMeterId(e.target.value)}
            />
          </Form.Item>

          {meterData?.rfmeter && (
            <Form.Item
              label="DCU"
              name="dcuid"
              rules={[{ required: true, message: "Please select a DCU" }]}
            >
              <Select
                placeholder="Select DCU"
                options={dcuData?.body.map((dcu) => ({
                  value: dcu.dcuid,
                  label: `${dcu.dcualias} (ID: ${dcu.dcuid})`,
                }))}
                onChange={setDcuid}
              />
            </Form.Item>
          )}

          <Form.Item
            label="Customer Address"
            name="selectedAddress"
            rules={[{ required: true, message: "Please select an address" }]}
          >
            <Select
              placeholder="Select an address"
              options={addressOptions}
              onChange={setSelectedAddress}
            />
          </Form.Item>

          <Form.Item
            label="Customer Class"
            name="customerClass"
            rules={[
              { required: true, message: "Please select a customer class" },
            ]}
          >
            <Select
              placeholder="Select customer class"
              options={
                customerclassData?.customerClasses?.map((cls) => ({
                  value: cls.customerClass,
                  label: `${cls.customerClass} (${cls.customerType})`,
                })) || []
              }
              onChange={setCustomerClass}
            />
          </Form.Item>

          <Button
            className="!text-white mt-3 w-full !bg-black !border-none !outline-none"
            type="primary"
            block
            onClick={handleAssignMeter}
            loading={assignRfloading}
          >
            Assign
          </Button>
        </Form>
      )}
    </Modal>
  );
};

export default AssignMeter;
