import "./styles.css";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "../../../../utils/axios";
import toast from "react-hot-toast";
import { Button } from "../../../Button";
import { themeColor as color } from "../../../../constant/color";
import { meterController } from "../../../../controllers/MeterController";
import Backdrop from "../../../Backdrop";
import { errorBlock } from "../../../../controllers/errorBlock";

const MeterInfoModal = ({ setopen, open, meterNumber }) => {
  const [meterDetails, setMeterDetails] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getmeter", {
          meternumber: meterNumber,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }
        setMeterDetails(result.message);
        setLoading(false);
      } catch (err) {
        errorBlock(err);
      }
    })();
  }, []);

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[450px] bg-white drop-shadow-md rounded-sm p-3">
          <div className=" pb-1 flex items-center justify-between">
            <div className="font-semibold">Meter Info</div>
            <div className=" cursor-pointer" onClick={() => setopen(false)}>
              {/* <IoClose size={18} color={color.darkColor} /> */}
              Close
            </div>
          </div>
          <hr />
          <div className="">
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Meter number:</div>
              <div className="font-semibold">
                {meterDetails?.meterid ?? "-"}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Meter Type:</div>
              <div className="font-semibold">
                {meterDetails?.type ?? "-"} (
                {meterDetails?.registrar ? " RF " : " SIM "})
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Meter Registrar:</div>
              <div className="font-semibold">
                {meterDetails?.registrar ?? "-"}
              </div>
            </div>

            <div className=" mt-4 flex items-center justify-between">
              <div className="">Meter load limit:</div>
              <div className="flex font-semibold">
                {meterDetails?.loadlimit ?? 0}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Date registered:</div>
              <div className="font-semibold">
                {meterDetails?.dateregistered
                  ? moment(meterDetails?.dateregistered)
                      .subtract("1", "week")
                      .format("DD-MM-YYYY HH:mm")
                  : "-"}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Date decommissioned:</div>
              <div className="font-semibold">
                {meterDetails?.decommissioned
                  ? moment(meterDetails?.decommissioned).format(
                      "DD-MM-YYYY HH:mm"
                    )
                  : "-"}
              </div>
            </div>

            <div className="row mt-4 d-flex align-items-center justify-content-end">
              <div className="col-4" onClick={() => setopen(false)}>
                <Button
                  text={"Done"}
                  bg={color.black}
                  color={color.whiteColor}
                  height={"35px"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default MeterInfoModal;
