import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Search,
  Toolbar,
  ExcelExport,
  Sort,
  Freeze,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "../../../Button";
// import { color } from "../../../../constant/color";
import { sampleData } from "./data";
// import MeterInfoModal from "../../MeterInfoModal";
import { toast } from "react-hot-toast";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import SetLoadPolicy from "../Modals/SetLoadPolicy";
import { themeColor as color } from "../../../../constant/color";
import MeterInfoModal from "../../modals/MeterInfoModal";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";
import { useSelector } from "react-redux";
import { tableCustomStyles } from "../../../../helperFuncs";

const AllBatchMeterTable = ({ data }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectMeter, setselectMeter] = useState(null);
  const [selectedLoading, setselectedLoading] = useState(null);
  const [loading, setloading] = useState(null);
  const [loadType, setloadType] = useState(null);
  const [showSet, setshowSet] = useState(false);
  let grid = useRef();
  let num = 1;
  let final = data.map((i) => {
    return {
      num: num++,
      ...i,
    };
  });
  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);
  const userDetials = useSelector((state) => state.userDetials);

  const handleLoad = async (type, number) => {
    try {
      if (type == "set") {
        return setshowSet(true);
      } else if (type == "get") {
        let res = await axios.post("/meter/getpermeterloadcontrolpolicy", {
          meternumber: number,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);

          setloadType(null);
          setselectMeter(null);
          return;
        }
        // console.log(result.message, "/get");
        setloadType(null);
        setselectMeter(null);
        setloading(false);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", selectedRows);
  };

  const columns = [
    {
      name: "Batch No",
      center: true,
      selector: (row) => row.batchid,
      grow: 2,
      sortable: true,
    },
    // {
    //   name: "Meter type",
    //   center: true,
    //   selector: (row) => row.type,
    //   grow: 2,
    //   sortable: true,
    // },
    {
      name: "Meters per device",
      center: true,
      selector: (row) => row.numberofchannels,
      grow: 2,
      sortable: true,
    },
    {
      name: "Phase type",
      center: true,
      selector: (row) => row.phases,
      grow: 1,
      sortable: true,
    },
    {
      name: "Model",
      center: true,
      selector: (row) => row.model,
      grow: 1,
      sortable: true,
    },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center text-[13px]">
            {moment(item.dateregistered).format("lll")}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.siteid,
      grow: userDetials?.role != "Project Manager" ? 2 : 4,
      center: true,
      cell: (item) => {
        return (
          <Link
            to={`/meter/batch/${item.batchid}`}
            className="flex relative rounded-md items-center justify-between"
          >
            <div className="flex border-[1px] cursor-pointer ml-2 rounded border-secondary text-[12px] text-secondary font-light px-4 py-2">
              View Details
            </div>

            {userDetials?.role == "Project Manager" && (
              <div className="flex ">
                <div
                  onClick={() => {
                    setloadType("get");
                    setselectMeter(item?.number);
                    handleLoad("get", item?.number);
                  }}
                  className=" border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
                >
                  Get load policy
                </div>
                <div
                  onClick={() => {
                    setselectMeter(item?.number);
                    setshowSet(true);
                  }}
                  // to={`/dashboard/systemadmin/projectsite/${item.sitename}`}
                  className=" border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
                >
                  Set load policy
                </div>
              </div>
            )}
          </Link>
        );
      },
    },
  ];

  return (
    <div
      style={{
        borderTop: "1px solid #eee",
      }}
      className=""
    >
      {modal && (
        <MeterInfoModal closeModal={setModal} meterNumber={selectedId} />
      )}

      {showSet && (
        <SetLoadPolicy meterId={selectMeter} closeModal={setshowSet} />
      )}
      <DataTable
        // selectableRows
        onSelectedRowsChange={handleChange}
        columns={columns}
        data={data}
        pagination
        // expandableRows
        // expandableRowsComponent={<div className="">this is a sample</div>}
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default AllBatchMeterTable;
