import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Button, Input, Form } from "antd";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Backdrop from "../../Backdrop";
import CloseButton from "../../CloseButton";
import Rule from "../../Rule/Index";
import ErrorText from "../../Errors/ErrorText";
import axios from "../../../utils/axios";
import { channelController } from "../../../controllers/channelController";

const AddUser = ({ open, setopen }) => {
  const [eye, seteye] = useState(false);
  const navigate = useNavigate();
  const { companyname } = useSelector((state) => state.domain);
  const [password, setpassword] = useState("12345");
  const [username, setusername] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setloading(true);
      let res = await axios.post("/auth/createaccount", {
        username: username.trim().toLowerCase(),
        defaultpassword: password.toLowerCase(),
        // phone: phonenumber.trim(),
        email: email.trim(),
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }
      toast.success("User created successfully");
      setloading(false);
      setopen(false);
      navigate("/users");
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] rounded bg-white drop-shadow-md p-3 ">
          <div className="flex items-start w-full justify-between">
            <div>
              <div className="font-semibold text-[17px]">
                Add a user <span className="capitalize">{companyname}</span>
              </div>
              <p>
                Fill the form fields below to add and assign a role to user in
                your company
              </p>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "Please enter a username" }]}
            >
              <Input
                size="large"
                onChange={(e) => setusername(e.target.value)}
                placeholder="Enter a username for this user"
              />
            </Form.Item>

            {/* <Form.Item
              label="Phone Number"
              name="phonenumber"
              rules={[
                { required: true, message: "Please enter a phone number" },
              ]}
            >
              <Input
                size="large"
                onChange={(e) => setphonenumber(e.target.value)}
                type="number"
                placeholder="Enter the phone number of the user"
              />
            </Form.Item> */}

            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Please enter an email address" },
              ]}
            >
              <Input
                size="large"
                onChange={(e) => setemail(e.target.value)}
                type="email"
                placeholder="Enter the email of the user"
              />
            </Form.Item>

            <Form.Item label="Password">
              <div className="flex items-center">
                <Input.Password
                  value={password}
                  size="large"
                  onChange={(e) => setpassword(e.target.value)}
                  type={eye ? "text" : "password"}
                  placeholder="Enter your password"
                  className="w-full"
                />
                {/* <div
                  className="px-3 cursor-pointer"
                  onClick={() => seteye(!eye)}
                >
                  {eye ? (
                    <AiOutlineEye size={20} />
                  ) : (
                    <AiOutlineEyeInvisible size={20} />
                  )}
                </div> */}
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                className="!bg-black !text-white !border-none !outline-none"
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
                block
              >
                Add User
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddUser;
