import React, { useState } from "react";
import Layout from "../../../components/Layout";
import { Tabs } from "antd";
import DateRangePicker from "../../../components/DateRangePicker";
import moment from "moment";
import PowerQualityChart from "../../../components/Dashboards/SystemAdmin/Chart/PowerQualityChart";
import BackBtn from "../../../components/BackBtn";
import ProjectSiteConsumption from "../../ProjectManager/ProjectSite/ProjectSiteConsumption";
const SiteWideAnalytics = () => {
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "month").format("YYYY-MM-DD HH:mm:ss")
  );

  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  //   const [getBasicPowerQauality, { isLoading, error, data }] =
  //     useGetSalesBySysAdminMutation();

  const onChange = (key) => {};
  const items = [
    {
      key: "1",
      label: "Daily Projectsite consumption",
      children: (
        <div className="h-[75vh]">
          <PowerQualityChart />
        </div>
      ),
    },
    {
      key: "2",
      label: "All Project Site Comsumption",
      children: (
        <div className="h-[75vh]">
          <PowerQualityChart />
        </div>
      ),
    },
  ];
  const [refresh, setRefresh] = useState(false);

  return (
    <Layout>
      <div className="bg-white rounded-md p-2 rounde-md drop-shadow-sm">
        {/* <div className="font-bold">Your Project Site Analytics</div> */}
        <div className="flex justify-between my-2">
          <div className="flex">
            <div className="h-[30px] mr-2 w-[20px] rounded bg-sky-200"></div>
            <div className="font-semibold pt-1 text-[16px] ">
              Your Project Site Analytics
            </div>
          </div>
          {/* <DateRangePicker
            setEndDate={setenddate}
            setStartDate={setstartdate}
            refreshBtn={setRefresh}
          /> */}
        </div>
        {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}

        <ProjectSiteConsumption />
      </div>
    </Layout>
  );
};

export default SiteWideAnalytics;
