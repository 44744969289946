import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const meterAPI = createApi({
  reducerPath: "meterPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    // Existing endpoints
    getMeterDetails: build.mutation({
      query: (data) => ({
        url: "meter/getmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data,
        },
      }),
    }),
    getMetersInSite: build.mutation({
      query: (data) => ({
        url: "/meter/getallmetersinzone",
        method: "POST",
        body: {
          ...credentials,
          zone: Number(data),
        },
      }),
    }),
    assignMeter: build.mutation({
      query: (data) => ({
        url: "meter/assignmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          customerid: data?.customerid,
          address: data?.address,
          zone: Number(data?.siteid),
          customerclass: data?.customerclass,
        },
      }),
    }),
    unassignMeter: build.mutation({
      query: (data) => ({
        url: "meter/unassignmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          customerid: data?.customerid,
          address: data?.address,
        },
      }),
    }),
    assignRFMeter: build.mutation({
      query: (data) => ({
        url: "meter/assignrfmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          dcuid: data?.dcuid,
          customerid: data?.customerid,
          address: data?.address,
          zone: Number(data?.siteid),
          customerclass: data?.customerclass,
        },
      }),
    }),
    unassignRFMeter: build.mutation({
      query: (data) => ({
        url: "/meter/unassignrfmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          customerid: data?.customerid,
          address: data?.address,
        },
      }),
    }),
    getAllCustomerMetersInSite: build.mutation({
      query: (data) => ({
        url: "customer/getallmetercustomers",
        method: "POST",
        body: {
          ...credentials,
          zone: Number(data?.siteid),
          startdatecreated: data?.startdatecreated,
          enddatecreated: data?.enddatecreated,
          customermanager: data?.customermanager,
        },
      }),
    }),

    // Newly added endpoints
    countAllDecommissionedMeters: build.mutation({
      query: (data) => ({
        url: "/meter/countalldecommisionedmeters",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    countAllMetersAssignedToCustomers: build.mutation({
      query: () => ({
        url: "/meter/countallmetersassignedtocustomers",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    countAllMetersInService: build.mutation({
      query: (data) => ({
        url: "/meter/countallmetersinservice",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    countAllMetersNotAssignedToCustomers: build.mutation({
      query: () => ({
        url: "/meter/countallmetersnotassignedtocustomers",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    countAllRegisteredMeters: build.mutation({
      query: (data) => ({
        url: "/meter/countallregisteredmeters",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    generateMeterControlTokens: build.mutation({
      query: (data) => ({
        url: "/meter/generatemetercontroltokens",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          operation: data?.operation,
        },
      }),
    }),
    getAllMeterBatches: build.mutation({
      query: (data) => ({
        url: "/meter/getallmeterbatches",
        method: "POST",
        body: {
          ...credentials,
          datesortorder: data?.datesortorder,
        },
      }),
    }),
    getAllMeters: build.mutation({
      query: (data) => ({
        url: "/meter/getallmeters",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          limit: data?.limit,
          offset: data?.offset,
          datesortorder: data?.datesortorder,
        },
      }),
    }),
    getAllMetersByBatchId: build.mutation({
      query: (data) => ({
        url: "/meter/getallmetersbybatchid",
        method: "POST",
        body: {
          ...credentials,
          batch: data?.batch,
        },
      }),
    }),
    meterSearch: build.mutation({
      query: (data) => ({
        url: "meter/search",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    getCustomerMeterDetails: build.mutation({
      query: (data) => ({
        url: "customer/getmetercustomer",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
  }),
});

export const {
  useGetCustomerMeterDetailsMutation,
  useMeterSearchMutation,
  useGetMeterDetailsMutation,
  useAssignMeterMutation,
  useAssignRFMeterMutation,
  useGetMetersInSiteMutation,
  useUnassignMeterMutation,
  useUnassignRFMeterMutation,
  useGetAllCustomerMetersInSiteMutation,
  useCountAllDecommissionedMetersMutation,
  useCountAllMetersAssignedToCustomersMutation,
  useCountAllMetersInServiceMutation,
  useCountAllMetersNotAssignedToCustomersMutation,
  useCountAllRegisteredMetersMutation,
  useGenerateMeterControlTokensMutation,
  useGetAllMeterBatchesMutation,
  useGetAllMetersMutation,
  useGetAllMetersByBatchIdMutation,
} = meterAPI;
