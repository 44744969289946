import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackBtn from "../../../../components/BackBtn";
import CardTitle from "../../../../components/CardTitle";
import PowerQuality from "../../../../components/Dashboards/PowerQuality";
import Revenue from "../../../../components/Dashboards/Revenue";
import StatsCard from "../../../../components/Dashboards/StatsCard";
import Layout from "../../../../components/Layout";
import moment from "moment";
import { IoIosLink } from "react-icons/io";

import Rule from "../../../../components/Rule/Index";
import { useAsync } from "../../../../utils/Hooks/useAsync";
import { client } from "../../../../utils/api";
import { useGetProjectSiteRevenueMutation } from "../../../../services/revenue.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import ProjectSiteSalesTable from "../../../../components/ProjectManager/ProjectSite/Tables/ProjectSiteSalesTable";
import FilterDateRange from "../../../../components/FilterDateRange";
import { useGetMetersInSiteMutation } from "../../../../services/meter.services";
import { Select } from "antd";
import DateRangePicker from "../../../../components/DateRangePicker";

const ProjectSiteDetails = () => {
  const { siteid } = useParams();
  const [startdate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [revenue, setRevenue] = useState(0);
  const [tab, settab] = useState(2);
  const [refresh, setRefresh] = useState(false);

  const [
    getMetersInSite,
    { isLoading: metersloading, error: metersError, data: metersData },
  ] = useGetMetersInSiteMutation();

  useEffect(() => {
    getMetersInSite(siteid).unwrap();
  }, []);

  useEffect(() => {
    if (metersError) {
      errorBlock(metersError);
    }
  }, [metersError]);

  const {
    data: sitedetails,
    run: getSiteDetails,
    status: siteStatus,
    error: siteError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  // get site details
  useEffect(() => {
    getSiteDetails(
      client(`/sitemanagement/getspecificsitebysiteid`, {
        data: {
          siteid,
        },
        method: "POST",
      })
    );
  }, [getSiteDetails]);

  // get all customer manager role
  const {
    data: cmRoles,
    run: getCmRoles,
    status: cmRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  // get all Customer manager
  useEffect(() => {
    getCmRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(siteid)]),
          role: "Customer Manager",
        },
        method: "POST",
      })
    );
  }, [getCmRoles]);

  // get all Customer representative
  const {
    data: crRoles,
    run: getCrRoles,
    status: crRoleStatus,
    error: crErrors,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    if (crErrors) {
      errorBlock(crErrors);
    }
  }, [crErrors]);

  // get all Customer representative
  useEffect(() => {
    getCrRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(siteid)]),
          role: "Customer Representative",
        },
        method: "POST",
      })
    );
  }, [getCrRoles]);

  // sl roles
  const {
    data: slRoles,
    run: getSlRoles,
    status: slRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    getSlRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(siteid)]),
          role: "Sales Agent",
        },
        method: "POST",
      })
    );
  }, [getSlRoles]);

  // pm roles
  const {
    data: pmRoles,
    run: getPmRoles,
    status: PmRoleStatus,
    error: pmError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    getPmRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(siteid)]),
          role: "Project Manager",
        },
        method: "POST",
      })
    );
  }, [getPmRoles]);

  useEffect(() => {
    if (pmError) {
      errorBlock(pmError);
    }
  }, [pmError]);

  const SalesBalanceCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
          <div className="font-semibold text-[13px] bg-secondary text-white py-1 px-2 rounded">
            {salesData?.currency ?? ""}
          </div>
        </div>
        <div className="text-3xl font-semibold">
          {Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    );
  };

  const AgentTopCard = ({ title, value, caption, icon, type }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-3xl font-semibold">
          {type == "count"
            ? value
            : Number(value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          {(type == "sales" || type == "balance" || type == "spent") && (
            <sup className="mt-[-10px] text-[12px]">
              {salesData?.currency ?? ""}
            </sup>
          )}
          {type == "units" && <sup className=" text-[12px] font-bold">kWh</sup>}
        </div>
      </div>
    );
  };

  // get site sales Revenue
  const [
    getProjectSiteRevenue,
    { isLoading: salesLoading, data: salesData, error: salesError },
  ] = useGetProjectSiteRevenueMutation();

  // get site Revenue
  useEffect(() => {
    getProjectSiteRevenue({
      startdate,
      enddate,
      siteid,
    }).unwrap();
  }, [refresh]);

  useEffect(() => {
    if (salesError) {
      errorBlock(salesError);
    }
  }, [salesError]);

  const [modal, setmodal] = useState(false);

  const handleRevenuefilter = (e) => {
    switch (e) {
      case "today":
        setStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "week":
        setStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "month":
        setStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "year":
        setStartDate(
          moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }

    setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
    setRefresh((prev) => !prev);
  };

  // console.log(siteid, "meter data");
  return (
    <Layout>
      <div className="">
        <div className="mt-2">
          <div className="bg-white p-2 rounded shadow-md">
            <BackBtn text={"Go back"} />
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[16px]">
                Site name:{" "}
                <span className="font-semibold text-secondary">
                  {" "}
                  {sitedetails ? sitedetails?.sitename : "-Nil-"}
                </span>{" "}
              </div>
              <DateRangePicker
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                refreshBtn={setRefresh}
                startDate={startdate}
                endDate={enddate}
              />
              {/* <FilterDateRange
                  setopen={setmodal}
                  open={modal}
                  setRefresh={setRefresh}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  handlefilter={handleRevenuefilter}
                /> */}
            </div>
            <hr />
            {/* <div className="font-semibold text-[16px]">Site details --- </div> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-2">
              <SalesBalanceCard
                title={"Total sales"}
                value={salesData?.cumulativeStats?.totalRevenue ?? 0}
                caption={`Summary of sales made`}
                icon={"balance"}
                type={"sales"}
              />
              <AgentTopCard
                title={"Net revenue"}
                value={salesData?.cumulativeStats?.netRevenue ?? 0}
                caption={`Total number sales made`}
                icon={"balance"}
                type={"sales"}
              />
              <AgentTopCard
                title={"Total paid out commission"}
                value={salesData?.cumulativeStats?.totalCommission ?? 0}
                caption={`Total units recharged acroos all meters`}
                icon={"balance"}
              />
              <AgentTopCard
                title={"Total units sold (kWh)"}
                value={salesData?.cumulativeStats?.totalKWh ?? 0}
                caption={`Total units recharged acroos all meters`}
                icon={"balance"}
                type={"units"}
              />

              {/* <AgentTopCard
                title={"Close tickets"}
                value={"0"}
                caption={`Total number of tickets`}
                icon={"credit"}
              /> */}
              {/* <AgentTopCard title={"Pending sales"} value={""} /> */}
            </div>
          </div>

          {/* stats and Project site */}
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 p-2 mt-2 w-full bg-white shadow-md rounded-md">
              <div className="flex items-center justify-between">
                <div className="font-semibold">Project site name</div>
                <div className="font-bold text-[30px]">
                  {sitedetails ? sitedetails?.sitename : "-Nil-"}
                </div>
              </div>

              <Rule />
              <div className="py-2 flex items-center justify-between">
                <div className="">Project site type:</div>
                <div className="font-semibold">
                  {sitedetails ? sitedetails?.sitetype : "-Nil-"}
                </div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="">GPS:</div>
                {sitedetails?.gps ? (
                  <Link
                    to={`/projectsite/location?lat=${
                      sitedetails.gps.split(",")[0]
                    }&long=${sitedetails.gps.split(",")[1]}&siteid=${
                      sitedetails?.siteid
                    }`}
                    className=" flex items-center justify-end font-semibold underline"
                  >
                    {sitedetails ? sitedetails?.gps : ""}
                    <div className="">
                      <IoIosLink className="" size={15} />
                    </div>
                  </Link>
                ) : (
                  <div className=" flex items-center justify-end font-semibold underline">
                    {sitedetails ? sitedetails?.gps : ""}
                    <div className="">
                      <IoIosLink className="" size={15} />
                    </div>
                  </div>
                )}
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="">Country:</div>
                <div className="font-semibold">
                  {sitedetails?.country ?? "-Nil-"}
                </div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="">Timezone:</div>
                <div className="font-semibold">
                  {sitedetails?.timezone ?? "-Nil-"}
                </div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="">Date created:</div>
                <div className="font-semibold">
                  {sitedetails
                    ? moment(sitedetails?.datecreated).format("lll")
                    : ""}
                </div>
              </div>
            </div>
            <div className="col-span-1 p-2 mt-2 w-full bg-white shadow-md rounded-md">
              <StatsCard
                sitedetails={sitedetails}
                meters={metersData?.meta?.count ?? 0}
                meterTxt="Total meters"
                projectManagers={pmRoles?.count ?? 0} //
                customers={cmRoles?.count ?? 0} // customer manager
                revenue={slRoles?.count ?? 0} // sales agent
              />
            </div>
          </div>
          {/* Revenue Charts */}
          <div className="row mt-4 w-full bg-white rounded-md shadow-sm p-2">
            <div className="flex items-center mb-3 justify-between">
              <div className="">
                <h3 className="m-0">Sales generated in site</h3>
                <p className="font-light text-[13px]">
                  This table shows total sales and transaction activity.
                </p>
              </div>

              <div className=" px-3">
                <div className="flex p-[2.5px] w-[fit-content] bg-gray-200 rounded-md items-center">
                  <div
                    onClick={() => settab(1)}
                    className={
                      tab == 1
                        ? `bg-white rounded-md text-[12px] px-2 py-1 text-center cursor-pointer`
                        : `text-center cursor-pointer px-2 py-1 text-[12px]`
                    }
                  >
                    Table
                  </div>
                  <div
                    onClick={() => settab(2)}
                    className={
                      tab == 2
                        ? `bg-white rounded-md px-2 py-1 text-[12px] text-center cursor-pointer`
                        : `text-center cursor-pointer text-[12px] px-2 py-1 `
                    }
                  >
                    Chart
                  </div>
                </div>
              </div>
            </div>
            {tab == 2 && (
              <Revenue
                paymentHistory={salesData?.transactions ?? []}
                revenue={revenue}
              />
            )}
            {tab == 1 && (
              <ProjectSiteSalesTable data={salesData?.transactions ?? []} />
            )}
          </div>

          {/* Power Quality and Roles */}
          <div className="hidden grid-cols-1 mt-4 mb-[200px] bg-white rounded-md shadow-md p-2">
            <div className="col-span-1">
              <div className="flex items-center justify-between ">
                <CardTitle
                  title={"Power Quality"}
                  caption={"Monitor power quality"}
                />
                <div className="">
                  <Select
                    defaultValue={"energy"}
                    className="w-[200px]"
                    placeholder="Select data.."
                    options={[
                      { value: "energy", label: "Energy" },
                      { value: "power", label: "Power" },
                      { value: "voltage", label: "Voltage" },
                    ]}
                  />
                </div>
              </div>
              <div className="h-[500px]">
                <PowerQuality />
              </div>
            </div>
          </div>
        </div>
        <div className="h-[200px]"></div>
      </div>
    </Layout>
  );
};

export default ProjectSiteDetails;
