import { Link } from "react-router-dom";
import TopCard from "../../../../components/TopCard";
import DateRangePicker from "../../../../components/DateRangePicker";
import { useSelector } from "react-redux";
import RolesCard from "../../../../components/Roles/Card/RolesCard";
import CardTitle from "../../../../components/CardTitle";
import { useState, useEffect } from "react";
import moment from "moment";
import ListLoader from "../../../../components/ListLoader";
import { Empty } from "antd";
import { useAsync } from "../../../../utils/Hooks/useAsync";
import { client } from "../../../../utils/api";
import TableTop from "../../../../components/TableTop";
import MeterClassTable from "../../../../components/ProjectManager/MeterClass/Table/MeterClassTable";

const ProjectsiteManageStats = () => {
  let projectSiteName = useSelector((state) => state.projectSite).sitename;
  let projectSiteId = useSelector((state) => state.projectSite).siteid;
  const [refresh, setRefresh] = useState(false);
  const [sitedetails, setsitedetails] = useState({});
  const [meterclass, setmeterclass] = useState([]);

  const [startDate, setStartDate] = useState(
    moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  // slRoles
  const {
    data: slRoles,
    run: getSlRoles,
    status: slRoleStatus,
    error: slRoleError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    if (navigator.onLine) {
      getSlRoles(
        client(`/roles/getallusersbyzoneandrole`, {
          data: {
            projectzonearray: JSON.stringify([Number(projectSiteId)]),
            role: "Sales Agent",
          },
          method: "POST",
        })
      );
    }
  }, [getSlRoles]);

  // crRoles
  const {
    data: crRoles,
    run: getCrRoles,
    status: crRoleStatus,
    error: crRoleError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    if (navigator.onLine) {
      getCrRoles(
        client(`/roles/getallusersbyzoneandrole`, {
          data: {
            projectzonearray: JSON.stringify([Number(projectSiteId)]),
            role: "Customer Representative",
          },
          method: "POST",
        })
      );
    }
  }, [getCrRoles]);

  // cmRoles
  const {
    data: cmRoles,
    run: getCmRoles,
    status: cmRoleStatus,
    error: cmRoleError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    if (navigator.onLine) {
      getCmRoles(
        client(`/roles/getallusersbyzoneandrole`, {
          data: {
            projectzonearray: JSON.stringify([Number(projectSiteId)]),
            role: "Customer Manager",
          },
          method: "POST",
        })
      );
    }
  }, [getCmRoles]);

  const renderRolesCard = (roleStatus, roles, roleName, viewAllLink) => {
    if (roleStatus === "pending") {
      return <ListLoader rows={8} />;
    }

    if (roleStatus === "rejected") {
      return (
        <Empty
          style={{ height: "100%", paddingTop: "20%" }}
          description={"No network connection."}
        />
      );
    }

    if (roles?.roles?.length) {
      return (
        <div className="pt-2">
          {roles.roles.map((role, i) => (
            <RolesCard
              role={role.role}
              sitename={role.sitename}
              username={role.username}
              key={i}
              link={`/dashboard/${roleName}/${projectSiteId}/${role.username}`}
            />
          ))}
        </div>
      );
    }

    return (
      <Empty
        style={{ height: "100%", paddingTop: "20%" }}
        description={`No records of ${roleName}.`}
      />
    );
  };

  return (
    <div>
      <div className="row flex justify-content-between">
        <div className="col-4 hidden">
          <TopCard
            title={"Project Site"}
            value={projectSiteName}
            fontSize={"17px"}
            caption={sitedetails.sitetype ?? "-Nil-"}
          />
        </div>
      </div>

      {/* Roles in site */}
      {/* <div className="mt-2 flex w-full">
        <div className="bg-white drop-shadow-md w-full rounded p-2">
          <h2 className="mb-0">Recent roles created in {projectSiteName}</h2>
        </div>
      </div> */}
      <div className="grid grid-cols-3 gap-2 mt-2">
        {/* Customer Manager */}
        <div className="w-100 h-[500px] bg-white p-2 dshb-roles__cover border-r-[1px] ">
          <div className="w-100 flex items-center justify-between">
            <div className="">
              <CardTitle title={"Customer Manager"} />
            </div>
            <div className="d-flex align-items-center justify-content-center more-btn">
              <Link
                className="text-primary"
                to="/userroles/projectzone?role=Project Manager"
              >
                View all{" "}
              </Link>
            </div>
          </div>
          {renderRolesCard(
            cmRoleStatus,
            cmRoles,
            "customermanager",
            `/dashboard/customermanager/${projectSiteId}/`
          )}
        </div>

        {/* Customer Representative */}
        <div className="w-100 h-[500px] bg-white p-2 dshb-roles__cover  border-r-[1px] ">
          <div className="w-100 flex items-center justify-between">
            <div className="">
              <CardTitle title={"Customer Representative"} />
            </div>
            <div className="d-flex align-items-center justify-content-center more-btn">
              <Link
                className="text-primary"
                to="/userroles/projectzone?role=Project Manager"
              >
                View all{" "}
              </Link>
            </div>
          </div>
          {renderRolesCard(
            crRoleStatus,
            crRoles,
            "customerrepresentative",
            `/dashboard/customerrepresentative/${projectSiteId}`
          )}
        </div>

        {/* Sales agent */}
        <div className="w-100 h-[500px] bg-white p-2 dshb-roles__cover ">
          <div className="w-100 flex items-center justify-between">
            <div className="">
              <CardTitle title={"Sales Agent"} />
            </div>
            <div className="d-flex align-items-center justify-content-center more-btn">
              <Link
                className="text-primary"
                to="/userroles/projectzone?role=Sales Agent"
              >
                View all{" "}
              </Link>
            </div>
          </div>
          {renderRolesCard(
            slRoleStatus,
            slRoles,
            "salesagent",
            `/dashboard/salesagent/${projectSiteId}`
          )}
        </div>
      </div>

      {/* Meters table */}
      <div
        className="row hidden bg-white mt-4"
        style={{
          marginBottom: "500px",
        }}
      >
        <div className="">
          <TableTop title={"Meter Class"} />
        </div>
        <div className="">
          <MeterClassTable data={meterclass} />
        </div>
      </div>
    </div>
  );
};

export default ProjectsiteManageStats;
