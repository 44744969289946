import * as uuid from "uuid";
import { useContext } from "react";
import { getToken } from "../../utils/token";
import { useSelector } from "react-redux";

export const data = {
  sysdmin: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: "/dashboard/systemadmin",
    },
    {
      id: `${uuid.v4()}`,
      title: "Staff",
      icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
      children: [],
      link: "/users",
    },
    {
      id: `${uuid.v4()}`,
      title: "Staff Roles",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      children: [],
      link: "/roles/alluserroles",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      children: [],
      link: "/allmeters",
    },
    {
      id: `${uuid.v4()}`,
      title: "Project sites",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      children: [],
      link: "/projectsite",
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meters",
    //   icon: `<ion-icon name="paper-plane"></ion-icon>`,
    //   link: "",
    //   children: [
    //     {
    //       id: `${uuid.v4()}`,
    //       title: "All Meters",
    //       children: [],
    //       icon: "",
    //       link: "/allmeters",
    //     },
    //     // {
    //     //   id: `${uuid.v4()}`,
    //     //   title: "Customer Meters",
    //     //   icon: `<ion-icon name="speedometer"></ion-icon>`,
    //     //   children: [],
    //     //   link: "/dashboard/customers",
    //     // },
    //     // {
    //     //   id: `${uuid.v4()}`,
    //     //   title: "All Customer meters",
    //     //   children: [],
    //     //   icon: "",
    //     //   link: "/allcustomermeters",
    //     // },
    //     // {
    //     //   id: `${uuid.v4()}`,
    //     //   title: "All Site meters.",
    //     //   children: [],
    //     //   link: "/allprojectsitemeters",
    //     // },
    //   ],
    // },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Customer Meters",
    //   icon: `<ion-icon name="speedometer"></ion-icon>`,
    //   children: [],
    //   link: "/dashboard/customers",
    // },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Project sitess",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   // link: "/dashboard/systemadmin/projectsite",
    //   link: "/projectsite/overview",
    // },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meter class",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/meterclass",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Revenue",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/revenue",
    },
    {
      id: `${uuid.v4()}`,
      title: "Analytics",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/consumption-analytics",
    },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      icon: `<ion-icon name="people"></ion-icon>`,
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Your roles",
          children: [],
          icon: "",
          link: "/userroles",
        },
        // {
        //   id: `${uuid.v4()}`,
        //   title: "All user roles",
        //   children: [],
        //   icon: "",
        //   link: "/roles/alluserroles",
        // },
      ],
      link: "",
    },

    // {
    //   id: `${uuid.v4()}`,
    //   title: "Revenue",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/revenue",
    // },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Wallet",
    //   icon: `<ion-icon name="people"></ion-icon>`,
    //   children: [],
    //   link: "/wallet",
    // },

    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Site config.",
          children: [],
          link: "/settings/dropdown",
        },
        // {
        //   id: `${uuid.v4()}`,
        //   title: "Control Settings.",
        //   children: [],
        //   link: "/settings/controls",
        // },
      ],
    },
  ],
  projectmanager: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/projectmanager`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Devices",
      icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
      children: [],
      link: "/dcu",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      children: [],
      link: "/allmeters",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customers",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/manage-meter",
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Site Discount",
    //   icon: `<ion-icon name="speedometer"></ion-icon>`,
    //   children: [],
    //   link: "/discount",
    // },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Wallet",
    //   icon: `<ion-icon name="speedometer"></ion-icon>`,
    //   children: [],
    //   link: "/wallet",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Revenue",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/projectmanager/revenue",
    },
    {
      id: `${uuid.v4()}`,
      title: "Payment plan",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Dynamic plans",
          children: [],
          icon: "",
          link: "/paymentplan",
        },
        {
          id: `${uuid.v4()}`,
          title: "Fixed unit",
          children: [],
          icon: "",
          link: "/tierplans",
        },
      ],
      link: "", // /paymentplan
    },

    {
      id: `${uuid.v4()}`,
      title: "Roles",
      icon: `<ion-icon name="people"></ion-icon>`,
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Your roles",
          children: [],
          icon: "",
          link: "/userroles",
        },
        {
          id: `${uuid.v4()}`,
          title: "Site roles",
          children: [],
          icon: "",
          link: "/roles/alluserroles",
        },
      ],
      link: "",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer class",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      children: [],
      link: "/meterclass",
    },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Site config.",
          children: [],
          link: "/settings/dropdown",
        },
        {
          id: `${uuid.v4()}`,
          title: "Site discount",
          children: [],
          link: "/discount",
        },
        // {
        //   id: `${uuid.v4()}`,
        //   title: "Control Settings.",
        //   children: [],
        //   link: "/settings/controls",
        // },
      ],
    },
  ],
  customerRep: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/customerrepresentative`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      children: [],
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      link: "/manage-meter",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      children: [],
      link: "/allmeters",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer Profile",
      icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
      children: [],
      link: "/users",
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Customer Meters",
    //   icon: `<ion-icon name="speedometer"></ion-icon>`,
    //   children: [],
    //   link: "/salesagent/customer",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      children: [],
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/userroles",
    },

    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meters",
    //   icon: `<ion-icon name="card"></ion-icon>`,
    //   link: "/dashboard/systemadmin/meters",
    //   children: [],
    // },

    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meter class",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/meterclass",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Site config.",
          children: [],
          link: "/settings/dropdown",
        },
      ],
    },
  ],
  customerManager: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/customermanager`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Customers",
      children: [],
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      link: "/manage-meter",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      children: [],
      link: "/allmeters",
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Customer Profile",
    //   icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
    //   children: [],
    //   link: "/zonecustomers",
    // },

    {
      id: `${uuid.v4()}`,
      title: "Roles",
      children: [],
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/userroles",
    },

    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        // {
        //   id: `${uuid.v4()}`,
        //   title: "Site config.",
        //   children: [],
        //   link: "/settings/dropdown",
        // },
      ],
    },
  ],
  salesagent: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/salesagent`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Wallet",
      children: [],
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/wallet",
    },
    {
      id: `${uuid.v4()}`,
      title: "Sales analytics",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/salesagent/revenue",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer meters",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/salesagent/customer",
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Sales Analytics",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/revenue",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      children: [],
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/userroles",
    },

    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        // {
        //   id: `${uuid.v4()}`,
        //   title: "Site config.",
        //   children: [],
        //   link: "/settings/dropdown",
        // },
      ],
    },
  ],
  customers: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/usermeters",
    },
    {
      id: `${uuid.v4()}`,
      title: "Recharge History",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/recharge-history",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meter Activities",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/customer-meter/meteractivities",
    },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
      ],
    },
  ],
  user: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Devices",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/allchannels",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/allmeters",
    },

    {
      id: `${uuid.v4()}`,
      title: "Wallet",
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/wallet",
      children: [],
    },
    {
      id: `${uuid.v4()}`,
      title: "Meter Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Payment plans",
          children: [],
          icon: "",
          link: "/allpaymentplans",
        },
        {
          id: `${uuid.v4()}`,
          title: "Meter class",
          children: [],
          icon: "",
          link: "/meterclass",
        },
      ],
    },
    {
      id: `${uuid.v4()}`,
      title: "Profile",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [],
    },
  ],
};
