import React, { useState, useEffect } from "react";
import Rule from "../../../../components/Rule/Index";
import { Button, Input } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useEditCustomerMeterMutation } from "../../../../services/customer.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export const CustomerMeterProfile = ({ data }) => {
  const { customer, meterid } = useParams();
  const [editable, setEditable] = useState(false);

  const customerDetails = data?.body[0] ?? null;

  // State for the form
  const [formState, setFormState] = useState({
    address: "",
    gps: "",
    latitude: "",
    longitude: "",
    polenumber: "",
    section: "",
  });

  const { siteid } = useSelector((state) => state.projectSite);

  const [
    editCustomerMeter,
    { isLoading: customerLoading, data: customerData, error: customerError },
  ] = useEditCustomerMeterMutation();

  // Populate the form state when customerDetails become available
  useEffect(() => {
    if (customerDetails) {
      const [latitude = "", longitude = ""] =
        customerDetails.gps?.split(",") || [];
      setFormState({
        address: customerDetails.address || "",
        gps: customerDetails.gps || "",
        latitude,
        longitude,
        polenumber: customerDetails.polenumber || "",
        section: customerDetails.section || "",
      });
    }
  }, [customerDetails]);

  // Handle input changes dynamically
  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle the Edit Customer action
  const handleEditCustomer = async () => {
    const gps = `${formState.latitude},${formState.longitude}`; // Join latitude and longitude
    try {
      const response = await editCustomerMeter({
        customerid: customer,
        meter: meterid,
        ...formState,
        gps,
        customertype: customerDetails?.customertype,
        projectzone: siteid,
      });

      if (response?.error) {
        errorBlock(response.error);
        return;
      }

      if (response?.data?.response !== "success") {
        toast.error(response?.data?.error || "Unknown error occurred.");
        return;
      }

      toast.success("Customer updated successfully!");
      setEditable(false);
    } catch (error) {
      errorBlock(error);
    }
  };

  return (
    <div className="grid grid-cols-5 h-auto gap-4">
      <div className="col-span-2 p-2">
        <div className="flex items-center justify-between">
          <h1>Customer Details</h1>
          <div className="">
            {!editable && (
              <Button type="default" onClick={() => setEditable(true)}>
                Edit
              </Button>
            )}
            {editable && (
              <>
                <Button
                  type="primary"
                  className="mx-2"
                  onClick={handleEditCustomer}
                  disabled={customerLoading}
                >
                  {customerLoading ? "Saving..." : "Save"}
                </Button>
                <Button
                  type="default"
                  className=""
                  onClick={() => {
                    setEditable(false);
                    const [latitude = "", longitude = ""] =
                      customerDetails?.gps?.split(",") || [];
                    setFormState({
                      address: customerDetails?.address || "",
                      gps: customerDetails?.gps || "",
                      latitude,
                      longitude,
                      polenumber: customerDetails?.polenumber || "",
                      section: customerDetails?.section || "",
                    });
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </div>
        <Rule />
        {/* Input fields */}
        {[
          { label: "Address", field: "address" },
          { label: "Pole Number", field: "polenumber" },
          { label: "Section", field: "section" },
        ].map((input) => (
          <div
            className="flex items-center justify-between mb-2"
            key={input.field}
          >
            <p className="m-0">{input.label}</p>
            <Input
              className="!w-fit inline-block rounded-md"
              value={formState[input.field]}
              disabled={!editable}
              onChange={(e) => handleInputChange(input.field, e.target.value)}
            />
          </div>
        ))}
        {/* Latitude and Longitude Fields */}
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Latitude</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={formState.latitude}
            disabled={!editable}
            onChange={(e) => handleInputChange("latitude", e.target.value)}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Longitude</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={formState.longitude}
            disabled={!editable}
            onChange={(e) => handleInputChange("longitude", e.target.value)}
          />
        </div>
        {/* Static Fields */}
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Username</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={customer}
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Meter model</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={customerDetails?.meter_model || ""}
            disabled={true}
          />
        </div>
      </div>
      {/* Map component */}
      <div className="col-span-3 bg-grey rounded-md overflow-hidden">
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=${formState.latitude},${formState.longitude}`}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};
