import React, { useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "../../../Button";
import { tableCustomStyles } from "../../../../helperFuncs";
import { Dropdown, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import { formatName } from "../../../../utils/helpers";

const MeterTable = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleViewInfo = (meterId) => {
    setSelectedId(meterId);
    setModal(true);
  };

  const columns = [
    {
      name: "Meter Number",
      selector: (row) => row.meternumber,
      sortable: true,
      cell: (row) => (
        <Link
          to={`/meter-details/${row.customerid}/${row.meternumber}`}
          className="underline"
          style={{ color: "#0a58ca", fontSize: "13px" }}
        >
          {row.meternumber || "nill"}
        </Link>
      ),
    },
    {
      name: "Username",
      selector: (row) => row.customerid,
      sortable: true,
      cell: (row) => <span className="">{row.customerid || "nill"}</span>,
    },
    {
      name: "Fullname",
      selector: (row) => row.customerid,
      sortable: true,
      cell: (row) => (
        <span className="">
          {formatName(row.firstname) + " " + formatName(row.lastname)}
        </span>
      ),
    },
    {
      name: "Customer Type",
      selector: (row) => row.customertype,
      sortable: true,
      cell: (row) => <span className="">{row.customertype || "null"}</span>,
    },
    {
      name: "Meter Type",
      selector: (row) => row.metertype,
      sortable: true,
      cell: (row) => <span className="">{row.metertype || "null"}</span>,
    },
    {
      name: "Actions",
      cell: (item) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <Link
                    to={`/meter-details/${item.customerid}/${item.meternumber}`}
                    className="text-[12px]"
                    onClick={() => {
                      setSelectedId(item.meter);
                      setModal(true);
                    }}
                  >
                    View details
                  </Link>
                ),
              },
            ],
          }}
          placement="bottom"
          arrow
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <TbDotsVertical color="black" />
            </Space>
          </a>
        </Dropdown>
        // <div className=""></div>

        // <div className="d-flex">
        //   <Button
        //     text="View Info"
        //     color="#0a58ca"
        //     onClick={() => handleViewInfo(row.meternumber)}
        //     fontSize="11px"
        //     fontWeight="400"
        //     height="30px"
        //   />
        //   <Link
        //     to={`/dashboard/systemadmin/meters/${row.meternumber}`}
        //     style={{ marginLeft: "10px" }}
        //   >
        //     <Button
        //       text="View Settings"
        //       color="#0a58ca"
        //       fontSize="11px"
        //       fontWeight="400"
        //       height="30px"
        //     />
        //   </Link>
        // </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div style={{ borderTop: "1px solid #eee" }}>
      {/* {modal && (
        <MeterInfoModal
          closeModal={() => setModal(false)}
          meterNumber={selectedId}
        />
      )} */}
      <DataTable
        // title="Meters Table"
        columns={columns}
        data={data}
        pagination
        // highlightOnHover
        defaultSortField="meternumber"
        paginationPerPage={15}
        responsive
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default MeterTable;
