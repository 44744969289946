import React, { useState } from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import {
  useUpdateUserAccountMutation,
  useUpdateUserEmailMutation,
} from "../../../../services/auth.service";
import toast from "react-hot-toast";
import { errorBlock } from "../../../../controllers/errorBlock";
export const UserProfileUpdate = ({
  visible,
  onClose,
  selecteduser,
  refreshBtn,
}) => {
  const [form] = Form.useForm();

  const [updateUserEmail, { isLoading, data, error }] =
    useUpdateUserEmailMutation(); // Use the mutation

  // Function to handle form submission
  const handleFormSubmit = async (values) => {
    try {
      const response = await updateUserEmail({
        // firstname: values.firstname,
        // lastname: values.lastname,
        username: selecteduser?.username,
        email: values.email,
        // phone: values.phone,
      }).unwrap();

      // console.log(response, "response");
      if (response.response === "success") {
        toast.success("User information updated successfully!");
        refreshBtn((prev) => !prev);
        onClose();
      } else {
        toast.error(`${response.message}`);
      }
    } catch (error) {
      errorBlock(error);
    }
  };
  return (
    <Modal
      title="Edit User Information"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={selecteduser}
        onFinish={handleFormSubmit}
      >
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[{ required: true, message: "Last name is required" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="First Name"
            name="firstname"
            rules={[{ required: true, message: "First name is required" }]}
          >
            <Input disabled />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: "Phone number is required" },
              { pattern: /^\d{10,15}$/, message: "Invalid phone number" },
            ]}
          >
            <Input disabled />
          </Form.Item>
        </div>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Invalid email format" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Active Status"
          name="activestatus"
          rules={[{ required: true, message: "Active status is required" }]}
        >
          <Select disabled>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="inactive">Inactive</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Date Registered" name="dateregistered">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

// const App = () => {
//

//   return (
//     <div>
//       <Button type="primary" onClick={() => setIsModalVisible(true)}>
//         Open User Form
//       </Button>
//       <UserFormModal
//         visible={isModalVisible}
//         onClose={() => setIsModalVisible(false)}
//       />
//     </div>
//   );
// };

// export default App;
