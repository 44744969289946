import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackBtn from "../../../../../components/BackBtn";
import DateRangePicker from "../../../../../components/DateRangePicker";
import Layout from "../../../../../components/Layout";

import { useGetProjectSiteRevenueMutation } from "../../../../../services/revenue.services";
import { errorBlock } from "../../../../../controllers/errorBlock";
import { useGetSiteDetailsMutation } from "../../../../../services/projectsite.services";
import { countryList } from "../../../../../helperFuncs/countryList";
// import FilterDateRange from "../../../../../components/FilterDateRange";
import Rule from "../../../../../components/Rule/Index";
import SiteRevenueChart from "../../../../../components/ProjectManager/ProjectSite/Charts/SiteRevenueChart";

const SiteRevenueRecords = ({}) => {
  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );
  const handlefilter = (e) => {
    switch (e) {
      case "today":
        setStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "week":
        setStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "month":
        setStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "year":
        setStartDate(
          moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }

    setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
    setRefresh((prev) => !prev);
  };
  const [currency, setcurrency] = useState("");

  const [data, setdata] = useState([]);
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [refresh, setRefresh] = useState(false);
  const { projectsite, id } = useParams();

  // get site sales Revenue
  const [
    getProjectSiteRevenue,
    { isLoading: salesLoading, data: salesData, error: salesError },
  ] = useGetProjectSiteRevenueMutation();

  // get site Revenue
  useEffect(() => {
    getProjectSiteRevenue({
      startdate,
      enddate,
      siteid: projectsite,
    })
      .unwrap()
      .catch((err) => errorBlock(err));
  }, [refresh]);

  useEffect(() => {
    if (salesError) {
      errorBlock(salesError);
    }
  }, [salesError]);

  const [modal, setmodal] = useState(false);

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  // get Site details
  useEffect(() => {
    getSiteDetails({
      siteid: projectsite,
    }).unwrap();
  }, []);

  // console.log(salesData, "sales data");

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  return (
    <Layout>
      {/* <TopNav title={"new sample"} /> */}
      <div className="mt-2 p-2 bg-white">
        <div className="flex bg-gray-100 p-1 rounded items-center justify-between">
          <div className="font-bold">
            <BackBtn />
            <div className="">
              Revenue generated in{" "}
              <span className="">{siteData?.sitename ?? ""}</span>{" "}
            </div>
          </div>

          <div className="flex ">
            <DateRangePicker
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              refreshBtn={setRefresh}
              startDate={startdate}
              endDate={enddate}
            />
            {/* <FilterDateRange
              open={modal}
              setRefresh={setRefresh}
              setopen={setmodal}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              handlefilter={handlefilter}
            /> */}
          </div>
        </div>

        <Rule />
        {/* Line charts */}
        <div className=" h-[90vh] py-3 px-3">
          <SiteRevenueChart
            data={salesData?.transactions ?? []}
            currency={currency}
          />
        </div>
      </div>
      <div className="mb-40 h-[100px] w-full"></div>
    </Layout>
  );
};

export default SiteRevenueRecords;
