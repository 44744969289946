import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  notification,
  DatePicker,
  List,
  Typography,
} from "antd";
import { dropdownData } from "../../../screens/Settings/DropDownSettings/data";
import axios from "../../../utils/axios";
import { errorBlock } from "../../../controllers/errorBlock";
import { useSegmentCustomersMutation } from "../../../services/segmentation.services";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import { convertToNigerianTime } from "../../../utils/timezones";
import { useGetDropdownMutation } from "../../../services/generalSetting.service";
import { useGetCustomerClassInSiteMutation } from "../../../services/customerclass.services";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const CreateCustomerSegment = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { siteid, timezone } = useSelector((state) => state.projectSite);
  const [segmentCustomers, { isLoading }] = useSegmentCustomersMutation();
  const [resultData, setResultData] = useState(null); // For storing the API results
  const [dddata, setdddata] = useState([]); // For storing the API results
  const [showResults, setShowResults] = useState(false); // To toggle result list visibility
  const [ccdata, setccdata] = useState([]); // To toggle result list visibility
  const [getDropdown, { isLoading: droploading, error }] =
    useGetDropdownMutation();
  const [getCustomerClassInSite, { isLoading: cclass, error: ccerror }] =
    useGetCustomerClassInSiteMutation();

  useEffect(() => {
    getCustomerClassInSite(siteid).then((res) => {
      if (res.error) {
        console.log(res.error, "error");
      } else {
        setccdata(res.data?.customerClasses);
      }
    });
  }, []);

  useEffect(() => {
    getDropdown({
      comboname: "customertype",
    }).then((res) => {
      if (res.error) {
        console.log(res.error, "error");
      } else {
        setdddata(res.data?.list.split(","));
      }
    });
  }, []);

  const formatDate = (date) => {
    return timezone
      ? convertToNigerianTime(date, timezone, "YYYY-MM-DD")
      : date;
  };

  const handleFinish = (values) => {
    const formattedValues = {
      customerType: values?.customerType || null,
      customerClass: values?.customerClass || null,
      meterType: values?.meterType || null,
      minTotalPurchase:
        values?.minTotalPurchase === 0 ? null : values?.minTotalPurchase,
      maxTotalPurchase:
        values?.maxTotalPurchase === 0 ? null : values?.maxTotalPurchase,
      minPurchaseFrequency:
        values?.minPurchaseFrequency === 0
          ? null
          : values?.minPurchaseFrequency,
      maxPurchaseFrequency:
        values?.maxPurchaseFrequency === 0
          ? null
          : values?.maxPurchaseFrequency,
      phaseType: values?.phaseType === 0 ? null : values?.phaseType,
      startDate: values.dateRange ? formatDate(values.dateRange[0]) : null,
      endDate: values.dateRange ? formatDate(values.dateRange[1]) : null,
      paymentPlan: values?.paymentPlan === 0 ? null : values?.paymentPlan,
      siteid,
    };

    segmentCustomers(formattedValues)
      .then((res) => {
        if (res.error) {
          errorBlock(res);
          return;
        }
        if (res.data.response === "success" && res.data.count > 0) {
          navigate("/segmentation-result", {
            state: {
              formattedValues, // Pass the data here
            },
          });
          setResultData(res.data.customers);
        } else {
          setShowResults(true);
          setTimeout(() => {
            setShowResults(false);
          }, 4000);
          // toast.success("No customer found.");
        }
      })
      .catch((err) => {
        errorBlock(err);
      });

    form.resetFields();
  };

  const handleSaveResults = () => {
    // Implement save logic for the generated results
    toast.success("Results have been saved!");
    setShowResults(false);
    setResultData(null);
  };

  return (
    <Modal
      title="Create Customer Segment"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          customerType: "",
          customerClass: "",
          meterType: "",
          minTotalPurchase: 0,
          maxTotalPurchase: 0,
          minPurchaseFrequency: 0,
          maxPurchaseFrequency: 0,
          phaseType: "",
        }}
      >
        {showResults && <p className="">No result found for filter</p>}
        {/* Form Fields */}
        <div className="grid grid-cols-2 gap-4">
          <Form.Item name="customerType" label="Customer Type">
            <Select showSearch placeholder="Select Customer Type">
              {dddata.map((i, index) => (
                <Option key={index} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="customerClass" label="Customer Class">
            <Select showSearch placeholder="Select Customer Class">
              {ccdata.map((i, index) => (
                <Option key={index} value={i.customerClass}>
                  {i.customerClass}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Form.Item name="minTotalPurchase" label="Minimum Total Purchase">
            <InputNumber
              placeholder="Enter Minimum Total Purchase"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item name="maxTotalPurchase" label="Maximum Total Purchase">
            <InputNumber
              placeholder="Enter Maximum Total Purchase"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name="minPurchaseFrequency"
            label="Minimum Purchase Frequency"
          >
            <InputNumber
              placeholder="Enter Minimum Purchase Frequency"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="maxPurchaseFrequency"
            label="Maximum Purchase Frequency"
          >
            <InputNumber
              placeholder="Enter Maximum Purchase Frequency"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>

        <Form.Item name="dateRange" label="Select Date Range">
          <RangePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            size="large"
            className="w-full !bg-primary !border-none"
          >
            Create Segment
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCustomerSegment;
