import moment from "moment";
import { useState } from "react";

import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { tableCustomStyles } from "../../../../helperFuncs";
import { Empty } from "antd";

const MeterCommandsTable = ({ data }) => {
  const [loading, setloading] = useState(null);

  const finalData = data?.body ?? [];
  const userDetials = useSelector((state) => state.userDetials);

  const columns = [
    {
      name: "Sender",
      center: true,
      selector: (row) => row.sender,
      grow: 2,
      sortable: true,
    },
    // {
    //   name: "Meter number",
    //   center: true,
    //   selector: (row) => row.deviceid,
    //   grow: 2,
    //   sortable: true,
    // },
    {
      name: "Command type",
      center: true,
      selector: (row) => row.interpretation.commandDescription,
      grow: 2,
      sortable: true,
    },

    {
      name: "Description",
      center: true,
      selector: (row) => row.interpretation.humanReadable,
      grow: 3,
      sortable: true,
    },
    // {
    //   name: "Command Id",
    //   center: true,
    //   selector: (row) => row.transactionid,
    //   grow: 1,
    //   sortable: true,
    // },
    {
      name: "Date issued",
      center: true,
      selector: (row) => row.dateissued,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[13px]">
              {moment(item.dateissued).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.siteid,
    //   grow: userDetials?.role != "Project Manager" ? 2 : 4,
    //   center: true,
    // },
  ];

  return (
    <div className="border-t-[1px]">
      <DataTable
        // selectableRows
        columns={columns}
        data={finalData}
        noDataComponent={
          <div className="h-[300px] flex flex-col items-center justify-center">
            <Empty description="No command has been recorded on meter" />
          </div>
        }
        pagination
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default MeterCommandsTable;
