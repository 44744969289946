import React, { useEffect, useState } from "react";
import Rule from "../../../../components/Rule/Index";
import { Tabs, Input, Button, Empty } from "antd";
import { controlsData } from "./data";
import { errorBlock } from "../../../../controllers/errorBlock";
import {
  useResetSAIDIMutation,
  useResetSAIFIMutation,
  useSAIFIEnquiryMutation,
  useSAIDIEnquiryMutation,
} from "../../../../services/commands.services";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import SetSAIDFIModal from "../../../../components/Meter/modals/SetSAIDFIModal";
export const CustomerMeterControls = () => {
  const { customer, meterid } = useParams();
  const [showcontent, setContent] = useState({
    enquiry: false,
    hardwareCommands: false,
  });

  const [
    resetSAIFI,
    {
      isLoading: resetsaifiLoading,
      data: resetsaifiData,
      error: errorsaifiError,
    },
  ] = useResetSAIFIMutation();

  const [
    resetSAIDI,
    {
      isLoading: resetsaidiLoading,
      data: resetsaidiData,
      error: errorsaidiError,
    },
  ] = useResetSAIDIMutation();

  const handleCommand = async (type) => {
    try {
      switch (type) {
        case "RST03":
          const resetsaidiResult = await resetSAIDI({ meterid }).unwrap();
          if (resetsaidiResult.response === "success") {
            toast.success(resetsaidiResult.response);
          } else {
            toast.error(resetsaidiResult.error || "Operation failed");
          }
          break;

        case "RST04":
          const resetsaifiResult = await resetSAIFI({ meterid }).unwrap();
          if (resetsaifiResult.response === "success") {
            toast.success(resetsaifiResult.response);
          } else {
            toast.error(resetsaifiResult.error || "Operation failed");
          }
          break;
        case "SAIDI":
          const saidiresult = await SAIDIEnquiry({ meterid }).unwrap();
          if (saidiresult.response === "success") {
            toast.success(saidiresult.response);
          } else {
            toast.error(saidiresult.error || "Operation failed");
          }
          break;
        case "SAIFI":
          const saifiresult = await SAIFIEnquiry({ meterid }).unwrap();
          if (saifiresult.response === "success") {
            toast.success(saifiresult.response);
          } else {
            toast.error(saifiresult.error || "Operation failed");
          }
          break;

        default:
          break;
      }
    } catch (error) {
      errorBlock(error);
    }
  };

  const modalState = () => {};

  const loadingState = (type) => {
    switch (type) {
      case "RST03":
        return resetsaidiLoading;
      case "RST04":
        return resetsaifiLoading;
      case "SAIDI":
        return saidiLoading;
      case "SAIFI":
        return saifiLoading;
      default:
        return false;
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setselected] = useState(null);

  const [
    SAIFIEnquiry,
    { isLoading: saifiLoading, data: saifiData, error: saifiError },
  ] = useSAIFIEnquiryMutation();

  const [
    SAIDIEnquiry,
    { isLoading: saidiLoading, data: saidiData, error: saidiError },
  ] = useSAIDIEnquiryMutation();

  const EnquiryCommands = () => {
    return (
      <div className="">
        <div className="flex mb-3 items-center justify-end">
          <Input.Search
            className="!w-[250px]"
            placeholder="search of command"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {controlsData.enquiryData.map(({ id, label, short, description }) => (
            <div key={id} className="border p-2 h-auto rounded-md">
              <h1 className="m-0">{label}</h1>
              <p className="font-light">{description}</p>
              {/* button components */}
              <Button
                loading={loadingState(short)}
                disabled={isProcessingEnquiry}
                onClick={() => {
                  handleCommand(short);
                  // setIsModalOpen(true);
                  // setselected({
                  //   id,
                  //   label,
                  //   short,
                  //   description,
                  // });
                }}
                className="!bg-transparent !text-black w-full !rounded-md !outline-none !border-black"
              >
                Get {short}
              </Button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const HardwareCommands = () => {
    return !showcontent.hardwareCommands ? (
      <div className="">
        <Empty description="No hardware command has been added." />
      </div>
    ) : (
      <div className="">
        <div className="flex mb-3 items-center justify-end">
          <Input.Search
            className="!w-[250px]"
            placeholder="search of command"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {controlsData.hardwareCommands.map(
            ({ id, label, short, description }) => (
              <div
                key={id}
                className="border flex flex-col justify-between h-[130px] p-2 rounded-md"
              >
                <div className="">
                  <h1 className="m-0">{label + " (" + short + ")"} </h1>
                  <p className="font-light">{description}</p>
                </div>
                <Button
                  loading={loadingState(short)}
                  disabled={isProcessingSettings}
                  onClick={() => handleCommand(short)}
                  className="!bg-gray-900 !text-white w-full !rounded-md !outline-none !border-none"
                >
                  {short == "GTP" ? "Get" : "Clear"} {short}
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const SettingsCommands = () => {
    return (
      <div className="">
        <div className="flex mb-3 items-center justify-end">
          <Input.Search
            className="!w-[250px]"
            placeholder="search of command"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {controlsData.settingsCommands.map(
            ({ id, label, short, description }) => (
              <div
                key={id}
                className="border flex flex-col justify-between h-[130px] p-2 rounded-md"
              >
                <div className="">
                  <h1 className="m-0">{label} </h1>
                  <p className="font-light">{description}</p>
                </div>
                <Button
                  loading={loadingState(short)}
                  onClick={() => handleCommand(short)}
                  className="!text-black w-full !rounded-md !outline-none !border !border-gray-500"
                >
                  {short === "GTP" ? "Get" : "Clear"} {label.split(" ")[1]}
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const commandList = [
    {
      id: 1,
      label: "Enquiry Controls",
      children: <EnquiryCommands />,
    },
    {
      id: 2,
      label: "Settings Controls",
      children: <SettingsCommands />,
    },
    {
      id: 3,
      label: "Hardware Controls",
      children: <HardwareCommands />,
    },
  ];
  const [tabindex, settabindex] = useState(1);
  const isProcessingSettings = resetsaifiLoading || resetsaidiLoading; // In your
  const isProcessingEnquiry = saidiLoading || saifiLoading; // In your
  // const isProcessingHardware = ctpLoading || gtpLoading; // In your
  // console.log(isProcessingEnquiry, "processing enquiry");

  useEffect(() => {
    if (saidiError) {
      errorBlock(saidiError);
    }
    if (saifiError) {
      errorBlock(saifiError);
    }
  }, [saidiError, saifiError]);

  return (
    <>
      <div className="">
        {/* <h1>lorem</h1> */}
        <div className="mt-4">
          <Tabs
            defaultActiveKey={tabindex}
            tabPosition={"left"}
            style={{
              height: 220,
            }}
            onChange={(key) => settabindex(key)} // Update active tab index
            items={commandList.map((i) => {
              const isActive = tabindex === i.id;
              return {
                label: (
                  <div
                    className={`font-light ${
                      isActive ? "text-black font-bold" : "text-gray-600"
                    }`}
                  >
                    {i.label}
                  </div>
                ),
                key: i.id,
                children: i.children,
              };
            })}
          />
          <div className=" h-[800px] hidden grid-cols-5 gap-4">
            <div className="col-span-1 border"></div>
            <div className=" col-span-4 border"></div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <SetSAIDFIModal
          selected={selected}
          setselected={setselected}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
