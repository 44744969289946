import { Select, DatePicker } from "antd";
import PowerQuality from "../../../../components/Dashboards/PowerQuality";
import { useEffect, useState } from "react";
import { errorBlock } from "../../../../controllers/errorBlock";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useGetDailySiteWideEnergyAnalyticsSummaryMutation } from "../../../../services/analytics.services";
import DateRangePicker from "../../../../components/DateRangePicker";
import SiteWideConsumption from "../../../../components/Dashboards/SystemAdmin/Table/SiteWideConsumption";

const ProjectSiteConsumption = ({}) => {
  const [
    getDailySiteWideEnergyAnalyticsSummary,
    { isLoading: meterloading, data: meterData, error: meterError },
  ] = useGetDailySiteWideEnergyAnalyticsSummaryMutation();

  const [refresh, setRefresh] = useState(false);

  const { customer, meterid } = useParams();
  const [startdate, setstartdate] = useState(
    moment(Date.now()).startOf("day").format("YYYY-MM-DD hh:mm:ss")
  );
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  );

  useEffect(() => {
    try {
      getDailySiteWideEnergyAnalyticsSummary({
        startdate,
        enddate,
      })
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, [refresh, startdate, enddate]);

  useEffect(() => {
    if (meterError) {
      errorBlock(meterError);
    }
  }, []);

  return (
    <div className="w-100 mt-2 bg-white p-2 rounded-md ">
      <div className="flex border-b-1 border-gray-400  items-start justify-between">
        <div className="">
          <div className="font-semibold">Power quality data</div>
          <div className="text-gray-500">
            Daily consumption analyics from all project site
          </div>
        </div>
        <DatePicker.RangePicker
          showTime={{ format: "HH:mm:ss" }}
          format="YYYY-MM-DD HH:mm:ss"
          onChange={(e) => {
            setstartdate(moment(e[0]._d).format("YYYY-MM-DD HH:mm:ss"));
            setenddate(moment(e[1]._d).format("YYYY-MM-DD HH:mm:ss"));
            setRefresh((prev) => !prev);
          }}
        />
      </div>
      <SiteWideConsumption data={meterData} loading={meterloading} />
    </div>
  );
};

export default ProjectSiteConsumption;
