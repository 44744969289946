import { toast } from "react-hot-toast";

export const errorBlock = (err) => {
  console.log("error block");
  // console.log(err);
  if (err?.response) {
    return toast.error(err.response?.data?.response);
  } else {
    console.log("else block");
    if (err?.data) {
      if (err?.data?.response == "invalid session") {
        localStorage.clear();
        window.location.pathname = "/";
        return;
      } else if (err?.data?.response == "invalid session") {
        localStorage.clear();
        window.location.pathname = "/";
        return;
      } else if (err?.data?.response == "invalid_token") {
        toast.error(err.data.repsonse);
        return;
      }
      return toast.error(err.data?.response);
    } else {
      toast.error(err.message);
    }
  }
};
