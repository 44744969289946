import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sitename: "",
  siteid: "",
  country: "",
  timezone: "",
};

export const projectSiteSlice = createSlice({
  name: "projectSite",
  initialState,
  reducers: {
    setProjectSiteName: (state, action) => {
      state.sitename = action.payload;
    },
    setProjectSiteId: (state, action) => {
      state.siteid = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    settimezone: (state, action) => {
      state.timezone = action.payload;
    },
  },
});

export const { setProjectSiteName, setProjectSiteId, setCountry, settimezone } =
  projectSiteSlice.actions;

export const projectSiteReducer = projectSiteSlice.reducer;
