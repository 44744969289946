import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";
import { isWWWhostname } from "../utils/helpers";
const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const commandsAPI = createApi({
  reducerPath: "commandsPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers) => {
      // Add any necessary headers here
    },
  }),
  endpoints: (build) => ({
    // POST /commands/activatesaasexpirednotice
    activateSaasExpiredNotice: build.mutation({
      query: (data) => ({
        url: "/commands/activatesaasexpirednotice",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: ``,
        },
      }),
    }),

    // POST /commands/addmetertometerclass
    addMeterToMeterClass: build.mutation({
      query: (data) => ({
        url: "/commands/addmetertometerclass",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          meterclass: data?.meterclass,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/alterlogginginterval
    alterLoggingInterval: build.mutation({
      query: (data) => ({
        url: "/commands/alterlogginginterval",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          minutes: data?.minutes,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/balanceenquiry
    balanceEnquiry: build.mutation({
      query: (data) => ({
        url: "/commands/balanceenquiry",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/clearexistingcommandonmeter
    clearExistingCommandOnMeter: build.mutation({
      query: (data) => ({
        url: "/commands/clearexistingcommandonmeter",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          commandtoclear: data?.commandtoclear,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/clearuntimedenergy
    clearUntimedEnergy: build.mutation({
      query: (data) => ({
        url: "/commands/clearuntimedenergy",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/control/dailyenergylimit
    controlDailyEnergyLimit: build.mutation({
      query: (data) => ({
        url: "/commands/control/dailyenergylimit",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          start_date: data?.start_date,
          starttime: data?.starttime,
          end_date: data?.end_date,
          endtime: data?.endtime,
          kwh: data?.kwh,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/createmeterclass
    createMeterClass: build.mutation({
      query: (data) => ({
        url: "/commands/createmeterclass",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          meterclass: data?.meterclass,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
          tou: data?.tou,
        },
      }),
    }),

    // POST /commands/cumulativeenergylimit
    cumulativeEnergyLimit: build.mutation({
      query: (data) => ({
        url: "/commands/cumulativeenergylimit",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          start_date: data?.start_date,
          starttime: data?.starttime,
          end_date: data?.end_date,
          endtime: data?.endtime,
          kwh: data?.kwh,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/dailyenergylimit
    dailyEnergyLimit: build.mutation({
      query: (data) => ({
        url: "/commands/dailyenergylimit",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          start_date: data?.start_date,
          starttime: data?.starttime,
          end_date: data?.end_date,
          endtime: data?.endtime,
          kwh: data?.kwh,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/dailypowerlimit
    dailyPowerLimit: build.mutation({
      query: (data) => ({
        url: "/commands/dailypowerlimit",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          start_date: data?.start_date,
          start_time: data?.start_time,
          end_date: data?.end_date,
          end_time: data?.end_time,
          applicable_power: data?.applicable_power,
          return_power: data?.return_power,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/deactivatesaasexpirednotice
    deactivateSaasExpiredNotice: build.mutation({
      query: (data) => ({
        url: "/commands/deactivatesaasexpirednotice",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/deletemeterclass
    deleteMeterClass: build.mutation({
      query: (data) => ({
        url: "/commands/deletemeterclass",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          meterclass: data?.meterclass,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/entiremeterstate
    entireMeterState: build.mutation({
      query: (data) => ({
        url: "/commands/entiremeterstate",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/getlastrechargetoken
    getLastRechargeToken: build.mutation({
      query: (data) => ({
        url: "/commands/getlastrechargetoken",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/getlasttoken
    getLastToken: build.mutation({
      query: (data) => ({
        url: "/commands/getlasttoken",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/getpowerstatesofmetersfromdcu
    getPowerStatesOfMetersFromDCU: build.mutation({
      query: (data) => ({
        url: "/commands/getpowerstatesofmetersfromdcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/gettokenhistory
    getTokenHistory: build.mutation({
      query: (data) => ({
        url: "/commands/gettokenhistory",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),
    // POST /commands/immediateturnoff
    immediateTurnOff: build.mutation({
      query: (data) => ({
        url: "/commands/immediateturnoff",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/immediaturnon
    immediateTurnOn: build.mutation({
      query: (data) => ({
        url: "/commands/immediateturnon",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/killmeterclass
    killMeterClass: build.mutation({
      query: (data) => ({
        url: "/commands/killmeterclass",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          meterclass: data?.meterclass,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/lastrechargeenquiry
    lastRechargeEnquiry: build.mutation({
      query: (data) => ({
        url: "/commands/lastrechargeenquiry",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/looptimer
    loopTimer: build.mutation({
      query: (data) => ({
        url: "/commands/looptimer",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          starttime: data?.starttime,
          endtime: data?.endtime || "2424", // Default if not provided
          onetimeaction: data?.onetimeaction,
          connectminutes: data?.connectminutes,
          disconnectminutes: data?.disconnectminutes,
          startstate: data?.startstate,
          stopstate: data?.stopstate,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/measureenergybyapparentpower
    measureEnergyByApparentPower: build.mutation({
      query: (data) => ({
        url: "/commands/measureenergybyapparentpower",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/measureenergybyrealpower
    measureEnergyByRealPower: build.mutation({
      query: (data) => ({
        url: "/commands/measureenergybyrealpower",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/meterclassenquiry
    meterClassEnquiry: build.mutation({
      query: (data) => ({
        url: "/commands/meterclassenquiry",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/powerdownonsupply
    powerDownOnSupply: build.mutation({
      query: (data) => ({
        url: "/commands/powerdownonsupply",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/poweruponsupply
    powerUpOnSupply: build.mutation({
      query: (data) => ({
        url: "/commands/poweruponsupply",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/realtimeupdate
    realTimeUpdate: build.mutation({
      query: (data) => ({
        url: "/commands/realtimeupdate",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/requestfirmwareversion
    requestFirmwareVersion: build.mutation({
      query: (data) => ({
        url: "/commands/requestfirmwareversion",
        method: "POST",
        body: {
          ...credentials,
          meterordcuid: data?.meterordcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/resetenergyregister
    resetEnergyRegister: build.mutation({
      query: (data) => ({
        url: "/commands/resetenergyregister",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/resetmetertofactorydefault
    resetMeterToFactoryDefault: build.mutation({
      query: (data) => ({
        url: "/commands/resetmetertofactorydefault",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/resetroutingtableondcu
    resetRoutingTableOnDCU: build.mutation({
      query: (data) => ({
        url: "/commands/resetroutingtableondcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/resetsaidi
    resetSAIDI: build.mutation({
      query: (data) => ({
        url: "/commands/resetsaidi",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/resetsaifi
    resetSAIFI: build.mutation({
      query: (data) => ({
        url: "/commands/resetsaifi",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/resettimeondcu
    resetTimeOnDCU: build.mutation({
      query: (data) => ({
        url: "/commands/resettimeondcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/resettimeonmeter
    resetTimeOnMeter: build.mutation({
      query: (data) => ({
        url: "/commands/resettimeonmeter",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/routingtableenquiry
    routingTableEnquiry: build.mutation({
      query: (data) => ({
        url: "/commands/routingtableenquiry",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/saidienquiry
    SAIDIEnquiry: build.mutation({
      query: (data) => ({
        url: "/commands/saidienquiry",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/saifienquiry
    SAIFIEnquiry: build.mutation({
      query: (data) => ({
        url: "/commands/saifienquiry",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/scheduledconnect
    scheduledConnect: build.mutation({
      query: (data) => ({
        url: "/commands/scheduledconnect",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          starttime: data?.starttime,
          endtime: data?.endtime || "2424", // Default if not provided
          onetimeaction: data?.onetimeaction,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/scheduleddisconnect
    scheduledDisconnect: build.mutation({
      query: (data) => ({
        url: "/commands/scheduleddisconnect",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          starttime: data?.starttime,
          endtime: data?.endtime || "2424", // Default if not provided
          onetimeaction: data?.onetimeaction,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/scheduledpowerlimit
    scheduledPowerLimit: build.mutation({
      query: (data) => ({
        url: "/commands/scheduledpowerlimit",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          daybinaries: data?.daybinaries,
          starttime: data?.starttime,
          endtime: data?.endtime || "2424", // Default if not provided
          onetimeaction: data?.onetimeaction,
          startwattage: data?.startwattage,
          returnwattage: data?.returnwattage || "0000",
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/untimedenergy
    untimedEnergy: build.mutation({
      query: (data) => ({
        url: "/commands/untimedenergy",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          kwh: data?.kwh,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/updatefirmwareforgprsorstandanlonedcu
    updateFirmwareForGPRSOrStandaloneDCU: build.mutation({
      query: (data) => ({
        url: "/commands/updatefirmwareforgprsorstandanlonedcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST /commands/updatefirmwareformetersonstandanlonedcu
    updateFirmwareForMetersOnStandaloneDCU: build.mutation({
      query: (data) => ({
        url: "/commands/updatefirmwareformetersonstandanlonedcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),

    // POST getpowerstate of meter
    getPowerStateOfMeter: build.mutation({
      query: (data) => ({
        url: "/commands/getpowerstateofmeter",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          transactiontopic: `${isWWWhostname()}/${credentials?.callerid}`,
        },
      }),
    }),
  }),
});

export const {
  useGetPowerStateOfMeterMutation,
  useActivateSaasExpiredNoticeMutation,
  useAddMeterToMeterClassMutation,
  useAlterLoggingIntervalMutation,
  useBalanceEnquiryMutation,
  useClearExistingCommandOnMeterMutation,
  useClearUntimedEnergyMutation,
  useControlDailyEnergyLimitMutation,
  useCreateMeterClassMutation,
  useCumulativeEnergyLimitMutation,
  useDailyEnergyLimitMutation,
  useDailyPowerLimitMutation,
  useDeactivateSaasExpiredNoticeMutation,
  useDeleteMeterClassMutation,
  useEntireMeterStateMutation,
  useGetLastRechargeTokenMutation,
  useGetLastTokenMutation,
  useGetPowerStatesOfMetersFromDCUMutation,
  useGetTokenHistoryMutation,
  //   sdkj
  useImmediateTurnOffMutation,
  useImmediateTurnOnMutation,
  useKillMeterClassMutation,
  useLastRechargeEnquiryMutation,
  useLoopTimerMutation,
  useMeasureEnergyByApparentPowerMutation,
  useMeasureEnergyByRealPowerMutation,
  useMeterClassEnquiryMutation,
  usePowerDownOnSupplyMutation,
  usePowerUpOnSupplyMutation,
  useRealTimeUpdateMutation,
  useRequestFirmwareVersionMutation,
  useResetEnergyRegisterMutation,
  useResetMeterToFactoryDefaultMutation,
  useResetRoutingTableOnDCUMutation,
  useResetSAIDIMutation,
  useResetSAIFIMutation,
  useResetTimeOnDCUMutation,
  useResetTimeOnMeterMutation,
  useRoutingTableEnquiryMutation,
  useSAIDIEnquiryMutation,
  useSAIFIEnquiryMutation,
  useScheduledConnectMutation,
  useScheduledDisconnectMutation,
  useScheduledPowerLimitMutation,
  useUntimedEnergyMutation,
  useUpdateFirmwareForGPRSOrStandaloneDCUMutation,
  useUpdateFirmwareForMetersOnStandaloneDCUMutation,
} = commandsAPI;
