import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { errorBlock } from "../../../controllers/errorBlock";
import { useDispatch } from "react-redux";
import { postLogin } from "../../../redux/actions/auth.action";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import BackBtn from "../../../components/BackBtn";
import { headers } from "../../../utils/headers";
import { apiURL } from "../../../utils/axios";
import {
  setCountry,
  setProjectSiteId,
  setProjectSiteName,
} from "../../../redux/slice/projectsiteSlice";
import {
  updateRole,
  updateUsername,
  updateRoleCount,
  updateClientId,
} from "../../../redux/slice/userDetailSlice";
import { updateDomain } from "../../../redux/slice/domainslice";

const MFAVerification = () => {
  const location = useLocation();

  const [time, settime] = useState(15 * 60); // Initialize time to 15 minutes (in seconds)
  const [number, setnumber] = useState(""); // Initialize time to 15 minutes (in seconds)
  const [resendVisible, setResendVisible] = useState(false); // To track when to show the Resend button
  let [searchParams, setSearchParams] = useSearchParams();
  let username = searchParams.get("username");
  let subdomain = searchParams.get("subdomain");
  let clientid = searchParams.get("clientid");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      if (!username) {
        return toast.error("Username is required");
      }

      if (!subdomain) {
        return toast.error("A companyid is required");
      }

      if (!number) return toast.error("Please enter your 6-digits code");

      if (number.length < 6)
        return toast.error("Your code needs to be 6-digits");

      setloading(true);

      let res = await axios.post(
        apiURL.mmsUrlDev + "auth/verify2fa",
        {
          token: number,
          username,
          subdomain,
          clientid,
        },
        headers
      );

      if (res.data.response == "success") {
        // toast.success("Email verified successfully");
        localStorage.setItem("spiral_token", res.data.sessionid);
        localStorage.setItem("spiral_email", "verified");
        localStorage.setItem("spiral_registration", "notverified");
        localStorage.setItem("spiral_username", searchParams.get("username"));

        // Make request to get User Role
        let rolesReq = await axios.post(
          apiURL.mmsUrlDev + "/roles/getuserroles",
          {
            callerid: username,
            targetusername: username,
            sessionid: res.data?.sessionid,
          },
          headers
        );

        dispatch(postLogin());

        let userRoles = rolesReq.data.body;

        if (!userRoles.length) {
          toast.error("A role has not been assigned to your account.");
          setloading(false);
          return;
        }

        dispatch(updateRoleCount(rolesReq.data.meta.count));

        dispatch(updateClientId(clientid));

        // check the length of the role
        if (userRoles.length == 1) {
          const { creator, role, roleid, country, siteid, sitename, username } =
            userRoles[0];
          dispatch(updateDomain(subdomain));
          if (role == "System Manager") {
            dispatch(setProjectSiteName(""));
            dispatch(setProjectSiteId(""));
            dispatch(updateUsername(username));
            dispatch(setCountry(""));
            dispatch(updateRole(role));

            window.location.pathname = "/dashboard/systemadmin";

            return;
          } else {
            // update the siteid, sitename, and username
            dispatch(setProjectSiteName(sitename));
            dispatch(setProjectSiteId(siteid));
            dispatch(setCountry(country));
            dispatch(updateUsername(username));
            dispatch(updateRole(role));
            setloading(false);

            if (role == "Sales Agent") {
              window.location.pathname = "/dashboard/salesagent";
            } else if (role == "Project Manager") {
              window.location.pathname = "/dashboard/projectmanager";
            } else if (role == "Customer Manager") {
              window.location.pathname = "/dashboard/customermanager";
            } else if (role == "Customer Representative") {
              window.location.pathname = "/dashboard/customerrepresentative";
            }
          }
          return;
        } else {
          window.location.pathname = "/userroles";
          setloading(false);
        }
      } else if (res.data.response == "failure") {
        toast.error("Failed please try again");
      } else if (res.data.response == "invalid_token") {
        toast.error("Invalid token");
        return;
      } else if (res.data.response == "expired") {
        toast.error("Token has expired please try again");
      } else if (res.message) {
        toast.error(res.message);
      }
      setloading(false);
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };

  useEffect(() => {
    let timer = setInterval(() => {
      settime((prev) => {
        if (prev <= 1) {
          clearInterval(timer); // Stop the timer when it reaches 0
          setResendVisible(true); // Show the resend button
          return 0;
        }
        return prev - 1; // Decrement time by 1 second
      });
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the interval on component unmount
    };
  }, []);

  const handleResend = async () => {
    settime(15 * 60); // Reset the timer to 15 minutes
    setResendVisible(false); // Hide the Resend button
    //
    // Param: username, subdomain. returns Json object with keys {response: 'success'|'invalid_token'|'error', If success, includes confirmation of email sent}
    try {
      if (!username) {
        return toast.error("Username is required");
      }

      if (!subdomain) {
        return toast.error("A companyid is required");
      }

      setloading(true);
      let res = await axios.post(apiURL.mmsUrlDev + "auth/resend2fa", {
        username,
        subdomain,
      });

      if (res.data.response == "success") {
        toast.success("A new code has been sent to your registration email.");
      } else if (res.data.response == "failure") {
        toast.error("Failed please try again");
      } else if (res.data.response == "invalid_token") {
        localStorage.clear();
        toast.error("Invalid token");
        navigate("/");
      } else if (res.data.response == "expired") {
        toast.error("Token has expired please try again");
      } else if (res.message) {
        toast.error(res.message);
      }
      setloading(false);
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`; // Format as MM:SS
  };

  return (
    <div className="h-screen bg-gray-50 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="w-full md:w-[450px] !z-50 bg-white shadow-md rounded-md mx-auto">
        <div className="p-1 border-b-[1px] m-2">
          <BackBtn />
        </div>
        <div className="text-center pt-2 text-primary font-semibold text-2xl">
          Chanels
        </div>
        <div className="w-[full] h-auto rounded-md px-6">
          <div className="font-light text-center text-gray-700">
            Enter the six digits code sent to your email address
          </div>
          <div className="font-semibold text-center text-l">
            {!searchParams.get("email")
              ? ""
              : location.search.split("&")[0].split("=")[1]}
          </div>
          <div className="mt-3 flex flex-col items-center justify-center">
            <Input
              size="large"
              type="number"
              placeholder="Enter your six digits code"
              className="w-full"
              onChange={(e) => {
                setnumber(e.target.value);
              }}
            />
          </div>

          {resendVisible ? (
            <Button
              type="default"
              className="mt-2 !border-none !outline-none underline !bg-none"
              onClick={handleResend}
            >
              Resend Code
            </Button>
          ) : (
            <p className="text-end mt-2 font-light">
              Expires in {formatTime()}
            </p>
          )}

          <hr className="my-2" />
          <Button
            loading={loading}
            onClick={handleSubmit}
            size="large"
            disabled={loading}
            className="mb-2 !text-white !w-full !bg-black !rounded-none"
          >
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MFAVerification;
