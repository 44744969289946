import { Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import CustomerMeterChart from "../../../../components/Meter/Charts/CustomerMeterChart";
import { useGetBasicPowerQaualityMutation } from "../../../../services/analytics.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import { useParams } from "react-router-dom";
import moment from "moment";
import DateRangePicker from "../../../../components/DateRangePicker";
import { useSelector } from "react-redux";
import { convertToNigerianTime } from "../../../../utils/timezones";

export const CustomerMeterAnalytics = () => {
  const [
    getBasicPowerQauality,
    { isLoading: meterloading, data: meterData, error: meterError },
  ] = useGetBasicPowerQaualityMutation();

  const [refresh, setRefresh] = useState(false);

  const { timezone } = useSelector((state) => state.projectSite);

  const { customer, meterid } = useParams();
  const [startdate, setstartdate] = useState(
    moment(Date.now())
      .subtract("1", "month")
      .endOf("month")
      .format("YYYY-MM-DD hh:mm:ss")
  );
  const [enddate, setenddate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD hh:mm:ss")
  );

  useEffect(() => {
    try {
      getBasicPowerQauality({
        meterjsonarray: [meterid],
        startdate: timezone
          ? convertToNigerianTime(startdate, timezone)
          : startdate,
        enddate: timezone ? convertToNigerianTime(enddate, timezone) : enddate,
      })
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, [refresh]);

  useEffect(() => {
    if (meterError) {
      errorBlock(meterError);
    }
  }, []);

  // Extract readings data
  const readings = meterData?.meters?.[meterid]?.readings || [];

  return (
    <div className="overflow-hidden">
      <div className="flex items-center justify-between">
        <h1>Meter Consumption Analytics</h1>
        <div className="flex items-center">
          <Tooltip
            color=""
            style={{
              color: "black !important",
            }}
            className="!text-black"
            title="Request for consumption in real-time"
          >
            <Button className="!bg-secondary !border-none !text-white">
              Realtime Consumption Analytics
            </Button>
          </Tooltip>
          <DateRangePicker
            setEndDate={setenddate}
            setStartDate={setstartdate}
            startDate={startdate}
            endDate={enddate}
            refreshBtn={setRefresh}
          />
        </div>
      </div>
      <div className="h-[600px]">
        {meterloading ? (
          <p>Loading data...</p> // Or use a spinner here
        ) : readings.length > 0 ? (
          <CustomerMeterChart data={readings} />
        ) : (
          <p>No data available for the selected date range.</p>
        )}
      </div>
    </div>
  );
};
