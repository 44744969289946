import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer, RootState } from "./reducers";
import { meterAPI } from "../services/meter.services";
import { DCUAPI } from "../services/dcu.services";
import { customerClassAPI } from "../services/customerclass.services";
import { customerAPI } from "../services/customer.services";
import { revenueAPI } from "../services/revenue.services";
import { salesanalyticsAPI } from "../services/salesanalytics.services";
import { rolesAPI } from "../services/roles.services";
import { projectSiteAPI } from "../services/projectsite.services";
import { siteMapAPI } from "../services/map.services";
import { analyticAPI } from "../services/analytics.services";
import { commandsAPI } from "../services/commands.services";
import { commandAnalyticsAPI } from "../services/command.analytics.services";
import { externalAPI } from "../services/external.services";
import { authAPI } from "../services/auth.service";
import { segmentationApi } from "../services/segmentation.services";
import { paymentAPI } from "../services/payment.service";
import { generalSettingsApi } from "../services/generalSetting.service";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    meterAPI.reducerPath,
    DCUAPI.reducerPath,
    customerClassAPI.reducerPath,
    customerAPI.reducerPath,
    salesanalyticsAPI.reducerPath,
    revenueAPI.reducerPath,
    rolesAPI.reducerPath,
    projectSiteAPI.reducerPath,
    siteMapAPI.reducerPath,
    authAPI.reducerPath,
    segmentationApi.reducerPath,
    paymentAPI.reducerPath,
    generalSettingsApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).concat(
      meterAPI.middleware,
      DCUAPI.middleware,
      customerClassAPI.middleware,
      customerAPI.middleware,
      revenueAPI.middleware,
      salesanalyticsAPI.middleware,
      rolesAPI.middleware,
      projectSiteAPI.middleware,
      siteMapAPI.middleware,
      analyticAPI.middleware,
      commandsAPI.middleware,
      commandAnalyticsAPI.middleware,
      externalAPI.middleware,
      authAPI.middleware,
      segmentationApi.middleware,
      paymentAPI.middleware,
      generalSettingsApi.middleware
    ),
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
