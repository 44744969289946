import { Modal, Button, Input } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
const ConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleContinue,
  title,
  description,
  btntext,
}) => {
  const [statement, setstatement] = useState("");
  const [loading, setloading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <Button
          size="large"
          loading={loading}
          disabled={loading}
          onClick={() => {
            setloading(true);
            if (statement !== "I understand") {
              toast.error("Enter the right sentence to continue");
              setloading(false);
              return;
            }
            handleContinue();
          }}
          className="!outline-none !border-none w-full !bg-red-400 !text-white"
        >
          {btntext ?? "Continue"}
        </Button>
      }
    >
      <div>
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <Input
          onChange={(e) => {
            setstatement(e.target.value);
          }}
          status="error"
          size="large"
          placeholder="I understand"
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
