import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const authAPI = createApi({
  reducerPath: "authPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    //
    updateUserEmail: build.mutation({
      query: (data) => ({
        url: "auth/sysadmin/useraccount/updateemail",
        method: "POST",
        body: {
          ...credentials,
          ...data,
        },
      }),
    }),
    activateUserAccount: build.mutation({
      query: (username) => ({
        url: "auth/activateuseraccount",
        method: "POST",
        body: {
          ...credentials,
          username,
        },
      }),
    }),
    completeRegistration: build.mutation({
      query: (data) => ({
        url: "auth/completeregistration",
        method: "POST",
        body: {
          ...credentials,
          ...data, // Includes firstname, lastname, phone
        },
      }),
    }),
    createAccount: build.mutation({
      query: (data) => ({
        url: "auth/createaccount",
        method: "POST",
        body: {
          ...credentials,
          ...data, // Includes username, defaultpassword, email
        },
      }),
    }),
    getAllRegisteredUsers: build.mutation({
      query: ({ limit, offset }) => ({
        url: "auth/customeraccount/getallregisteredusers",
        method: "POST",
        body: {
          ...credentials,
          limit,
          offset,
        },
      }),
    }),
    getFirstAndLastName: build.mutation({
      query: (username) => ({
        url: "auth/customeraccount/getfirstandlastname",
        method: "POST",
        body: {
          ...credentials,
          username,
        },
      }),
    }),
    getFirstName: build.mutation({
      query: (username) => ({
        url: "auth/customeraccount/getfirstname",
        method: "POST",
        body: {
          ...credentials,
          username,
        },
      }),
    }),
    searchUser: build.mutation({
      query: (keyword) => ({
        url: "auth/customeraccount/searchuser",
        method: "POST",
        body: {
          ...credentials,
          keyword,
        },
      }),
    }),
    getLastName: build.mutation({
      query: (username) => ({
        url: "auth/cuustomeraccount/getlastname",
        method: "POST",
        body: {
          ...credentials,
          username,
        },
      }),
    }),
    deactivateUserAccount: build.mutation({
      query: (username) => ({
        url: "auth/deactivateuseraccount",
        method: "POST",
        body: {
          ...credentials,
          username,
        },
      }),
    }),
    isUsernameUsed: build.mutation({
      query: (data) => ({
        url: "auth/isusernameused",
        method: "POST",
        body: data, // Includes usernames, subdomain
      }),
    }),
    login: build.mutation({
      query: (data) => ({
        url: "auth/login",
        method: "POST",
        body: data, // Includes username, password, clientid, subdomain
      }),
    }),
    logout: build.mutation({
      query: (data) => ({
        url: "auth/logout",
        method: "POST",
        body: data, // Includes username, sessionid
      }),
    }),
    logoutAllDevices: build.mutation({
      query: (data) => ({
        url: "auth/logoutalldevices",
        method: "POST",
        body: data, // Includes username, sessionid
      }),
    }),
    logoutAllDevicesExceptThis: build.mutation({
      query: () => ({
        url: "auth/logoutalldevicesexceptthis",
        method: "POST",
        body: credentials,
      }),
    }),
    resend2FA: build.mutation({
      query: (data) => ({
        url: "auth/resend2fa",
        method: "POST",
        body: data, // Includes username, subdomain
      }),
    }),
    resetDefaultPassword: build.mutation({
      query: (data) => ({
        url: "auth/resetdefaultpassword",
        method: "POST",
        body: data, // Includes username, password, newpasswords, subdomain
      }),
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: "auth/resetpassword",
        method: "POST",
        body: {
          ...credentials,
          ...data, // Includes password, newpassword, logoutofotherdevices
        },
      }),
    }),
    getUser: build.mutation({
      query: (username) => ({
        url: "auth/useraccount/getuser",
        method: "POST",
        body: {
          ...credentials,
          username,
        },
      }),
    }),
    getUserUnchecked: build.mutation({
      query: (data) => ({
        url: "auth/useraccount/getuserunchecked",
        method: "POST",
        body: data, // Includes username, subdomain
      }),
    }),
    updateUserAccount: build.mutation({
      query: (data) => ({
        url: "auth/useraccount/update",
        method: "POST",
        body: {
          ...credentials,
          ...data, // Includes firstname, lastname, email, phone
        },
      }),
    }),
    verify2FA: build.mutation({
      query: (data) => ({
        url: "auth/verify2fa",
        method: "POST",
        body: data, // Includes username, token, clientid, subdomain
      }),
    }),
  }),
});

export const {
  useActivateUserAccountMutation,
  useCompleteRegistrationMutation,
  useCreateAccountMutation,
  useGetAllRegisteredUsersMutation,
  useGetFirstAndLastNameMutation,
  useGetFirstNameMutation,
  useSearchUserMutation,
  useGetLastNameMutation,
  useDeactivateUserAccountMutation,
  useIsUsernameUsedMutation,
  useLoginMutation,
  useLogoutMutation,
  useLogoutAllDevicesMutation,
  useLogoutAllDevicesExceptThisMutation,
  useResend2FAMutation,
  useResetDefaultPasswordMutation,
  useResetPasswordMutation,
  useGetUserMutation,
  useGetUserUncheckedMutation,
  useUpdateUserAccountMutation,
  useVerify2FAMutation,
  useUpdateUserEmailMutation,
} = authAPI;
